export default function CmsInstructionAccordion(props: {
    cmsInstructionEmp: string;
    cmsInstruction: string;
}) {
    return (
        <div className="space-x-4 ">
            <h2 className="inline-block text-center object-center">
                Instructions
            </h2>
            <div className="grid-cols-2 grid gap-4">
                <div className="inline-block object-left items-left">
                    <input
                        type="checkbox"
                        name="panel"
                        id="panel-1"
                        className="hidden"
                    />
                    <label
                        htmlFor="panel-1"
                        className="plus relative block bg-slate-50 text-black p-4 shadow border border-white rounded"
                    >
                        Instruction CMS Admin
                    </label>

                    <div className="accordion__content overflow-hidden bg-grey-lighter">
                        <p className="accordion__body p-4" id="panel1">
                            {props.cmsInstruction}
                        </p>
                    </div>
                </div>
                <div className="inline-block object-left items-left">
                    <input
                        type="checkbox"
                        name="panel"
                        id="panel-2"
                        className="hidden"
                    />
                    <label
                        htmlFor="panel-2"
                        className=" plus relative block bg-slate-50 text-black p-4 shadow border border-white rounded"
                    >
                        Instruction to Content Writer
                    </label>
                    <div className="accordion__content overflow-hidden bg-grey-lighter">
                        <p className="accordion__body p-4">
                            {props.cmsInstructionEmp}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
