import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAddTodoMutation,
    useGetEmployeeUsingTokenQuery,
    useGetTodosQuery,
} from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/notification.css';
import InputComponent from '../main/Input';

interface TableRowProps {
    task: string | undefined;
}
function TableRow(props: TableRowProps) {
    return (
        <tr>
            <td className="pl-4 pr-2">
                <input className="align-middle h-4 w-4 " type="checkbox" />
            </td>

            <tr className="text-xl font-bold">
                <td className="nameTitle px-2 ">
                    <span>{props.task}</span>{' '}
                </td>
            </tr>
        </tr>
    );
}

function formatDate(date: any) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
}

export default function CmsToDoList() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data } = useGetTodosQuery({
        variables: {
            input: {
                employeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });

    const navigate = useNavigate();

    const [addTodo] = useAddTodoMutation();

    const formik = useFormik({
        initialValues: {
            task: '',
            complete: false,
            employeeID: LoggedInUser?.getEmployeeUsingToken?.id,
        },
        onSubmit: async () => {
            addTodo({
                variables: {
                    input: {
                        task: formik.values.task,
                        complete: formik.values.complete,
                        employeeID: formik.values.employeeID,
                    },
                },
            });
            navigate('/');
        },
    });

    const [open, setOpen] = React.useState(false);
    const date = new Date();
    const current_date = formatDate(date);

    return (
        <div className="notifb max-w-sm rounded-lg ">
            <div className="px-6 py-4">
                <div className="headerTitle font-bold text-xl mb-2 grid grid-cols-2 ">
                    To Do List
                    <div className="pl-24">
                        {' '}
                        <button
                            onClick={() => setOpen(true)}
                            type="button"
                            className="bg-zedex-button-blue rounded-lg pb-8 text-white create-order align-middle h-6 w-16  hover:bg-zedex-button-blue-hover"
                        >
                            <span className="text-xs  ">Add Task</span>
                        </button>
                        {open ? (
                            <div className="absolute">
                                {open ? (
                                    <div className="hh justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                            <div className="border-2 border-trev m-10 p-5 bg-white rounded-xl space-y-4 w-[30rem]">
                                                <div className="space-x-8">
                                                    <div className="pl-40 inline-block text-lg font-bold tracking-wide align-middle">
                                                        Add Tasks
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="inline-block pl-24 text-lg"
                                                        onClick={() =>
                                                            setOpen(false)
                                                        }
                                                    >
                                                        x
                                                    </button>
                                                </div>
                                                <div className="text-slate-500 tracking-wide text-justify text-sm pl-24">
                                                    Add Tasks on your To-Do List
                                                </div>
                                                <form
                                                    onSubmit={
                                                        formik.handleSubmit
                                                    }
                                                >
                                                    <InputComponent
                                                        id="task"
                                                        inputType="text"
                                                        name="task"
                                                        inputPlaceholder="Enter Your Task"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        value={
                                                            formik.values.task
                                                        }
                                                        touched={
                                                            formik.touched.task
                                                        }
                                                        error={
                                                            formik.errors.task
                                                        }
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="text-white w-[50%] p-2 mx-[23%] bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600 rounded-lg"
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <tbody>
                {data?.getTodosById
                    ?.filter(
                        (ro) =>
                            ro?.complete === false &&
                            ro?.date.slice(0, 10) === current_date
                    )
                    .slice(0, 5)
                    .map((row) => {
                        return (
                            <TableRow
                                key={row?.id}
                                task={row?.task as string}
                            />
                        );
                    })}
            </tbody>
        </div>
    );
}
