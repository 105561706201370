import '../../styles/ProfileBar.css';
import '../../styles/card.css';

import { useMemo } from 'react';
import { Employee, useGetEmployeesQuery } from '../../api/generated';
import CardWrapper from './CardWrapper';
import EmployeeData from './EmployeeData';


export default function Cards() {
    const { data } = useGetEmployeesQuery();


    const SeoEmployes = useMemo(()=>{
        return data?.getEmployees
        ?.filter(
            (ro) =>
                ro?.role === 'SEOADMIN' ||
                ro?.role === 'SEOEMPLOYEE'
        ) || []
    },[data])

    const CmsEmployes = useMemo(()=>{
        return data?.getEmployees
        ?.filter(
            (ro) =>
                ro?.role === 'CMSADMIN' ||
                ro?.role === 'CMSEMPLOYEE'
        ) || []
    },[data])




    return (
        <CardWrapper href="/admin/emp-list" title="Employees">
            <div className='text-black50 mt-6 flex flex-col gap-4' >
                <EmployeeData heading='SEO Employees' Employees={SeoEmployes as Employee[]} />
                <EmployeeData heading='CMS Employees' Employees={CmsEmployes as Employee[]} />
            </div>
        </CardWrapper>
    );
}
