import { Link } from 'react-router-dom';

export function BlueButton(props: { to: string; state: any; label: string }) {
    return (
        <Link to={props.to} state={props.state}>
            <button
                type="button"
                className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover mt-2"
            >
                <div className="space-x-2 align-middle">
                    <span>{props.label}</span>
                </div>
            </button>
        </Link>
    );
}
