import { Employee } from "../../../api/generated";
import EmployeeCard from "../EmployeeCard";


type EmployeeDataProps = {
    heading : string
    Employees : Employee[]
}


const EmployeeData = ({heading, Employees}:EmployeeDataProps) => {
    return (
        <div className="text-black50" >
            <div>
                <div className=" font-bold text-xl mb-6">{heading}</div>
            </div>
            <div className="flex flex-col gap-6" >
                {Employees.slice(0, 3)
                    .map((employee) => {
                        return (
                            <EmployeeCard
                                key={employee?.id}
                                employee={employee}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default EmployeeData;
