import { useFormikContext } from 'formik';
import { ExportCSVButton } from './ExportCSVButton';
import { InitialValues } from './Table';

export function SelectColumns() {
    const formik = useFormikContext<InitialValues>();
    return (
        <div className="bg-white border rounded-lg">
            <div className="py-3 px-2">
                <div className="text-center text-xl mb-4">Select Columns</div>
                <div className="grid grid-cols-3">
                    {formik.values.csvHeader.map((columnHeader, colIndex) => (
                        <div
                            key={columnHeader}
                            className="flex flex-row p-1 space-x-2"
                        >
                            <label
                                htmlFor={columnHeader}
                                className="basis-2/3 align-middle"
                            >
                                {columnHeader}
                            </label>
                            <input
                                checked={formik.values.selectColumns.includes(
                                    colIndex
                                )}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        formik.setFieldValue('selectColumns', [
                                            ...formik.values.selectColumns,
                                            colIndex,
                                        ]);
                                    } else {
                                        const filterColums =
                                            formik.values.selectColumns.filter(
                                                (value) => value !== colIndex
                                            );
                                        formik.setFieldValue(
                                            'selectColumns',
                                            filterColums
                                        );
                                    }
                                }}
                                type="checkbox"
                                name={columnHeader}
                                className="align-middle"
                            />
                        </div>
                    ))}
                </div>
                <div className="flex flex-col items-center">
                    <ExportCSVButton />
                </div>
            </div>
        </div>
    );
}
