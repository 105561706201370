/* eslint-disable react/button-has-type */
// import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useSelect } from "downshift";
import { ErrorMessage, useFormikContext } from "formik";
import { HTMLAttributes, useMemo } from "react";

// import Label from "../Label";

export interface SelectProps<T> {
    name: Extract<keyof T, string>;
    containerProps?: HTMLAttributes<HTMLDivElement>;
    value?: Option;
    placeholder?: string;
    options?: Option[] | undefined;
    label?: string;
    disable?: boolean;
    isRequired?: boolean;
}

export interface Option {
    label?: string | null | undefined;
    value?: string | null | number | undefined;
}

const Select = <T,>({
    name,
    value,
    label,
    disable,
    options = [],
    isRequired,
    ...props
}: SelectProps<T>) => {
    const { errors, touched, setFieldValue, setFieldTouched, values } =
        useFormikContext<T>();

    const selectedItem = useMemo<Option | undefined>(() => {
        return (
            options.find((option) => String(option.value) === String(values[name])) ||
            value
        );
    }, [name, options, value, values]);

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
    } = useSelect<Option>({
        items: options,

        onSelectedItemChange({ selectedItem: selected }) {
            setFieldValue(name, selected?.value);
            setFieldTouched(name, true);
        },
        itemToString(item) {
            return item?.value?.toString() || "";
        },
    });

    return (

        <div className="relative w-full items-center text-sm  ">
            {isRequired ? label && <div className="flex gap-1"> <label htmlFor={name} className="block text-sm font-semibold text-slate-500 mb-1">{label}</label> <span className="text-red ">*</span></div> :
                label && <label htmlFor={name} className="block text-sm font-semibold text-slate-500 mb-1">{label}</label>}
            <div
                className={classNames(
                    "py-1.5 px-3 flex border rounded-sm w-full",
                    "focus-within:black focus-within:shadow-sm focus-within:to-black20",
                    touched[name]
                        ? errors[name]
                            ? "border-red shadow "
                            : "border-slate-600"
                        : "border-slate-400",
                )}
            >
                <button
                    {...getToggleButtonProps({ type: "button", disabled: disable })}
                    className="outline-none flex-1 flex items-center justify-between"

                >
                    <div className="text">
                        {selectedItem
                            ? selectedItem.label
                            : props.placeholder
                                ? props.placeholder
                                : "Status"}
                    </div>

                    <div className="flex justify-center items-center">
                        {isOpen ? (
                            <ChevronDownIcon className="w-4 h-4 stroke-secondary" />
                        ) : (
                            <ChevronUpIcon className="w-4 h-4 stroke-secondary" />
                        )}
                    </div>
                </button>
            </div>

            <ul
                {...getMenuProps()}
                className={`absolute z-50 rounded-sm bg-white shadow-xl mt-2 w-full ${isOpen ? "border border-gray" : ""
                    }`}
            >
                {isOpen && (
                    <>
                        {options.map((item, index) => (
                            <li
                                key={item.value}
                                className={classNames(
                                    highlightedIndex === index && "bg-slate-100 text-trev",

                                    selectedItem === options[index] &&
                                    "bg-slate-100 text-trev",

                                    "px-5 cursor-pointer py-2 flex gap-3 items-center hover:bg-background-3 ",
                                )}
                                {...getItemProps({
                                    index,

                                    item,
                                })}
                            >
                                <span className="">{item.label}</span>
                            </li>
                        ))}
                    </>
                )}
            </ul>

            <ErrorMessage
                name={name}
                render={(message) => (
                    <div className="text-primary-4 w-0 min-w-full text-xs mt-1">
                        {message}
                    </div>
                )}
            />
        </div>
    );
};

export default Select;
