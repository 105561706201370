import {
    Employee,
    Role,
    SeoOrders,
    useGetEmployeeWithRoleQuery,
    useGetSeoOrderByIdQuery,
} from '../../api/generated';
import Comments from '../ViewOrder/Comments';
import ClientOrderHeader from './ClientOrderHeader';

import InstructionAccordion from './InstructionAccordion';
import TablesOrder from './Table';

export default function SeoViewOrder(props: { orderID: string }) {
    const { data, refetch } = useGetSeoOrderByIdQuery({
        variables: {
            input: {
                seoOrderID: props.orderID,
            },
        },
    });

    const { data: employeesAssignable } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Seoemployee],
            },
        },
    });

    const order = data && data.getSeoOrderByID;
    return (
        <>
            <ClientOrderHeader
                id={order?.id?.toString() || ''}
                clientName={data?.getSeoOrderByID?.order?.client?.name || ''}
                // seoOrderManagerID={
                //     data?.getSeoOrderByID?.seoOrderManager?.id as number
                // }
                // seoOrderManagerName={`${data?.getOrderByID?.seoOrderManager?.firstName} ${data?.getOrderByID?.seoOrderManager?.lastName}`}
                startDate={order?.order?.orderStartDate || ''}
                dueDate={order?.order?.orderDueDate || ''}
                articleCount={order?.order?.ArticleCounts || 0}
                requiredDA={order?.order?.requiredDA || 0}
                requiredDR={order?.order?.requiredDR || 0}
                clientSiteURL={
                    (order?.ClientSiteUrl &&
                        order.ClientSiteUrl[0]?.clientSiteURL) ||
                    ''
                }
                employeeAssignable={
                    employeesAssignable?.getEmployeeWithRole as Employee[]
                }
                status={order?.orderStatus || ''}
                refetch={refetch}
            />
            <InstructionAccordion
                seoInstruction={order?.instructionsSeoEmployee || ''}
                cmsInstruction={
                    (order?.CmsOrders &&
                        order?.CmsOrders[0]?.instructionCmsAdmin) ||
                    ''
                }
            />
            <TablesOrder
                order={order as SeoOrders}
                employeesAssignable={
                    employeesAssignable?.getEmployeeWithRole as Employee[]
                }
            />
            <Comments orderID={order?.id as string} />
        </>
    );
}
