import { useFormikContext } from 'formik';
import { ReactElement, useEffect } from 'react';
import DataGrid from 'react-data-grid';
import { Link } from 'react-router-dom';
import {
    DomainTransferRequest,
    useAcceptDomainTransferRequestMutation,
    useGetEmployeeUsingTokenQuery,
    usePaginatedTransferRequestForEmployeeQuery,
    useRejectDomainTransferRequestMutation,
} from '../../api/generated';
import { TransferDomainListInitialValues } from './TransferDomainListInitialValues';

interface TableRows {
    srnum: number;
    siteURL: string;
    contactType: string;
    requestBy: string;
    dateOfRequest: string;
    siteDA: number;
    finalPrice: number;
    specialPrice: number;
    status: ReactElement;
}

export function Table(props: {
    data: DomainTransferRequest[];
    refetchRequests: any;
}) {
    const formik = useFormikContext<TransferDomainListInitialValues>();
    const data = props.data || [];

    const columns = [
        {
            key: 'srnum',
            name: 'Sr.No.',
        },
        {
            key: 'siteURL',
            name: 'Site URL',
        },
        {
            key: 'contactType',
            name: 'Contact Type',
        },
        {
            key: 'requestBy',
            name: 'Request By',
        },
        {
            key: 'dateOfRequest',
            name: 'Date Of Request',
        },
        {
            key: 'siteDA',
            name: 'Site DA',
        },
        {
            key: 'finalPrice',
            name: 'Final Price',
        },
        {
            key: 'specialPrice',
            name: 'Special Price',
        },
        {
            key: 'status',
            name: 'Status',
        },
    ];

    const rows: TableRows[] = [];

    const [acceptDomainTransferRequest] =
        useAcceptDomainTransferRequestMutation();
    const [rejectDomainTransferRequest] =
        useRejectDomainTransferRequestMutation();

    data.forEach((row, i) => {
        rows.push({
            srnum: i + 1,
            siteURL: row.domain?.siteUrl as string,
            contactType: row.contactType?.contactType as string,
            siteDA: row.siteDA as number,
            finalPrice: row.finalPrice as number,
            specialPrice: row.specialPrice as number,
            status:
                formik.values.employeeID === row.employeeRequestBy?.id ||
                row.status !== 'Processing' ? (
                    <div
                        className={`${
                            row.status === 'Processing'
                                ? 'bg-orange-200'
                                : row.status === 'Accepted'
                                ? 'bg-green-200'
                                : 'bg-rose-400'
                        }`}
                    >
                        {row.status}
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-3">
                        <button
                            type="button"
                            className="bg-green-200 rounded-md"
                            onClick={async () => {
                                const { data } =
                                    await acceptDomainTransferRequest({
                                        variables: {
                                            input: {
                                                id: row.id,
                                            },
                                        },
                                    });

                                if (
                                    data?.acceptDomainTransferRequest?.success
                                ) {
                                    props.refetchRequests();
                                }
                            }}
                        >
                            Accept
                        </button>
                        <button
                            type="button"
                            className="bg-rose-200 rounded-md"
                            onClick={async () => {
                                const { data } =
                                    await rejectDomainTransferRequest({
                                        variables: {
                                            input: {
                                                id: row.id,
                                            },
                                        },
                                    });

                                if (
                                    data?.rejectDomainTransferRequest?.success
                                ) {
                                    props.refetchRequests();
                                }
                            }}
                        >
                            Reject
                        </button>
                    </div>
                ),
            requestBy: `${row.employeeRequestBy?.firstName} ${row.employeeRequestBy?.lastName}`,
            dateOfRequest: row.createdAt.substring(0, 10),
        });
    });
    if (rows.length === 0) {
        return (
            <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center">
                No Data To be Displayed
            </div>
        );
    }
    return (
        <DataGrid
            className="rdg-light text-center"
            columns={columns}
            rows={rows}
            defaultColumnOptions={{
                sortable: true,
                minWidth: 269.5,
            }}
            style={{
                rowGap: 10,
            }}
            rowHeight={40}
        />
    );
}

export function TableOrGrid() {
    const formik = useFormikContext<TransferDomainListInitialValues>();
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data: domainTransferRequests, refetch } =
        usePaginatedTransferRequestForEmployeeQuery({
            variables: {
                input: {
                    employeeID: loggedInUser?.getEmployeeUsingToken?.id,
                    inOrOut:
                        formik.values.predefinedFilters === 'Incoming'
                            ? 'in'
                            : formik.values.predefinedFilters === 'Outgoing'
                            ? 'out'
                            : 'all',
                    take: Number(formik.values.paginationRowCount),
                    skip:
                        Number(formik.values.pageNum) *
                        Number(formik.values.paginationRowCount),
                },
            },
        });

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    useEffect(() => {
        formik.setFieldValue(
            'employeeID',
            loggedInUser?.getEmployeeUsingToken?.id
        );
    }, [loggedInUser]);

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            domainTransferRequests?.paginatedTransferRequestForEmployee
                ?.totalPages
        );
    }, [
        domainTransferRequests?.paginatedTransferRequestForEmployee?.totalPages,
    ]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            domainTransferRequests?.paginatedTransferRequestForEmployee
                ?.totalRows
        );
    }, [
        domainTransferRequests?.paginatedTransferRequestForEmployee?.totalRows,
    ]);
    if (
        domainTransferRequests?.paginatedTransferRequestForEmployee
            ?.totalPages === 0
    ) {
        return (
            <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center">
                No Data To be Displayed
            </div>
        );
    }

    return formik.values.toggleView ? (
        <Grid
            data={
                domainTransferRequests?.paginatedTransferRequestForEmployee
                    ?.requests as DomainTransferRequest[]
            }
            refetchRequests={refetch}
        />
    ) : (
        <Table
            data={
                domainTransferRequests?.paginatedTransferRequestForEmployee
                    ?.requests as DomainTransferRequest[]
            }
            refetchRequests={refetch}
        />
    );
}

function Grid(props: { data: DomainTransferRequest[]; refetchRequests: any }) {
    const data = props.data || [];
    return (
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-6 ">
            {data.map((row) => (
                <GridCard
                    key={row.id}
                    row={row}
                    refetchRequests={props.refetchRequests}
                />
            ))}
        </div>
    );
}

function GridCard(props: { row: DomainTransferRequest; refetchRequests: any }) {
    const formik = useFormikContext<TransferDomainListInitialValues>();
    const [acceptDomainTransferRequest] =
        useAcceptDomainTransferRequestMutation();
    const [rejectDomainTransferRequest] =
        useRejectDomainTransferRequestMutation();
    const { row } = props;
    return (
        <div className="border-2 rounded-lg text-sm p-3 text-slate-500 shadow-xl hover:bg-blue-100 space-y-3 w-full">
            <div>
                <Link
                    to="/seo/employee/transfer-domain-list/request"
                    state={{ requestID: row.id }}
                >
                    #{row.id}
                </Link>
            </div>
            <div className="flex flex-row my-1">
                <div className="basis-1/4">Site URL:&nbsp;</div>
                <div className="basis-3/4 font-bold text-blue-600 text-right">
                    {props.row.domain?.siteUrl}
                </div>
            </div>
            <div className="grid grid-cols-2">
                <div>
                    DA:{' '}
                    <span className="font-bold text-black">
                        {props.row.siteDA}
                    </span>
                </div>
                <div className="text-right">
                    Contact Type:{' '}
                    <span className="font-bold text-black">
                        {props.row.contactType?.contactType}
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-2">
                <div>
                    Price:
                    <span className="font-bold text-black">
                        {props.row.finalPrice}
                    </span>
                </div>
                <div className="text-right">
                    Speical Price:
                    <span className="font-bold text-black">
                        {props.row.specialPrice}
                    </span>
                </div>
            </div>
            <div className="text-center">
                Request By:&nbsp;
                <span className="font-bold text-black">
                    {`${props.row.employeeRequestBy?.firstName} ${props.row.employeeRequestBy?.lastName}`}
                </span>
            </div>
            <div className="text-center">
                Date Of Request:&nbsp;
                <span className="font-bold text-black">
                    {props.row.createdAt.substring(0, 10)}
                </span>
            </div>
            <div className="flex justify-center pt-3">
                {formik.values.employeeID === row.employeeRequestBy?.id ||
                row.status !== 'Processing' ? (
                    <div
                        className={`text-black px-3 py-2 text-center rounded-md w-32 ${
                            row.status === 'Processing'
                                ? 'bg-orange-200'
                                : row.status === 'Accepted'
                                ? 'bg-green-300'
                                : 'bg-rose-400'
                        }`}
                    >
                        {row.status}
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-3">
                        <button
                            type="button"
                            className="bg-green-300 text-black px-3 w-32 py-2 text-md rounded-md"
                            onClick={async () => {
                                const { data } =
                                    await acceptDomainTransferRequest({
                                        variables: {
                                            input: {
                                                id: row.id,
                                            },
                                        },
                                    });

                                if (
                                    data?.acceptDomainTransferRequest?.success
                                ) {
                                    props.refetchRequests();
                                }
                            }}
                        >
                            Accept
                        </button>
                        <button
                            type="button"
                            className="bg-rose-400 text-black px-3 w-32 py-2 text-md rounded-md"
                            onClick={async () => {
                                const { data } =
                                    await rejectDomainTransferRequest({
                                        variables: {
                                            input: {
                                                id: row.id,
                                            },
                                        },
                                    });

                                if (
                                    data?.rejectDomainTransferRequest?.success
                                ) {
                                    props.refetchRequests();
                                }
                            }}
                        >
                            Reject
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
