import EmpList from '../../components/empList/ListEmp';
import RootLayout from '../../shared/Icons/RootLayout';

export default function EmpLoyeeList() {
    return (
        <RootLayout activeElement='employee' ProfileBarTitle='Employee List'   >
            <EmpList />
        </RootLayout>
    );
}

