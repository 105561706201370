import {
    Employee,
    MainOrders,
    Role,
    useGetEmployeeWithRoleQuery,
    useGetOrderByIdQuery,
} from '../../api/generated';
import ClientOrderHeader from './ClientOrderHeader';
import Comments from './Comments';
import InstructionAccordion from './InstructionAccordion';
import TablesOrder from './Table';

export default function ViewOrder(props: { orderID: string }) {
    const { data, refetch } = useGetOrderByIdQuery({
        variables: {
            input: {
                orderID: props.orderID,
            },
        },
    });

    const { data: employeesAssignable } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Seoemployee,Role.Admin,Role.Seoadmin],
            },
        },
    });

    const order = data && data.getOrderByID;
    return (
        <>
            <ClientOrderHeader
                id={order?.id?.toString() || ''}
                clientName={data?.getOrderByID?.client?.name || ''}
                clientID={data?.getOrderByID?.client?.id || 0}
                seoOrderManagerID={
                    data?.getOrderByID?.seoOrderManager?.id as number
                }
                seoOrderManagerName={`${data?.getOrderByID?.seoOrderManager?.firstName} ${data?.getOrderByID?.seoOrderManager?.lastName}`}
                startDate={order?.orderStartDate || ''}
                dueDate={order?.orderDueDate || ''}
                articleCount={order?.ArticleCounts || 0}
                requiredDA={order?.requiredDA || 0}
                requiredDR={order?.requiredDR || 0}
                clientSiteURL={order?.clientSiteURL || ''}
                employeeAssignable={
                    employeesAssignable?.getEmployeeWithRole as Employee[]
                }
                status={order?.orderStatus || ''}
                refetch={refetch}
            />
            <InstructionAccordion
                seoInstruction={
                    (order?.SeoOrders &&
                        order?.SeoOrders[0]?.instructionsSeoEmployee) ||
                    ''
                }
                cmsInstruction={
                    (order?.SeoOrders &&
                        order.SeoOrders[0]?.CmsOrders &&
                        order?.SeoOrders[0]?.CmsOrders[0]
                            ?.instructionCmsAdmin) ||
                    ''
                }
            />
            <TablesOrder
                order={order as MainOrders}
                employeesAssignable={
                    employeesAssignable?.getEmployeeWithRole as Employee[]
                }
            />
            <Comments orderID={order?.id as string} />
        </>
    );
}
