import '../../styles/ClientOrders.css';
import '../../styles/ProfileBar.css';
import '../../styles/styles.css';

import { Formik } from 'formik';

import { PaginationFooter } from '../main/PaginationFooter';
import PathBar from '../main/PathBar';

import Search from './Search';
import Table from './Table';

function EmpList() {
    return (
        <div className="bg-zedex-bg-gray pt-20 ">
            <PathBar />
            <div className="Client-Order">
                <Formik
                    initialValues={{
                        paginationRowCount: 15,
                        pageNum: 0,
                        totalPages: 1,
                        totalRows: 0,
                        sortBy: 'id',
                        sortType: 'asc',
                        search: {
                            contains: '',
                        },
                        roleFilter: [
                            'ADMIN',
                            'SEOADMIN',
                            'CMSADMIN',
                            'CMSEMPLOYEE',
                            'SEOEMPLOYEE',
                        ],
                    }}
                    onSubmit={() => {
                        //
                    }}
                >
                    <>
                        <Search />
                        <Table />
                        <br />
                        <PaginationFooter />
                    </>
                </Formik>
            </div>
        </div>
    );
}

export default EmpList;
