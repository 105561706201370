import '../../styles/ProfileBar.css';

interface IPercentBarProps {
    height: number;
    name: string;
    bgcolor: string;
    progress: string;
    numbre: number;
}
export default function Percent(propse: IPercentBarProps) {
    const Parentdiv = {
        height: propse.height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 50,
    };

    const Childdiv = {
        height: '100%',
        width: `${propse.progress}%`,
        backgroundColor: propse.bgcolor,
        borderRadius: 50,
    };

    const progresstext = {
        padding: 0,
        fontWeight: 'bold',
    };
    const numberText = {
        color: propse.bgcolor,
        fontWeight: 400,
    };

    return (
        <div className="py-1">
            <span
                className="graphTitle"
                style={progresstext}
            >{`${propse.name}`}</span>
            <span
                className="float-right"
                style={numberText}
            >{`${propse.numbre}`}</span>
            <div style={Parentdiv}>
                <div style={Childdiv} />
            </div>
        </div>
    );
}
