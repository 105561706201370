import CalendarDaysIcon from '@heroicons/react/20/solid/CalendarDaysIcon';
import {
    RangeFilterDateAsString,
    RangeFilterInteger,
    SearchFilter,
    SeoOrders,
    useGetEmployeeUsingTokenQuery,
    usePaginatedSeoOrderListQuery,
} from '../../api/generated';

import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { Link } from 'react-router-dom';

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    statusFilter: string[];
    filterToggle: boolean;
    search: SearchFilter;
    siteDaReqFilter: RangeFilterInteger;
    siteDrReqFilter: RangeFilterInteger;
    articleCountsFilter: RangeFilterInteger;
    dueDateFilter: RangeFilterDateAsString;
    toggleView: boolean;
}

interface SeoOrderListTableRow {
    id: string;
    requiredDA: number;
    requiredDR: number;
    orderID: string;
    articleCount: number;
    clientName: string;
    startDate: string;
    dueDate: string;
    clientSiteURL: string;
    keyword: string;
    status: any;
}

export function TableAndGridSeoEmp() {
    const formik = useFormikContext<InitialValues>();
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data } = usePaginatedSeoOrderListQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                statusFilter: formik.values.statusFilter,
                search: {
                    ...formik.values.search,
                },
                siteDaReq: {
                    ...formik.values.siteDaReqFilter,
                },
                siteDrReq: {
                    ...formik.values.siteDrReqFilter,
                },
                articleCountsFilter: {
                    ...formik.values.articleCountsFilter,
                },
                dueDateFilter: {
                    ...formik.values.dueDateFilter,
                },
                forEmployeeID: loggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedSeoOrderList?.totalPages
        );
    }, [data?.paginatedSeoOrderList?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.paginatedSeoOrderList?.totalRows
        );
    }, [data?.paginatedSeoOrderList?.totalRows]);
    if (data?.paginatedSeoOrderList?.totalPages === 0) {
        return (
            <div className="rounded-lg mt-5 p-5 bg-blue-100 text-blue-500 text-center">
                No Data To be Displayed
            </div>
        );
    }
    if (!formik.values.toggleView) {
        const columns = [
            SelectColumn,
            { key: 'id', name: 'Seo Order ID' },
            {
                key: 'requiredDA',
                name: 'Required DA',
                sortable: true,
            },
            {
                key: 'requiredDR',
                name: 'Required DR',
                sortable: true,
            },
            {
                key: 'orderID',
                name: 'Main Order ID',
                sortable: true,
            },
            {
                key: 'articleCount',
                name: 'Number of Links',
                sortable: true,
            },
            {
                key: 'clientName',
                name: 'Client Name',
                sortable: true,
            },
            {
                key: 'startDate',
                name: 'Start Date',
                sortable: true,
            },
            {
                key: 'dueDate',
                name: 'Due Date',
                sortable: true,
            },
            {
                key: 'clientSiteURL',
                name: 'Client Site URL',
                sortable: true,
            },
            {
                key: 'keyword',
                name: 'Keyword',
                sortable: true,
            },
            {
                key: 'status',
                name: 'Status',
            },
        ];
        const rows: SeoOrderListTableRow[] = [];

        data?.paginatedSeoOrderList?.seoOrderList.forEach((seoOrder) => {
            const timeDue =
                new Date().getTime() -
                new Date(seoOrder?.order?.orderDueDate).getTime();
            const timeDueinDays = timeDue / (1000 * 3600 * 24);
            const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
            const clientSiteURL =
                seoOrder?.ClientSiteUrl && seoOrder.ClientSiteUrl[0];
            rows.push({
                id: seoOrder?.id as string,
                requiredDA: clientSiteURL?.requiredDA as number,
                requiredDR: clientSiteURL?.requiredDR as number,
                orderID: seoOrder?.order?.id as string,
                articleCount: seoOrder?.order?.ArticleCounts as number,
                clientName: seoOrder?.order?.client?.name as string,
                startDate: seoOrder?.order?.orderStartDate.substring(0, 10),
                dueDate: seoOrder?.order?.orderDueDate.substring(0, 10),
                clientSiteURL: clientSiteURL?.clientSiteURL as string,
                keyword: clientSiteURL?.keyword as string,
                status:
                    seoOrder?.orderStatus === 'Overdue' ? (
                        <td className="font-bold">
                            <span className="block overdue-status p-2 m-1">
                                Overdue
                                <br />
                                <span className="text-[10px] text-red">
                                    Due past{' '}
                                    {timeDueinDays >= 7
                                        ? timeDueinWeeks
                                              .toString()
                                              .concat('weeks')
                                              .concat(` ${timeDueinDays}`)
                                        : timeDueinDays
                                              .toString()
                                              .concat(' Days')}
                                </span>
                            </span>
                        </td>
                    ) : seoOrder?.orderStatus === 'Cancelled' ? (
                        <td className="font-bold">
                            <span className="block cancelled-status p-2 m-1">
                                Cancelled
                            </span>
                        </td>
                    ) : seoOrder?.orderStatus === 'Done' ? (
                        <td className="font-bold">
                            <span className="block done-status p-2 m-1">
                                Done
                            </span>
                        </td>
                    ) : seoOrder?.orderStatus === 'Processing' ? (
                        <td className="font-bold">
                            <span className="block processing-status p-2 m-1">
                                Processing
                                <br />
                                <span className="text-[10px] text-orange">
                                    Due in{' '}
                                    {timeDueinDays >= 7
                                        ? timeDueinWeeks
                                              .toString()
                                              .concat('weeks')
                                              .concat(` ${timeDueinDays}`)
                                        : timeDueinDays
                                              .toString()
                                              .concat(' Days')}
                                </span>
                            </span>
                        </td>
                    ) : (
                        seoOrder?.orderStatus === 'Issue Raised' && (
                            <td className="font-bold">
                                <span className=" block issue-raised-status p-2 m-1">
                                    Issue Raised
                                </span>
                            </td>
                        )
                    ),
            });
        });
        return (
            <div className="client-order-table-grid overflow-x-auto overflow-y-auto text-center bg-transparent">
                <DataGrid
                    className="rdg-light fill-grid w-full"
                    columns={columns}
                    rows={rows}
                    defaultColumnOptions={{
                        sortable: true,
                        minWidth: 250,
                        resizable: true,
                    }}
                    rowHeight={60}
                />
            </div>
        );
    }
    return (
        <div className="client-order-table-grid grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 lg:gap-4 overflow-y-auto scrollbar-hide text-center bg-transparent">
            {data?.paginatedSeoOrderList?.seoOrderList?.map(
                (row) =>
                    row && <GridCard key={row.id?.toString()} seoOrder={row} />
            )}
        </div>
    );
}

function GridCard(props: { seoOrder: SeoOrders }) {
    const timeDue =
        new Date(props?.seoOrder?.order?.orderDueDate).getTime() -
        new Date().getTime();

    const timeDueinDays = Math.ceil(timeDue / (1000 * 3600 * 24));
    const timeDueinHours = Math.ceil(timeDue / (1000 * 3600));

    const clientSiteURL =
        props.seoOrder &&
        props.seoOrder?.ClientSiteUrl &&
        props.seoOrder.ClientSiteUrl;

    const clientSiteUrlTrimmed =
        clientSiteURL &&
        clientSiteURL[0]?.clientSiteURL &&
        clientSiteURL[0]?.clientSiteURL?.length > 29
            ? clientSiteURL[0]?.clientSiteURL.substring(0, 29).concat('....')
            : clientSiteURL && clientSiteURL[0]?.clientSiteURL;
    return (
        <Link
            to="/seo/employee/order-list/view-order"
            state={{ orderID: props.seoOrder.id }}
        >
            <div className="grid-order-card p-3 rounded-xl shadow-lg border-2 border-slate-400 hover:bg-blue-100">
                <div className="border-b-2 h-12 border-slate-400 ">
                    <div className="text-xs font-bold text-left text-zedex-text-blue">
                        #{props.seoOrder.id}
                    </div>
                    <div className="text-md mb-2">
                        <span className="float-left font-bold">
                            {props?.seoOrder?.order?.client?.name}
                        </span>
                        <span
                            className={
                                timeDueinDays > 0
                                    ? 'float-right done-status p-1 text-[10px]'
                                    : 'float-right overdue-status p-1 text-[10px]'
                            }
                        >
                            {timeDueinDays > 0 ? 'Due in' : 'Due Past'}{' '}
                            {timeDueinDays === 0
                                ? Math.abs(timeDueinHours)
                                      .toString()
                                      .concat(' Hours')
                                : Math.abs(timeDueinDays)
                                      .toString()
                                      .concat(' Days')}
                        </span>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="text-xs grid grid-cols-2 gap-3">
                        <span className="text-left">
                            No. of Links to Build:&nbsp;
                            <span className="font-bold">
                                {props?.seoOrder?.order?.ArticleCounts}
                            </span>
                        </span>
                        <span className="text-right">
                            DA/DR Required:&nbsp;
                            <span className="font-bold">
                                {props?.seoOrder?.order?.requiredDA}
                            </span>
                        </span>
                    </div>
                    <div className="text-xs text-left mt-2">
                        <span className="">Client Site URL:</span>
                        <span
                            className=""
                            title={
                                (clientSiteURL &&
                                    clientSiteURL[0]?.clientSiteURL) ||
                                ''
                            }
                        >
                            {clientSiteUrlTrimmed}
                        </span>
                    </div>
                    <div className="text-xs grid grid-cols-2 mt-2">
                        <span className="text-left space-x-2">
                            <CalendarDaysIcon
                                color="#3bbf68"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">
                                Start Date: </span>
                                <span className='align-middle block'>{props?.seoOrder?.order?.orderStartDate?.substring(
                                    0,
                                    10
                                )}
                            </span>
                        </span>
                        <span className="text-right space-x-2">
                            <CalendarDaysIcon
                                color="red"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">Due Date: </span>
                            <span className='align-middle'>{props?.seoOrder?.order?.orderDueDate?.substring(
                                0,
                                10
                            )}</span>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

function TableRow(props: SeoOrders) {
    const timeDue =
        new Date().getTime() - new Date(props.order?.orderDueDate).getTime();
    const timeDueinDays = timeDue / (1000 * 3600 * 24);
    const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
    return (
        <tr className="text-xs">
            <td className="py-5">
                <input type="checkbox" />
            </td>
            <td className="underline">
                <Link
                    to="/admin/order-list/view-order"
                    state={{ orderID: props.id }}
                >
                    <span>{`#${props.id}`}</span>
                </Link>
            </td>

            {/* <td>{props?.ClientSiteUrl[0].}</td>
            <td>{props?.requiredDR}</td> */}
            <td className="underline">
                <Link
                    to="/admin/order-list/view-order"
                    state={{ orderID: props.id }}
                >
                    <span>{`#${props.id}`}</span>
                </Link>
            </td>
            <td>{props?.order?.ArticleCounts}</td>
            <td>{props?.order?.client?.name}</td>
            <td>{props?.order?.orderStartDate?.substring(0, 10)}</td>
            <td>{props?.order?.orderDueDate?.substring(0, 10)}</td>
            <td>{props?.order?.clientSiteURL}</td>
            {/* keyword */}
            <td>{props.id}</td>
            {props?.orderStatus === 'Overdue' && (
                <td className="font-bold">
                    <span className="block overdue-status p-2 m-1">
                        Overdue
                        <br />
                        <span className="text-[10px] text-red">
                            Due past{' '}
                            {timeDueinDays >= 7
                                ? timeDueinWeeks
                                      .toString()
                                      .concat('weeks')
                                      .concat(` ${timeDueinDays}`)
                                : timeDueinDays.toString().concat(' Days')}
                        </span>
                    </span>
                </td>
            )}
            {props?.orderStatus === 'Cancelled' && (
                <td className="font-bold">
                    <span className="block cancelled-status p-2 m-1">
                        Cancelled
                    </span>
                </td>
            )}
            {props?.orderStatus === 'Done' && (
                <td className="font-bold">
                    <span className="block done-status p-2 m-1">Done</span>
                </td>
            )}
            {props?.orderStatus === 'Processing' && (
                <td className="font-bold">
                    <span className="block processing-status p-2 m-1">
                        Processing
                        <br />
                        <span className="text-[10px] text-orange">
                            Due in{' '}
                            {timeDueinDays >= 7
                                ? timeDueinWeeks
                                      .toString()
                                      .concat('weeks')
                                      .concat(` ${timeDueinDays}`)
                                : timeDueinDays.toString().concat(' Days')}
                        </span>
                    </span>
                </td>
            )}
            {props?.orderStatus === 'Issue Raised' && (
                <td className="font-bold">
                    <span className=" block issue-raised-status p-2 m-1">
                        Issue Raised
                    </span>
                </td>
            )}
        </tr>
    );
}
