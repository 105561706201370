import { NavElement } from "../shared/Icons/types";


export const navElemts: NavElement[] = [
    {
        name: 'dashboard',
        tooltip: 'Dashboard',
        icon: 'dashboard',
        authorise: ['ALL'],
        to: '/',
    },
    {
        name: 'employee',
        tooltip: 'Employee List',
        icon: 'team',
        authorise: ['ADMIN'],
        to: '/admin/emp-list',
    },
    {
        name: 'outreach',
        tooltip: 'Domain Outreach Sheet',
        icon: 'checklist',
        authorise: ['ADMIN', 'SEOADMIN', 'SEOEMPLOYEE'],
        to: '/admin/outreach-sheet',
    },

    {
        name: 'order',
        tooltip: 'Admin Order List',
        icon: 'Stack',
        authorise: ['ALL'],
        to: '/',
    },
    {
        name: 'inbox',
        tooltip: 'Packages',
        icon: 'inbox',
        authorise: ['ADMIN', 'SEOADMIN'],
        to: '/admin/packages',
    },
    {
        name: 'transferDomain',
        tooltip: 'Domain Transfer',
        icon: 'transfer',
        authorise: ['ADMIN', 'SEOADMIN', 'SEOEMPLOYEE'],
        to: '/seo/employee/transfer-domain-list',
    },
    {
        name: 'contentOrders',
        tooltip: 'Content Order',
        icon: 'contentorder',
        authorise: ['ADMIN', 'CMSADMIN', 'CMSEMPLOYEE'],
        to: '/admin/content-order-list',
    },
];
