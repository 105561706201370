export function exportCSVSelectColumns(
    data: string[][],
    header: string[],
    selectColumns: number[]
) {
    let selectedColumnsData: string[][] = [];
    let selectColumnHeader: string[] = [];

    if (selectColumns.length === header.length) {
        selectedColumnsData = data;
        selectColumnHeader = header;
    } else {
        data.forEach((row) => {
            const rowData: string[] = [];
            row.forEach((x, i) => {
                if (selectColumns.includes(i)) {
                    rowData.push(x);
                }
            });
            selectedColumnsData.push(rowData);
        });
        header.forEach((title, i) => {
            if (selectColumns.includes(i)) {
                selectColumnHeader.push(title);
            }
        });
    }
    const csvHeader = selectColumnHeader.join(',').concat('\n');
    const csvData = selectedColumnsData.map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvHeader, csvData], {
        type: 'text/csv;charset=utf-8;',
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');

    document.body.appendChild(link);

    link.click();

    URL.revokeObjectURL(url);
}
