export function DescendSvg() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 5.16666H2C1.72667 5.16666 1.5 4.93999 1.5 4.66666C1.5 4.39332 1.72667 4.16666 2 4.16666H14C14.2733 4.16666 14.5 4.39332 14.5 4.66666C14.5 4.93999 14.2733 5.16666 14 5.16666Z"
                fill="#00092E"
            />
            <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E"
            />
            <path
                d="M9.33332 11.8333H6.66666C6.39332 11.8333 6.16666 11.6067 6.16666 11.3333C6.16666 11.06 6.39332 10.8333 6.66666 10.8333H9.33332C9.60666 10.8333 9.83332 11.06 9.83332 11.3333C9.83332 11.6067 9.60666 11.8333 9.33332 11.8333Z"
                fill="#00092E"
            />
        </svg>
    );
}
