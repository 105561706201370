import '../../styles/PackageSheet.css';

import Accordion from './Accordion';
import ImageBar from './ImageBar';
import ProfBar from './ProfBar';

export default function ProfilePage() {
    return (
        <div>
            <ImageBar />
            <ProfBar />
            <Accordion />
        </div>
    );
}
