import Card from './Card';
import Notification from './Notification';
import OrderDisplay from './OrderPercent';
import CmsToDoList from './TodoList';
import Welcome from './WelcomeBar';

export default function CmsDashboard() {
    return (
        <div className="bg-[#f4f5fa] pt-20">
            <Welcome />
            <OrderDisplay />
            <CmsToDoList />
            <Card />
            <Notification />
        </div>
    );
}
