import { Link } from "react-router-dom";

export function CloseButton(props: {
    redirectURL: string;
    className?: string;
}) {
    return (
        <div
            className={`inline-block hover:skew-x-2 p-0.5 rounded-md bg-slate-200 ${props.className}`}
        >

            <Link to={props.redirectURL}>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </Link>

        </div>
    );
}
