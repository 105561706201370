import { useFormik, useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import DataGrid, { SelectColumn } from 'react-data-grid';
import {
    ActualDaUpdateInput,
    ArticleLinksUpdateInput,
    ClientSiteUrl,
    Employee,
    LiveLinksUpdateInput,
    MainOrders,
    SearchFilter,
    SelectedSiteUpdateInput,
    SubTaskReAssignInput,
    usePaginatedSubTaskListQuery,
    useViewOrderUpdateMutation,
} from '../../api/generated';
import { PaginationFooter } from '../main/PaginationFooter';
import { SearchBar } from '../main/SearchBar';

interface orderDetailsTableRows {
    id: string;
    clientSiteURL: ReactElement;
    keyword: ReactElement;
    selectedSite: ReactElement;
    actualDA: ReactElement;
    liveLinks: ReactElement;
    articleLinks: ReactElement;
    requiredDaDr: string;
    EmployeeAssigned: ReactElement;
    dateOfAssignment: string;
    status: ReactElement;
}

interface assignEmployee {
    seoOrderID: number;
    seoEmployeeID: number;
}

export interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    sortBy: string;
    sortType: string;
    edited: boolean;
    search: SearchFilter;
    subTaskAssignment: [assignEmployee];
    exportData: string[][];
    csvHeader: string[];
    selectColumns: number[];
    toggleSelectColumns: boolean;
    toggleEdit: false;
    edittedReqDA: number;
    edittedDueDate: string;
}

export default function TablesOrder(props: {
    order: MainOrders;
    employeesAssignable: Employee[];
}) {
    const formikContext = useFormikContext<InitialValues>();
    const [updateOrder] = useViewOrderUpdateMutation();

    const formik = useFormik({
        initialValues: {
            subTaskAssignment: [],
            liveLinks: [],
            articleLinks: [],
            clientSiteURL: [],
            keyword: [],
            selectedSites: [],
            actualDA: [],
            edited: false,
        },
        onSubmit: () => {
            updateOrder({
                variables: {
                    input: {
                        subTask: formik.values.subTaskAssignment,
                        liveLinks: formik.values.liveLinks,
                        articleLinks: formik.values.articleLinks,
                        clientSiteUrl: formik.values.clientSiteURL,
                        keyword: formik.values.keyword,
                        selectedSite: formik.values.selectedSites,
                        actualDA: formik.values.actualDA,
                    },
                },
            });
            formik.setFieldValue('subTaskAssignment', []);
            formik.setFieldValue('articleLinks', []);
            formik.setFieldValue('liveLinks', []);
            formik.setFieldValue('clientSiteURL', []);
            formik.setFieldValue('keyword', []);
            // subTaskRefetch();
        },
    });

    const columns = [
        SelectColumn,
        { key: 'id', name: 'Sr.No' },
        {
            key: 'clientSiteURL',
            name: 'Client Site URL',
        },
        {
            key: 'keyword',
            name: 'Client Keyword',
        },
        {
            key: 'selectedSite',
            name: 'Selected Site',
        },
        {
            key: 'actualDA',
            name: 'Actual DA',
        },
        {
            key: 'liveLinks',
            name: 'Live Links',
        },
        {
            key: 'articleLinks',
            name: 'Article Links',
        },
        {
            key: 'requiredDaDr',
            name: 'DA/DR Required',
        },

        {
            key: 'EmployeeAssigned',
            name: 'Assigned To Employee ',
        },
        {
            key: 'dateOfAssignment',
            name: 'Date of Assignment',
        },
        {
            key: 'status',
            name: 'Status',
        },
    ];

    const { data: subTasks } = usePaginatedSubTaskListQuery({
        variables: {
            input: {
                take: Number(formikContext.values.paginationRowCount),
                skip:
                    Number(formikContext.values.pageNum) *
                    Number(formikContext.values.paginationRowCount),
                orderID: props.order && props.order.id,
                search: formikContext.values.search,
            },
        },
    });

    useEffect(() => {
        formikContext.setFieldValue('pageNum', 0);
    }, [formikContext.values.paginationRowCount]);

    useEffect(() => {
        formikContext.setFieldValue(
            'totalPages',
            subTasks?.paginatedSubTaskList?.totalPages
        );
    }, [subTasks?.paginatedSubTaskList?.totalPages]);

    useEffect(() => {
        formikContext.setFieldValue(
            'totalRows',
            subTasks?.paginatedSubTaskList?.totalRows
        );
    }, [subTasks?.paginatedSubTaskList?.totalRows]);

    const rows: orderDetailsTableRows[] = [];
    subTasks?.paginatedSubTaskList?.seoOrderList.forEach((seoOrder) => {
        const cmsOrder = seoOrder?.CmsOrders && seoOrder?.CmsOrders[0];
        rows.push({
            id: seoOrder?.id as string,
            clientSiteURL: (
                <input
                    type="text"
                    className="w-full text-center bg-transparent focus:no-border"
                    defaultValue={
                        (seoOrder?.ClientSiteUrl &&
                            seoOrder?.ClientSiteUrl[0]?.clientSiteURL) ||
                        ''
                    }
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue(`clientSiteURL`, [
                            {
                                id:
                                    (seoOrder?.ClientSiteUrl &&
                                        seoOrder?.ClientSiteUrl[0]?.id) ||
                                    0,
                                clientSiteUrl: event.target.value,
                            },
                            ...formik.values.liveLinks.filter(
                                (siteURL: ClientSiteUrl) =>
                                    siteURL.id !==
                                    (seoOrder?.ClientSiteUrl &&
                                        seoOrder?.ClientSiteUrl[0]?.id)
                            ),
                        ]);
                    }}
                />
            ),
            keyword: (
                <input
                    type="text"
                    className="w-full text-center bg-transparent focus:no-border"
                    defaultValue={
                        (seoOrder?.ClientSiteUrl &&
                            seoOrder?.ClientSiteUrl[0]?.keyword) ||
                        ''
                    }
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue(`keyword`, [
                            {
                                id:
                                    (seoOrder?.ClientSiteUrl &&
                                        seoOrder?.ClientSiteUrl[0]?.id) ||
                                    0,
                                keyword: event.target.value,
                            },
                            ...formik.values.keyword.filter(
                                (siteURL: ClientSiteUrl) =>
                                    siteURL.id !==
                                    (seoOrder?.ClientSiteUrl &&
                                        seoOrder?.ClientSiteUrl[0]?.id)
                            ),
                        ]);
                    }}
                />
            ),
            liveLinks: (
                <input
                    type="text"
                    className="w-full text-center bg-transparent focus:no-border"
                    defaultValue={
                        (seoOrder?.LiveLinks &&
                            seoOrder?.LiveLinks[0]?.liveLinks) ||
                        ''
                    }
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue(`liveLinks`, [
                            {
                                id:
                                    (seoOrder?.LiveLinks &&
                                        seoOrder?.LiveLinks[0]?.id) ||
                                    0,
                                liveLink: event.target.value,
                            },
                            ...formik.values.liveLinks.filter(
                                (liveLink: LiveLinksUpdateInput) =>
                                    liveLink.id !==
                                    (seoOrder?.LiveLinks &&
                                        seoOrder?.LiveLinks[0]?.id)
                            ),
                        ]);
                    }}
                />
            ),
            articleLinks: (
                <input
                    className="w-full text-center bg-transparent"
                    type="text"
                    defaultValue={
                        (cmsOrder?.Articles &&
                            cmsOrder?.Articles[0]?.articleLink) ||
                        ''
                    }
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue(`articleLinks`, [
                            {
                                id:
                                    (seoOrder?.CmsOrders &&
                                        seoOrder?.CmsOrders[0]?.Articles &&
                                        seoOrder?.CmsOrders[0]?.Articles[0]
                                            ?.id) ||
                                    0,
                                articleLink: event.target.value,
                            },
                            ...formik.values.articleLinks.filter(
                                (articleLink: ArticleLinksUpdateInput) =>
                                    articleLink.id !==
                                    (seoOrder?.CmsOrders &&
                                        seoOrder?.CmsOrders[0]?.Articles &&
                                        seoOrder?.CmsOrders[0]?.Articles[0]?.id)
                            ),
                        ]);
                    }}
                />
            ),
            requiredDaDr: `${
                seoOrder?.ClientSiteUrl &&
                seoOrder?.ClientSiteUrl[0]?.requiredDA === 0
                    ? seoOrder?.ClientSiteUrl &&
                      seoOrder.ClientSiteUrl[0]?.requiredDR
                    : seoOrder?.ClientSiteUrl &&
                      seoOrder?.ClientSiteUrl[0]?.requiredDA
            }`,
            EmployeeAssigned: (
                <select
                    className="w-full text-center bg-transparent p-2 m-0"
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue('subTaskAssignment', [
                            {
                                id: seoOrder?.id,
                                seoEmpID: Number(event.target.value),
                            },
                            ...formik.values.subTaskAssignment.filter(
                                (subTask: SubTaskReAssignInput) =>
                                    subTask.id !== seoOrder?.id
                            ),
                        ]);
                    }}
                >
                    <option value={seoOrder?.seoEmp?.id as number}>
                        {seoOrder?.seoEmp?.id? seoOrder?.seoEmp?.firstName?.concat(
                            ` ${seoOrder?.seoEmp?.lastName}`
                        ):"None"}
                    </option>
                    {props?.employeesAssignable?.map(
                        (employee) =>
                            employee && (
                                <option
                                    key={employee.id}
                                    value={employee.id?.toString()}
                                >
                                    {employee.firstName?.concat(
                                        ` ${employee?.lastName}` as string
                                    )}
                                </option>
                            )
                    )}
                </select>
            ),
            dateOfAssignment: seoOrder?.dateOfAssignment?.substring(0, 10),
            selectedSite: (
                <input
                    className="w-full text-center bg-transparent"
                    type="text"
                    defaultValue={seoOrder?.selectedSite || ''}
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue('selectedSites', [
                            {
                                id: seoOrder?.id,
                                selectedSite: event.target.value,
                            },
                            ...formik.values.selectedSites.filter(
                                (selectedSite: SelectedSiteUpdateInput) =>
                                    selectedSite.id !== seoOrder?.id
                            ),
                        ]);
                    }}
                />
            ),
            actualDA: (
                <input
                    className="w-full text-center bg-transparent"
                    type="number"
                    min={0}
                    max={100}
                    defaultValue={seoOrder?.actualDA || 0}
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue('actualDA', [
                            {
                                id: seoOrder?.id,
                                actualDA: Number(event.target.value),
                            },
                            ...formik.values.actualDA.filter(
                                (actualDA: ActualDaUpdateInput) =>
                                    actualDA.id !== seoOrder?.id
                            ),
                        ]);
                    }}
                />
            ),
            status:
                seoOrder?.orderStatus === 'Cancelled' ? (
                    <span className="-status px-8 py-3">Cancelled</span>
                ) : seoOrder?.orderStatus === 'Done' ? (
                    <span className="done-status px-8 py-3">Done</span>
                ) : seoOrder?.orderStatus === 'Processing' ? (
                    <span className="processing-status px-8 py-3">
                        Processing
                    </span>
                ) : seoOrder?.orderStatus === 'Issue Raised' ? (
                    <span className="issue-raised-status px-8 py-3">
                        Issue Raised
                    </span>
                ) : (
                    <span>Status Un-available</span>
                ),
        });
    });

    const [selectedRows, setSelectedRows] = useState<Set<string>>(
        () => new Set()
    );

    useEffect(() => {
        const selectedRowsArray = Array.from(selectedRows.values());
        const exportRows: string[][] = [];
        const selectedRowObj =
            subTasks?.paginatedSubTaskList?.seoOrderList?.filter((subtask) =>
                selectedRowsArray.includes(subtask?.id as string)
            );

        selectedRowObj?.forEach((seoOrder) => {
            exportRows.push([
                seoOrder?.id as string,
                (seoOrder?.ClientSiteUrl &&
                    seoOrder?.ClientSiteUrl[0]?.clientSiteURL) as string,
                (seoOrder?.ClientSiteUrl &&
                    seoOrder?.ClientSiteUrl[0]?.keyword) as string,
                (seoOrder?.ClientSiteUrl &&
                    seoOrder?.ClientSiteUrl[0]?.requiredDA?.toString()) as string,
                seoOrder?.selectedSite as string,
                seoOrder?.actualDA?.toString() as string,
                (seoOrder?.LiveLinks &&
                    seoOrder.LiveLinks[0]?.liveLinks) as string,
                `${seoOrder?.seoEmp?.firstName} ${seoOrder?.seoEmp?.lastName}`,
                seoOrder?.dateOfAssignment.substring(0, 10),
            ]);
        });

        formikContext.setFieldValue('exportData', exportRows);
    }, [selectedRows]);

    return (
        <div className="text-center bg-transparent">
            <div className="text-left p-5 space-x-6 static">
                <div className=" text-centerp-5 inline-block align-middle text-zedex-button-blue lg:mr-48">
                    Tasks: {props.order && props.order.ArticleCounts}
                </div>
                <SearchBar placeholder="Search for SubTask" />
            </div>
            {formik.values.edited ? (
                <div className="w-[30rem] grid grid-cols-2 mb-4 ml-5 p-3 float-left">
                    <button
                        type="button"
                        onClick={() => {
                            formik.setFieldValue('edited', false);
                            formik.handleSubmit();
                        }}
                        className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
                    >
                        <div className="space-x-2 align-middle">
                            <span className="">Save</span>
                        </div>
                    </button>

                    <button
                        type="button"
                        onClick={() => {
                            formik.setFieldValue('edited', false);
                            formik.resetForm();
                            // subTaskRefetch();
                            window.location.reload();
                        }}
                        className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
                    >
                        <div className="space-x-2 align-middle">
                            <span className="">Reset</span>
                        </div>
                    </button>
                </div>
            ) : null}
            <div>
                {rows.length === 0 ? (
                    <div
                        className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center
                        "
                    >
                        No Data To be Displayed
                    </div>
                ) : (
                    <DataGrid
                        className="rdg-light fill-grid w-full"
                        columns={columns}
                        rows={rows}
                        selectedRows={selectedRows}
                        rowKeyGetter={rowKeyGetter}
                        onSelectedRowsChange={(rowsSelected) => {
                            setSelectedRows(rowsSelected);
                        }}
                        defaultColumnOptions={{
                            sortable: true,
                            minWidth: 250,
                            resizable: true,
                        }}
                        rowHeight={60}
                    />
                )}
            </div>
            <div>
                <PaginationFooter />
            </div>
        </div>
    );
}

function rowKeyGetter(rows: orderDetailsTableRows) {
    return rows.id;
}
