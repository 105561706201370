import { accessTokenVar, isLoggedInVar, userRoleVar } from './account';

import { InMemoryCache } from '@apollo/client';

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read() {
                        return isLoggedInVar();
                    },
                },
                accessToken: {
                    read() {
                        return accessTokenVar();
                    },
                },
                userRole: {
                    read() {
                        return userRoleVar();
                    },
                },
            },
        },
    },
});
