import { ContentOrderServiceTypeAttributeValue } from '../../api/generated';

export function OrderServiceType(props: {
    serviceTypeAttributeValues: ContentOrderServiceTypeAttributeValue[];
}) {
    return props.serviceTypeAttributeValues ? (
        <div className="p-1 black bg-zedex-bg-gray">
            <div className="space-x-5 text-lg p-5 bg-white">
                <span>Service Type:</span>
                <span>
                    {
                        props.serviceTypeAttributeValues[0].serviceTypeAttribute
                            ?.serviceType?.serviceType
                    }
                </span>
            </div>
            <div className=" bg-white">
                {props.serviceTypeAttributeValues.map((attribute) => {
                    return (
                        <div
                            key={attribute.serviceTypeAttribute?.id}
                            className="flex flex-row p-3 space-x-5 border-b last:border-b-0 border-slate-300 mx-5"
                        >
                            <div className="basis-1/2">
                                {attribute.serviceTypeAttribute?.attributeName}:
                            </div>
                            <div className="basis-1/2">
                                {attribute.serviceTypeAttributeValue}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    ) : null;
}
