import { Combobox, Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useGetAllNichesQuery } from '../../api/generated';
import '../../styles/WebsiteOutreachPool.css';
import { WebsiteOutreachPoolInitialValues } from '../../types/WebsiteOutreachPoolInitialValues';
import { ResetFilterButton } from './ResetFilterButton';

export function Filter() {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    const { data } = useGetAllNichesQuery();
    if (formik.values.filterToggle) {
        return (
            <div>
                <div className="p-5 mt-5 grid sm:grid-cols-3 gap-4 transition ease-in-out bg-blue-50 rounded-2xl">
                    <IntegerMinMaxFilter
                        min={0}
                        max={100}
                        label="Site DA"
                        attribute="DA"
                        setFieldAttribute="siteDAFilter"
                    />
                    <IntegerMinMaxFilter
                        min={0}
                        max={100}
                        label="Site DR"
                        attribute="DR"
                        setFieldAttribute="siteDRFilter"
                    />
                    <IntegerMinMaxFilter
                        label="Site Traffic"
                        attribute="Site Traffic"
                        setFieldAttribute="siteTrafficFilter"
                    />
                    <IntegerMinMaxFilter
                        label="Price Asked"
                        attribute="PriceAsked"
                        setFieldAttribute="priceAskedFilter"
                    />
                    <IntegerMinMaxFilter
                        label="Special Price"
                        attribute="SpecialPrice"
                        setFieldAttribute="specialPriceFilter"
                    />
                    <IntegerMinMaxFilter
                        label="Final Price"
                        attribute="FinalPrice"
                        setFieldAttribute="finalPriceFilter"
                    />
                    <DateFromToFilter
                        label="Date Of Contact"
                        attribute="DateOfContact"
                        setFieldAttribute="dateOfContactFilter"
                    />

                    <div className="sm:col-span-3 lg:col-span-2 grid sm:grid-cols-2 lg:grid-cols-2 gap-4 text-center p-3 align-middle">
                        <MultiSelect
                            label="Status"
                            options={['Done', 'Negotiating']}
                            setFieldAttribute="statusFilters"
                            attributeValue={formik.values.statusFilters}
                        />
                        <ComboBoxFilter
                            label="Niche"
                            options={data?.getAllNiches as string[]}
                            setFieldAttribute="nicheFilter"
                            attributeValue={formik.values.nicheFilter}
                        />
                        <MultiSelect
                            label="Contact Type"
                            options={['ADMIN', 'THIRD-PARTY']}
                            setFieldAttribute="contactTypeFilter"
                            attributeValue={formik.values.contactTypeFilter}
                        />
                        <MultiSelect
                            label="Service Type"
                            options={['GUEST/POST', 'LINK-INSERTION']}
                            setFieldAttribute="serviceTypeFilter"
                            attributeValue={formik.values.serviceTypeFilter}
                        />
                    </div>
                </div>
                <div>
                    <ResetFilterButton />
                </div>
            </div>
        );
    }
    return <div />;
}

function MultiSelect(props: {
    label: string;
    options: string[];
    setFieldAttribute: string;
    attributeValue: string[];
}) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    return (
        <div className="w-full text-center bg-blue-100 rounded-lg">
            <Listbox
                value={props.attributeValue}
                onChange={(event) => {
                    if (event.length === 0) {
                        formik.setFieldValue(
                            props.setFieldAttribute,
                            props.options
                        );
                    } else {
                        formik.setFieldValue(props.setFieldAttribute, event);
                    }
                }}
                multiple
            >
                <Listbox.Button className="relative p-2 w-full rounded-lg inline-block text-sm space-x-2">
                    <span className="align-middle">{props.label}</span>
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400 inline-block"
                        aria-hidden="true"
                    />
                </Listbox.Button>
                <Listbox.Options className="bg-blue-50 mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {props.options.map((option) => (
                        <Listbox.Option
                            key={option}
                            value={option}
                            className="hover:bg-blue-100"
                        >
                            {props.attributeValue.includes(option) ? (
                                <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                            ) : (
                                <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                            )}
                            <span>{option}</span>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
}

function ComboBoxFilter(props: {
    label: string;
    options: string[];
    setFieldAttribute: string;
    attributeValue: string[];
}) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    const [query, setQuery] = useState('');

    const filteredOptions =
        query === ''
            ? props.options
            : props.options.filter((option) => {
                  return option.toLowerCase().includes(query.toLowerCase());
              });
    return (
        <div className="w-full text-center rounded-lg">
            <Combobox
                value={props.attributeValue}
                onChange={(event) => {
                    if (event.length === 0) {
                        formik.setFieldValue(
                            props.setFieldAttribute,
                            props.options
                        );
                    } else {
                        formik.setFieldValue(props.setFieldAttribute, event);
                    }
                }}
                multiple
            >
                <Combobox.Input
                    className="w-full rounded-lg p-1 bg-blue-100 h-10 text-center"
                    placeholder={
                        props.attributeValue.length === 0
                            ? 'Search for Niches'
                            : props.attributeValue
                                  .map((option) => option)
                                  .join(', ')
                    }
                    onChange={(event) => setQuery(event.target.value)}
                    value={props.attributeValue
                        .map((option) => option)
                        .join(', ')}
                />

                <Combobox.Options className="bg-blue-50 mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {filteredOptions.map((option) => (
                        <Combobox.Option
                            key={option}
                            value={option}
                            className="hover:bg-blue-100"
                        >
                            {props.attributeValue.includes(option) ? (
                                <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                            ) : (
                                <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                            )}
                            <span>{option}</span>
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </Combobox>
        </div>
    );
}

function IntegerMinMaxFilter(props: {
    label: string;
    attribute: string;
    setFieldAttribute: string;
    min?: number;
    max?: number;
}) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    return (
        <div className="filter-options rounded-md text-sm space-y-2 bg-blue-100">
            <span>{props.label}</span>
            <form className="grid grid-cols-1 gap-4">
                <div>
                    <label
                        htmlFor={'min'.concat(props.attribute)}
                        className="text-[13px]"
                    >
                        Min:
                    </label>
                    <input
                        min={props.min}
                        max={props.max}
                        id={'min'.concat(props.attribute)}
                        className="form-control
                                    text-center
                                    input-number-disable-arrows 
                                    text-sm
                                    text-gray-700
                                    border border-solid 
                                    border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    focus:outline-none font-medium
                                    p-1 ml-1"
                        type="number"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.gte'),
                                Number(event.target.value)
                            );
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor={'max'.concat(props.attribute)}
                        className="text-[13px]"
                    >
                        Max:
                    </label>
                    <input
                        min={props.min}
                        max={props.max}
                        id={'max'.concat(props.attribute)}
                        className="form-control
                        text-center
                        input-number-disable-arrows 
                        text-sm
                        text-gray-700
                        border border-solid 
                        border-gray-300
                        rounded
                        transition
                        ease-in-out
                        focus:outline-none font-medium
                        p-1 ml-1"
                        type="number"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.lte'),
                                Number(event.target.value)
                            );
                        }}
                    />
                </div>
            </form>
        </div>
    );
}

function DateFromToFilter(props: {
    label: string;
    attribute: string;
    setFieldAttribute: string;
}) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    return (
        <div className="sm:col-span-3 lg:col-span-1 filter-options text-sm space-y-4 px-1 align-middle bg-blue-100">
            <span>{props.label}</span>
            <form className="grid grid-cols-1 gap-4 content-center">
                <div className="space-x-3">
                    <label
                        className="text-[13px]"
                        htmlFor={'min'.concat(props.attribute)}
                    >
                        From:
                    </label>
                    <input
                        id={'min'.concat(props.attribute)}
                        className="form-control
                                    w-32
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    focus:outline-none"
                        type="date"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.gte'),
                                event.target.value
                            );
                        }}
                        // defaultValue={new Date().toISOString().substring(0, 10)}
                    />
                </div>
                <div className="space-x-6">
                    <label
                        htmlFor={'max'.concat(props.attribute)}
                        className="text-[13px]"
                    >
                        To:
                    </label>
                    <input
                        id={'max'.concat(props.attribute)}
                        className="form-control
                                    w-32
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:outline-none"
                        type="date"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.lte'),
                                event.target.value
                            );
                        }}
                        max={new Date().toISOString().split('T')[0]}
                        // defaultValue={new Date().toISOString().substring(0, 10)}
                    />
                </div>
            </form>
        </div>
    );
}
