import {
    ContentOrder,
    Employee,
    RangeFilterDateAsString,
    RangeFilterInteger,
    SearchFilter,
    useGetEmployeeUsingTokenQuery,
    usePaginatedContentOrderListQuery,
} from '../../api/generated';
import {
    TableHeaderColumnName,
    TableHeaderColumnSorting,
} from '../main/TableHeaderColumnSorting';

import CalendarDaysIcon from '@heroicons/react/20/solid/CalendarDaysIcon';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    sortBy: string;
    sortType: string;
    statusFilter: string[];
    filterToggle: boolean;
    search: SearchFilter;
    wordCountFilter: RangeFilterInteger;
    dueDateFilter: RangeFilterDateAsString;
    toggleView: boolean;
}

export function TableAndGrid() {
    const formik = useFormikContext<InitialValues>();

    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data } = usePaginatedContentOrderListQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                statusFilter: formik.values.statusFilter,
                search: {
                    ...formik.values.search,
                },
                wordCountFilter: {
                    ...formik.values.wordCountFilter,
                },
                dueDateFilter: {
                    ...formik.values.dueDateFilter,
                },
                forEmployeeID: loggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedContentOrderList?.totalPages
        );
    }, [data?.paginatedContentOrderList?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.paginatedContentOrderList?.totalRows
        );
    }, [data?.paginatedContentOrderList?.totalRows]);
    if (data?.paginatedContentOrderList?.totalPages === 0) {
        return (
            <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center md:col-span-2 lg:col-span-4 h-32 mt-5 self-center">
                No Data To be Displayed
            </div>
        );
    }
    if (!formik.values.toggleView) {
        return (
            <div className="client-order-table-grid overflow-x-auto overflow-y-auto text-center bg-transparent">
                <table className="table-auto w-full">
                    <thead className="table-header text-xs font-bold">
                        <tr className="space-x-2 overflow-x-auto">
                            <th className="edit-option">
                                <input
                                    className="align-middle"
                                    type="checkbox"
                                />
                            </th>
                            <TableHeaderColumnSorting
                                columnName="Order ID"
                                sortBy="id"
                            />
                            <TableHeaderColumnName columnName="Word Count" />
                            <TableHeaderColumnName columnName="Client Site URL" />
                            <TableHeaderColumnName columnName="Client Name" />
                            <TableHeaderColumnName columnName="Start Date" />
                            <TableHeaderColumnName columnName="Due Date" />
                            <TableHeaderColumnSorting
                                columnName="Status"
                                sortBy="orderStatus"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {data?.paginatedContentOrderList?.contentOrders?.map(
                            (row) =>
                                row && (
                                    <TableRow
                                        key={row.id?.toString()}
                                        id={row.id}
                                        wordCount={row.wordCount}
                                        client={row.client}
                                        orderStartDate={row?.orderStartDate.substring(
                                            0,
                                            10
                                        )}
                                        orderDueDate={row?.orderDueDate.substring(
                                            0,
                                            10
                                        )}
                                        orderStatus={row?.orderStatus}
                                        cmsAdmin={row.cmsAdmin as Employee}
                                        cmsEmployee={
                                            row.cmsEmployee as Employee
                                        }
                                        clientSiteURL={row.clientSiteURL}
                                    />
                                )
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    return (
        <div className="client-order-table-grid grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 lg:gap-4 overflow-y-auto scrollbar-hide text-center bg-transparent">
            {data?.paginatedContentOrderList?.contentOrders?.map(
                (row) =>
                    row && (
                        <GridCard
                            key={row.id?.toString()}
                            id={row.id}
                            client={row.client}
                            clientSiteURL={row.clientSiteURL}
                            orderDueDate={row.orderDueDate}
                            orderStartDate={row.orderStartDate}
                            orderStatus={row.orderStatus}
                            wordCount={row.wordCount}
                            PbnName={row.PbnName}
                            cmsAdmin={row.cmsAdmin}
                            cmsEmployee={row.cmsEmployee}
                            serviceType={row.serviceType}
                        />
                    )
            )}
        </div>
    );
}

function GridCard(props: ContentOrder) {
    const timeDue =
        new Date().getTime() - new Date(props.orderDueDate).getTime();
    const timeDueinDays = timeDue / (1000 * 3600 * 24);
    const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
    const trimmedClientSiteURL =
        props.clientSiteURL && props.clientSiteURL.length > 30
            ? props.clientSiteURL?.substring(0, 30).concat('...')
            : props.clientSiteURL;
    return (
        <Link
            to="/admin/content-order-list/view-content-order"
            state={{ orderID: props.id }}
        >
            <div className="grid-order-card p-3 rounded-xl shadow-lg border-2 border-slate-400 hover:bg-blue-100">
                <div className="border-b-2 h-12 border-slate-400 ">
                    <div className="text-xs font-bold text-left text-zedex-text-blue">
                        #{props.id}
                    </div>
                    <div className="text-md mb-2">
                        <span className="float-left font-bold">
                            {props.client?.name}
                        </span>
                        <span
                            className={`p-1 text-[10px] float-right ${
                                timeDue < 0 ? 'done-status' : 'overdue-status'
                            }`}
                        >
                            {timeDue > 0
                                ? `Due Past ${
                                      timeDueinWeeks < 0
                                          ? `${Math.abs(
                                                Math.floor(timeDueinWeeks)
                                            )} weeks and ${Math.abs(
                                                Math.ceil(
                                                    Math.floor(timeDueinDays) %
                                                        7
                                                )
                                            )} days`
                                          : Math.floor(timeDueinDays) > 0
                                          ? `${Math.abs(
                                                Math.floor(timeDueinDays)
                                            )} days`
                                          : ``
                                  }`
                                : timeDue < 0
                                ? `Due After ${
                                      timeDueinWeeks >= 0
                                          ? `${Math.abs(
                                                Math.ceil(timeDueinWeeks)
                                            )} weeks and ${Math.abs(
                                                Math.ceil(
                                                    Math.ceil(timeDueinDays) % 7
                                                )
                                            )} days`
                                          : Math.ceil(timeDueinDays) < 0
                                          ? `${Math.abs(
                                                Math.ceil(timeDueinDays)
                                            )} days`
                                          : ``
                                  }`
                                : 'Due Today'}
                        </span>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="text-xs grid grid-cols-2 gap-3">
                        <span className="text-left">
                            Word Count:&nbsp;
                            <span className="font-bold">{props.wordCount}</span>
                        </span>
                        <span className="text-left">
                            PBN Name:&nbsp;
                            <span className="font-bold">{props.PbnName}</span>
                        </span>
                    </div>
                    <div className="text-xs text-left mt-2">
                        <span className="">Client Site URL:</span>
                        <span className="">{trimmedClientSiteURL}</span>
                    </div>
                    <div className="text-xs grid grid-cols-2 mt-2">
                        <span className="text-left space-x-2">
                            <CalendarDaysIcon
                                color="#3bbf68"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">Start Date: </span>
                            <span className='align-middle block'>{props.orderStartDate.substring(
                                0,
                                10
                            )}</span>
                        </span>
                        <span className="text-right space-x-2">
                            <CalendarDaysIcon
                                color="red"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">Due Date: </span>
                            <span className='align-middle block'>{props.orderDueDate.substring(
                                0,
                                10
                            )}</span>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

function TableRow(props: ContentOrder) {
    const timeDue =
        new Date(props.orderDueDate).getTime() - new Date().getTime();
    const timeDueinDays = Math.ceil(timeDue / (1000 * 3600 * 24));
    const timeDueinHours = Math.ceil(timeDue / (1000 * 3600));

    return (
        <tr className="hover:bg-blue-50">
            <td className="py-5">
                <input type="checkbox" />
            </td>
            <td className="underline">
                <Link
                    to="/admin/content-order-list/view-content-order"
                    state={{ orderID: props.id }}
                >
                    <span>{`#${props.id}`}</span>
                </Link>
            </td>
            <td>{props.wordCount}</td>
            <td>{props.client?.name}</td>
            <td>{props.orderStartDate}</td>
            <td>{props.orderDueDate}</td>
            <td>{props.clientSiteURL}</td>
            {props.orderStatus === 'Overdue' && (
                <td className="font-bold">
                    <span className="block overdue-status p-2 m-1">
                        Overdue
                        <br />
                        <span className="text-[10px] text-red">
                            Due past{' '}
                            {timeDueinDays === 0
                                ? timeDueinHours.toString().concat(' Hours')
                                : timeDueinDays.toString().concat(' Days')}
                        </span>
                    </span>
                </td>
            )}
            {props.orderStatus === 'Assigned' && (
                <td className="font-bold">
                    <span className="block p-2 m-1 bg-slate-100">Assigned</span>
                </td>
            )}
            {props.orderStatus === 'Done' && (
                <td className="font-bold">
                    <span className="block done-status p-2 m-1">Done</span>
                </td>
            )}
            {props.orderStatus === 'In-Progress' && (
                <td className="font-bold">
                    <span className="block processing-status p-2 m-1">
                        In-Progress
                        <br />
                        <span className="text-[10px] text-orange">
                            Due in{' '}
                            {timeDueinDays === 0
                                ? timeDueinHours.toString().concat(' Hours')
                                : timeDueinDays.toString().concat(' Days')}
                        </span>
                    </span>
                </td>
            )}
            {props.orderStatus === 'In-Review' && (
                <td className="font-bold">
                    <span className=" block issue-raised-status p-2 m-1">
                        In-Review
                    </span>
                </td>
            )}
        </tr>
    );
}
