
import { ErrorMessage, Field, useFormikContext } from "formik";
import {
  ComponentProps,
  FC,
  HTMLAttributes,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useState
} from "react";

import IonIcon from "@reacticons/ionicons";
import classNames from "classnames";

export interface InputProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  name: Extract<keyof T, string>;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  placeholder?: string;
  autoFocus?: boolean;
  type?: HTMLInputTypeAttribute;
  Icon?: FC<ComponentProps<"svg">>;
  as?: "input" | "select" | "textarea";
  label?: string;
  isPass?: boolean;
  isRequired?: boolean;

}

const Input = <T,>({
  className,
  name,
  Icon,
  as = "input",
  label,
  containerProps,
  isPass = false,
  type,
  placeholder,
  isRequired,
  ...props
}: InputProps<T>) => {
  const { errors, touched } = useFormikContext<T>();
  const [isPassVisible, setIsPassVisible] = useState<boolean>(false);

  return name ? (
    <div
      className={classNames(
        "relative  w-full items-center",
        containerProps?.className,
      )}
    >
      {isRequired ? label && <div className="flex gap-1"> <label htmlFor={name} className="block text-sm font-semibold text-slate-500 mb-1">{label}</label> <span className="text-red ">*</span></div> :
        label && <label htmlFor={name} className="block text-sm font-semibold text-slate-500 mb-1">{label}</label>}
      <div
        className={classNames(
          "bg-light-bg-3  flex items-center gap-4 overflow-hidden rounded-sm border ",
          "focus-within:border-black border-background-4 focus-within:shadow focus-within:shadow-black20",
          props?.disabled
            ? "border-slate-400"
            : touched[name]
              ? errors[name]
                ? "border-red shadow "
                : "border-slate-600"
              : "border-slate-400",
        )}
      >
        {Icon && <Icon className="h-6 w-6" />}
        <Field
          className={classNames(
            "relative bg-light-bg-3 flex-1 p-1  outline-none placeholder:text-text-2 w-full",
            className,
            as === "textarea" && "h-32",
          )}
          as={as}
          name={name}
          rows={5}
          placeholder={placeholder}
          type={
            type === "password" ? (isPassVisible ? "text" : "password") : type
          }
          {...props}
        />
        {type === "password" && isPass && (
          <IonIcon
            name={isPassVisible ? "eye" : "eye-off"}
            className="absolute right-4 cursor-pointer"
            onClick={() => setIsPassVisible(!isPassVisible)}
          />
        )}
      </div>
      <ErrorMessage
        name={name}
        render={(message) => (
          <div className="absolute mt-1 w-0 min-w-full text-xs text-red">
            {message}
          </div>
        )}
      />
    </div>

  ) : null;
};

export default Input;
