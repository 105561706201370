import { Combobox } from '@headlessui/react';
import { CheckIcon, GlobeAsiaAustraliaIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import {
    DomainDetails,
    DomainNiche,
    SiteContactDetails,
    UpdateContactDetailsInput,
    useDomainQuery,
    useGetAllNichesQuery,
    useGetEmployeeUsingTokenQuery,
    useUpdateDomainDetailStatusMutation,
} from '../../api/generated';
import { AddDomainInitialValues } from '../../types/AddDomainInitialValues';

interface InitalValues {
    toggleEdit: boolean;
    siteDA: number;
    siteDR: number;
    siteTraffic: number;
    adminDomainDetails: DomainDetails;
    thirdPartyDomainDetails: DomainDetails;
    niches: string[];
    adminSiteContactDetails: UpdateContactDetailsInput;
    thirdPartySiteContactDetails: UpdateContactDetailsInput;
}

function ComboBoxInput(props: {
    label: string;
    setFieldAttribute: string;
    attributeValue: string[];
    disabled?: boolean;
}) {
    const formik = useFormikContext<AddDomainInitialValues>();
    const [query, setQuery] = useState('');
    const { data: options } = useGetAllNichesQuery();

    const filteredOptions =
        query === ''
            ? options?.getAllNiches
            : options?.getAllNiches?.filter((option) => {
                  return option?.toLowerCase().includes(query.toLowerCase());
              });
    return (
        <div className="w-full text-center rounded-lg">
            <Combobox
                disabled={props.disabled}
                value={props.attributeValue}
                onChange={(event) => {
                    formik.setFieldValue(props.setFieldAttribute, event);
                }}
                multiple
            >
                <Combobox.Input
                    className="w-full rounded-lg p-1 bg-blue-100 h-10 text-center"
                    placeholder={
                        props.attributeValue.length === 0
                            ? 'Search for Niches'
                            : props.attributeValue
                                  .map((option) => option)
                                  .join(', ')
                    }
                    onChange={(event) => setQuery(event.target.value)}
                    value={props.attributeValue
                        .map((option) => option)
                        .join(', ')}
                />

                <Combobox.Options className="bg-blue-50 mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {filteredOptions?.map(
                        (option) =>
                            option && (
                                <Combobox.Option
                                    key={option}
                                    value={option}
                                    className="hover:bg-blue-100"
                                >
                                    {props.attributeValue.includes(option) ? (
                                        <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                                    ) : (
                                        <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                                    )}
                                    <span>{option}</span>
                                </Combobox.Option>
                            )
                    )}
                </Combobox.Options>
            </Combobox>
        </div>
    );
}

function EditIcon(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            color="blue"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
        </svg>
    );
}

export function ViewDomainPage(props: { domainID: number }) {
    const { data: domain, refetch } = useDomainQuery({
        variables: {
            input: {
                id: props.domainID,
            },
        },
    });

    const formik = useFormikContext<InitalValues>();

    useEffect(() => {
        if (formik.values.siteDA === -1) {
            formik.setFieldValue('siteDA', domain?.domain?.siteDA);
        }
        if (formik.values.siteDR === -1) {
            formik.setFieldValue('siteDA', domain?.domain?.siteDR);
        }
        if (formik.values.siteTraffic === -1) {
            formik.setFieldValue('siteDA', domain?.domain?.siteTraffic);
        }
    }, [domain]);
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="px-2">
                <div className="mb-3 flex flex-row justify-end">
                    <button
                        className=""
                        type="button"
                        onClick={() =>
                            formik.setFieldValue(
                                'toggleEdit',
                                !formik.values.toggleEdit
                            )
                        }
                    >
                        <EditIcon />
                    </button>
                    {formik.values.toggleEdit ? (
                        <button
                            type="submit"
                            className="p-3 bg-zedex-button-blue hover:bg-zedex-button-blue-hover text-white rounded-md w-40 ml-10"
                        >
                            Save
                        </button>
                    ) : null}
                </div>
                <DomainHeader
                    siteUrl={domain?.domain?.siteUrl as string}
                    siteDA={domain?.domain?.siteDA as number}
                    siteDR={domain?.domain?.siteDR as number}
                    siteTraffic={domain?.domain?.siteTraffic as number}
                    serviceType={
                        domain?.domain?.domainDetails[0]?.serviceType
                            ?.serviceType as string
                    }
                    domainNiches={domain?.domain?.DomainNiches as DomainNiche[]}
                />
                <DomainDetailCard
                    refetch={refetch}
                    domainID={domain?.domain?.id as number}
                    domainDetails={
                        domain?.domain?.domainDetails as DomainDetails[]
                    }
                    siteContactDetails={
                        domain?.domain
                            ?.siteContactDetails as SiteContactDetails[]
                    }
                />
            </div>
        </form>
    );
}

export function DomainHeader(props: {
    siteUrl: string;
    siteDA: number;
    siteDR: number;
    siteTraffic: number;
    serviceType: string;
    domainNiches: DomainNiche[];
}) {
    return (
        <div className="grid lg:grid-cols-3 gap-3">
            <SiteUrl url={props.siteUrl} />
            <SiteDaDr
                siteDA={props.siteDA}
                siteDR={props.siteDR}
                siteTraffic={props.siteTraffic}
            />
            <ServiceType serviceType={props.serviceType} />
            <Niches niches={props.domainNiches as DomainNiche[]} />
        </div>
    );
}

function SiteUrl(props: { url: string }) {
    const formik = useFormikContext();
    return (
        <div className="rounded p-2 bg-blue-50 flex">
            <div className="self-center">
                <GlobeAsiaAustraliaIcon className="w-10 h-10 rounded inline-block fill-blue-500 align-middle p-1 bg-[#DBDEEE] mr-4" />
                <div className="inline-block align-middle ml-2 text-sm">
                    <div className="text-blue-500 text">Site URL</div>
                    <div className="text-blue-500 text-lg font-semibold">
                        {props.url}
                    </div>
                </div>
            </div>
        </div>
    );
}

function SiteDaDr(props: {
    siteDA: number;
    siteDR: number;
    siteTraffic: number;
}) {
    const formik = useFormikContext<InitalValues>();
    return (
        <div className=" bg-blue-50 rounded p-2 grid grid-cols-3 gap-3 text-center">
            <div className="inline-block align-middle ml-2 text-sm bg-blue-100 rounded-lg p-3">
                <div className="text-slate-500">Site DA</div>
                {formik.values.toggleEdit ? (
                    <div>
                        <input
                            defaultValue={props.siteDA}
                            min={0}
                            max={100}
                            className="text-center p-2 w-full rounded-md"
                            type="number"
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'siteDA',
                                    Number(event.target.value)
                                )
                            }
                        />
                    </div>
                ) : (
                    <div className="text-black text-center">{props.siteDA}</div>
                )}
            </div>
            <div className="inline-block align-middle ml-2 text-sm bg-blue-100 rounded-lg p-3">
                <div className="text-slate-500">Site DR</div>
                {formik.values.toggleEdit ? (
                    <div>
                        <input
                            className="text-center p-2 w-full rounded-md"
                            min={0}
                            max={100}
                            type="number"
                            defaultValue={props.siteDR}
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'siteDR',
                                    Number(event.target.value)
                                )
                            }
                        />
                    </div>
                ) : (
                    <div className="text-black text-center">{props.siteDR}</div>
                )}
            </div>
            <div className="inline-block align-middle ml-2 text-sm bg-blue-100 rounded-lg p-3">
                <div className="text-slate-500">Site Traffic</div>
                {formik.values.toggleEdit ? (
                    <div>
                        <input
                            className="text-center p-2 w-full rounded-md"
                            min={0}
                            type="number"
                            defaultValue={props.siteTraffic}
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'siteTraffic',
                                    Number(event.target.value)
                                )
                            }
                        />
                    </div>
                ) : (
                    <div className="text-black text-center">
                        {props.siteTraffic}
                    </div>
                )}
            </div>
        </div>
    );
}

function ServiceType(props: { serviceType: string }) {
    return props.serviceType ? (
        <div className=" bg-blue-50 rounded p-2 space-x-2 flex flex-row">
            <div className="align-middle p-1 px-2 flex self-center basis-1/3">
                Service Type:&nbsp;
            </div>
            <div className="space-x-5 py-3 bg-blue-100 rounded-lg px-10 text-center self-center">
                {props.serviceType}
            </div>
        </div>
    ) : null;
}

function Niches(props: { niches: DomainNiche[] }) {
    const formik = useFormikContext<InitalValues>();
    const niches: string[] = [];
    if (props.niches) {
        props.niches.forEach((niche) =>
            niches.push(niche.niche?.niche as string)
        );
    }
    useEffect(() => {
        if (formik.values.niches.length === 0) {
            formik.setFieldValue('niches', niches);
        }
    }, [props.niches]);
    return props.niches ? (
        <div className=" bg-blue-50 rounded p-2 space-x-2 flex flex-row lg:col-span-3">
            <div className="align-middle p-1 px-2 flex self-center">
                Niches:&nbsp;{' '}
            </div>
            <div className="inline-block space-x-5 px-4 py-3 bg-blue-100 rounded-lg w-[100%]">
                {formik.values.toggleEdit ? (
                    <ComboBoxInput
                        label=""
                        setFieldAttribute="niches"
                        attributeValue={formik.values.niches}
                    />
                ) : (
                    props.niches.map((niche) => (
                        <div
                            className="inline-block border-2 border-blue-600 p-1 rounded-xl px-2 bg-blue-200 align-middle my-4"
                            key={niche.nicheID}
                        >
                            {niche.niche?.niche}
                        </div>
                    ))
                )}
            </div>
        </div>
    ) : null;
}

function SiteContactDetailsSection(props: {
    contactType: string;
    contactTypeID: number;
    siteContactDetails: SiteContactDetails[];
}) {
    const formik = useFormikContext<InitalValues>();
    const siteContactDetails: string[] = [];

    props.siteContactDetails.forEach((contactDetail) =>
        siteContactDetails.push(`${contactDetail.contactDetails} ` as string)
    );
    return props.siteContactDetails ? (
        <div className="bg-blue-50 rounded space-x-2 flex flex-row">
            <div className="align-middle self-center inline-block">
                Site Contact Details:&nbsp;
            </div>
            {formik.values.toggleEdit ? (
                <div className="inline-block align-middle w-2/3">
                    <input
                        type="text"
                        className="border w-full p-2 rounded-md"
                        defaultValue={siteContactDetails.toString()}
                        onChange={(event) => {
                            if (props.contactType === 'ADMIN') {
                                formik.setFieldValue(
                                    'adminSiteContactDetails',
                                    {
                                        contactTypeID: props.contactTypeID,
                                        contactDetails: event.target.value,
                                    }
                                );
                            } else if (props.contactType === 'THIRD-PARTY') {
                                formik.setFieldValue(
                                    'thirdPartySiteContactDetails',
                                    {
                                        contactTypeID: props.contactTypeID,
                                        contactDetails: event.target.value,
                                    }
                                );
                            }
                        }}
                    />
                </div>
            ) : (
                <div className="inline-block px-4 bg-blue-50 rounded-lg align-middle">
                    {siteContactDetails.toString()}
                </div>
            )}
        </div>
    ) : null;
}

function DomainDetailCard(props: {
    domainID: number;
    domainDetails: DomainDetails[];
    siteContactDetails: SiteContactDetails[];
    refetch: any;
}) {
    const formik = useFormikContext<InitalValues>();
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();
    const domainDetails =
        props.domainDetails &&
        props.domainDetails.filter(
            (domainDetail) =>
                domainDetail?.contactedBy?.id ===
                loggedInUser?.getEmployeeUsingToken?.id
        );
    const [updateDomainDetailStatus] = useUpdateDomainDetailStatusMutation();
    return props.domainDetails ? (
        <div className="rounded mt-5 mx-5 space-y-5">
            {domainDetails.map((domainDetail) => {
                const siteContactDetails = props.siteContactDetails.filter(
                    (contactDetail) =>
                        contactDetail.contactType?.id ===
                        domainDetail?.contactType?.id
                );
                return (
                    <div
                        key={domainDetail?.contactType?.id}
                        className="border-2 border-blue-600 rounded p-2 w-full bg-blue-50"
                    >
                        <div className="text-xl text-left space-x-10 py-3">
                            <span className="">Contact Type:&nbsp;</span>
                            <span className="">
                                {domainDetail?.contactType?.contactType}
                            </span>
                            <select
                                className={`text-lg px-5 py-3 rounded-lg float-right ${
                                    domainDetail.status === 'Done'
                                        ? 'bg-green-300'
                                        : 'bg-rose-200'
                                }`}
                                onChange={async (event) => {
                                    const { data } =
                                        await updateDomainDetailStatus({
                                            variables: {
                                                input: {
                                                    contactTypeID:
                                                        domainDetail
                                                            ?.contactType?.id,
                                                    domainID: props.domainID,
                                                    status: event.target.value,
                                                },
                                            },
                                        });
                                    if (
                                        data?.updateDomainDetailStatus?.success
                                    ) {
                                        props.refetch();
                                    }
                                }}
                                value={domainDetail.status as string}
                            >
                                <option value="Done">Done</option>
                                <option value="Negotiating">Negotiating</option>
                            </select>
                        </div>
                        <div className="grid grid-cols-2 gap-10 mb-3">
                            <div className="border-r space-y-4">
                                <div className="flex flex-row">
                                    <span className="basis-2/3">
                                        Final Price: &nbsp;
                                    </span>
                                    {formik.values.toggleEdit ? (
                                        <span className="basis-1/3 mr-3">
                                            <input
                                                defaultValue={
                                                    domainDetail?.finalPrice as number
                                                }
                                                type="number"
                                                className="p-1 rounded-md w-full"
                                                onChange={(event) => {
                                                    if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'ADMIN'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'adminDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .adminDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                finalPrice:
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ),
                                                            }
                                                        );
                                                    } else if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'THIRD-PARTY'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'thirdPartyDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .thirdPartyDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                finalPrice:
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ),
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </span>
                                    ) : (
                                        <span>{`$${domainDetail.finalPrice}`}</span>
                                    )}
                                </div>
                                <div className="flex flex-row">
                                    <span className="basis-2/3">
                                        Price Asked: &nbsp;
                                    </span>
                                    {formik.values.toggleEdit ? (
                                        <span className="basis-1/3 mr-3">
                                            <input
                                                defaultValue={
                                                    domainDetail?.priceAsked as number
                                                }
                                                type="number"
                                                className="p-1 rounded-md w-full"
                                                onChange={(event) => {
                                                    if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'ADMIN'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'adminDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .adminDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                priceAsked:
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ),
                                                            }
                                                        );
                                                    } else if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'THIRD-PARTY'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'thirdPartyDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .thirdPartyDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                priceAsked:
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ),
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </span>
                                    ) : (
                                        <span>{`$${domainDetail.priceAsked}`}</span>
                                    )}
                                </div>
                                <div className="flex flex-row">
                                    <span className="basis-2/3">
                                        Special Price: &nbsp;
                                    </span>
                                    {formik.values.toggleEdit ? (
                                        <span className="basis-1/3 mr-3">
                                            <input
                                                defaultValue={
                                                    domainDetail.specialPrice as number
                                                }
                                                type="number"
                                                className="p-1 rounded-md w-full"
                                                onChange={(event) => {
                                                    if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'ADMIN'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'adminDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .adminDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                specialPrice:
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ),
                                                            }
                                                        );
                                                    } else if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'THIRD-PARTY'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'thirdPartyDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .thirdPartyDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                specialPrice:
                                                                    Number(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    ),
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </span>
                                    ) : (
                                        <span>{`$${domainDetail.specialPrice}`}</span>
                                    )}
                                </div>
                                {domainDetail.linkInsertionPrice ? (
                                    <div className="flex flex-row">
                                        <span className="basis-2/3">
                                            Link Insertion Price: &nbsp;
                                        </span>
                                        {formik.values.toggleEdit ? (
                                            <span className="basis-1/3 mr-3">
                                                <input
                                                    defaultValue={
                                                        domainDetail.linkInsertionPrice
                                                    }
                                                    type="number"
                                                    className="p-1 rounded-md w-full"
                                                    onChange={(event) => {
                                                        if (
                                                            domainDetail
                                                                ?.contactType
                                                                ?.contactType ===
                                                            'ADMIN'
                                                        ) {
                                                            formik.setFieldValue(
                                                                'adminDomainDetails',
                                                                {
                                                                    ...formik
                                                                        .values
                                                                        .adminDomainDetails,
                                                                    contactTypeID:
                                                                        domainDetail
                                                                            .contactType
                                                                            .id,
                                                                    linkInsertionPrice:
                                                                        Number(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        ),
                                                                }
                                                            );
                                                        } else if (
                                                            domainDetail
                                                                ?.contactType
                                                                ?.contactType ===
                                                            'THIRD-PARTY'
                                                        ) {
                                                            formik.setFieldValue(
                                                                'thirdPartyDomainDetails',
                                                                {
                                                                    ...formik
                                                                        .values
                                                                        .thirdPartyDomainDetails,
                                                                    contactTypeID:
                                                                        domainDetail
                                                                            .contactType
                                                                            .id,
                                                                    linkInsertionPrice:
                                                                        Number(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        ),
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                            </span>
                                        ) : (
                                            <span>
                                                {`$${domainDetail.linkInsertionPrice}`}
                                            </span>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            <div className=" space-y-4">
                                <div className="flex flex-row space-x-2">
                                    <span className="basis-1/2">
                                        Date Of Contact: &nbsp;
                                    </span>
                                    <span className=" basis-1/2">
                                        {domainDetail.dateOfContact.substring(
                                            0,
                                            10
                                        )}
                                    </span>
                                </div>
                                <div className="flex flex-row space-x-2">
                                    <span className="basis-1/2">
                                        POC: &nbsp;
                                    </span>
                                    {formik.values.toggleEdit ? (
                                        <div className="inline-block align-middle basis-1/2">
                                            <input
                                                type="email"
                                                className="border w-fulls p-1 rounded-md"
                                                defaultValue={
                                                    domainDetail?.POC as string
                                                }
                                                onChange={(event) => {
                                                    if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'ADMIN'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'adminDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .adminDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                POC: event
                                                                    .target
                                                                    .value,
                                                            }
                                                        );
                                                    } else if (
                                                        domainDetail
                                                            ?.contactType
                                                            ?.contactType ===
                                                        'THIRD-PARTY'
                                                    ) {
                                                        formik.setFieldValue(
                                                            'thirdPartyDomainDetails',
                                                            {
                                                                ...formik.values
                                                                    .thirdPartyDomainDetails,
                                                                contactTypeID:
                                                                    domainDetail
                                                                        .contactType
                                                                        .id,
                                                                POC: event
                                                                    .target
                                                                    .value,
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <span className="">
                                            {domainDetail.POC}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <SiteContactDetailsSection
                            siteContactDetails={siteContactDetails}
                            contactType={
                                domainDetail.contactType.contactType as string
                            }
                            contactTypeID={
                                domainDetail.contactType.id as number
                            }
                        />
                    </div>
                );
            })}
        </div>
    ) : null;
}
