import { useFormikContext } from 'formik';

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
}

export function PaginationFooter() {
    const formik = useFormikContext<InitialValues>();

    return (
        <div className="pagination-footer text-sm mb-5 grid md:grid-cols-1 lg:grid-cols-2 md:space-y-4">
            <div className="space-x-2 inline-block pt-4 sm:text-center lg:text-left">
                <SelectPaginationRowCount />
                <div className="align-middle inline-block font-medium">
                    Showing results&nbsp;
                    {1 +
                        formik.values.pageNum *
                        formik.values.paginationRowCount}
                    -
                    {formik.values.paginationRowCount *
                        (formik.values.pageNum + 1) >
                        formik.values.totalRows
                        ? formik.values.totalRows
                        : formik.values.paginationRowCount *
                        (formik.values.pageNum + 1)}
                    &nbsp;from {formik.values.totalRows}
                </div>
            </div>
            <PaginationNavigation />
        </div>
    );
}

function PaginationNavigation() {
    const formik = useFormikContext<InitialValues>();
    return (
        <div className="inline-block space-x-2 lg:text-right sm:text-center">
            <select
                name='pageNum'
                className="inline-block paginationSelectList form-select w-10 h-6 border rounded transition ease-in-out focus:outline-none text-center bg-blue-100 border-blue-600"
                onChange={formik.handleChange}
                value={formik.values.pageNum}
            >
                <option value={formik.values.pageNum}>
                    {Number(formik.values.pageNum) + 1}
                </option>
                {Array(formik.values.totalPages)
                    .fill(0)
                    .map((x, i) => {
                        return (
                            <option
                                key={i.toString()}
                                value={i + 1}
                                onClick={() => {
                                    formik.setFieldValue('pageNum', i);
                                }}
                            >
                                {i + 1}
                            </option>
                        );
                    })}
            </select>
            <span>
                of {formik.values.totalPages}&nbsp;
                {formik.values.totalPages <= 1 ? 'page' : 'pages'}
            </span>
            <button
                className="inline-block align-middle"
                type="button"
                onClick={() => {
                    formik.setFieldValue(
                        'pageNum',
                        formik.values.pageNum > 0
                            ? Number(formik.values.pageNum) - 1
                            : 0
                    );
                }}
            >
                <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <button
                className="inline-block align-middle"
                type="button"
                onClick={() => {
                    formik.setFieldValue(
                        'pageNum',
                        formik.values.pageNum < formik.values.totalPages - 1
                            ? Number(formik.values.pageNum) + 1
                            : formik.values.pageNum
                    );
                }}
            >
                <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
        </div>
    );
}

function SelectPaginationRowCount() {
    const formik = useFormikContext<InitialValues>();
    return (
        <div className="inline-block text-sm align-middle">
            <div className="ml-3 inline-block">
                <div>
                    <select
                        className="paginationSelectList text-center bg-transparent border-solid form-select block w-10 h-6  font-normal text-gray-700 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 bg-blue-100 border-blue-600 focus:outline-none align-middle"
                        name="paginationRowCount"
                        onChange={formik.handleChange}
                        value={formik.values.paginationRowCount}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={35}>35</option>
                    </select>
                </div>
            </div>
            <p className="inline-block ml-2">Items per page</p>
        </div>
    );
}
