import * as Yup from 'yup';
import { DADRMAX, DADRMIN } from '../components/main/Schema';

export const createOrderSchema = Yup.object().shape({
    id: Yup.string(),
    clientName: Yup.string().required('Required Field'),
    orderStartDate: Yup.string(),
    orderDueDate: Yup.string(),
    requiredDA: Yup.string().min(0, DADRMIN).max(100, DADRMAX),
    clientSiteUrlList: Yup.array().min(1, 'Enter Minimum one Client Site URLs'),
    clientSiteUrl: new Yup.ObjectSchema({
        clientSiteURL: Yup.string().url('Enter Valid URL'),
        keyword: Yup.string(),
        requiredDA: Yup.number(),
    }),
    selectedSites: Yup.array().of(Yup.string()),
    instructionSeoEmployee: Yup.string(),
    instructionCmsEmployee: Yup.string(),
    instructionCmsAdmin: Yup.string(),
});
