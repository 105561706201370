import {
    useGetEmployeeUsingTokenQuery,
    useGetNotificationByIdQuery,
} from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/notification.css';

interface TableRowProps {
    name: string | undefined;
    lname: string | undefined;
    description: string | undefined;
    date: string | undefined;
}

function TableRow(props: TableRowProps) {
    return (
        <tr>
            <td className="px-2">
                <img
                    className="rounded-full shadow-lg w-10 h-10 inline-block "
                    src="/img/nav/profilePic.jpg"
                    alt="Profile_Picture"
                />
            </td>
            <tr className="text-xl font-bold">
                <td className="nameTitle px-2 ">
                    <span>
                        {props.name} {props.lname}
                    </span>{' '}
                    <span className="pl-4">{props.date?.substring(0, 10)}</span>
                </td>
            </tr>
            <tr className="text-xs ">
                <td className="px-2 pb-2 text-trev">{props.description}</td>
            </tr>
        </tr>
    );
}

export default function Notif() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data } = useGetNotificationByIdQuery({
        variables: {
            input: {
                toEmployeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });
    return (
        <div className="notifd max-w-sm rounded-lg ">
            <div className="px-6 py-4">
                <div className="headerTitle font-bold text-xl mb-2">
                    Notifications
                </div>
            </div>
            <tbody>
                {data?.getNotificationById?.slice(0, 12).map((row) => {
                    return (
                        <TableRow
                            key={row?.id}
                            name={
                                row?.fromEmployeeNotification
                                    ?.firstName as string
                            }
                            lname={
                                row?.fromEmployeeNotification
                                    ?.lastName as string
                            }
                            description={row?.description as string}
                            date={row?.date as string}
                        />
                    );
                })}
            </tbody>
        </div>
    );
}
