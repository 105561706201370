import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAddTodoMutation,
    useGetEmployeeUsingTokenQuery,
    useGetTodosQuery,
} from '../../api/generated';
import '../../styles/notification.css';

import Modal from '../../shared/UI/Modal';
import '../../styles/ProfileBar.css';
import '../../styles/styles.css';
import CardWrapper from './CardWrapper';
import TodoListItem from './TodoListItem';

// function formatDate(date: any) {
//     const d = new Date(date);
//     let month = `${d.getMonth() + 1}`;
//     let day = `${d.getDate()}`;
//     const year = d.getFullYear();

//     if (month.length < 2) month = `0${month}`;
//     if (day.length < 2) day = `0${day}`;

//     return [year, month, day].join('-');
// }

export default function ToDoList() {
    const [cardShow, setCardShow] = useState<boolean>(false);

    useEffect(() => {
        setCardShow(true);
    }, []);

    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data, refetch } = useGetTodosQuery({
        variables: {
            input: {
                employeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });

    const navigate = useNavigate();
    const [addTodo] = useAddTodoMutation();

    const formik = useFormik({
        initialValues: {
            task: '',
            complete: false,
            employeeID: LoggedInUser?.getEmployeeUsingToken?.id,
        },
        onSubmit: async () => {
            addTodo({
                variables: {
                    input: {
                        task: formik.values.task,
                        complete: formik.values.complete,
                        employeeID: formik.values.employeeID,
                    },
                },
            });
            navigate('/');
        },
    });

    const [open, setOpen] = React.useState(false);

    // const date = new Date();
    // const current_date = formatDate(date);
    return (
        <div>
            <Modal
                open={open}
                onOpenChange={setOpen}
                title="Add Tasks on your To-Do"
            >
                <div className="h-56 bg-slate-400 w-full">
                    <p>Content</p>
                </div>
                {/* <div className="px-6 py-4">
                    <div className=" font-bold text-xl mb-2 grid grid-cols-2 ">
                        <div className="pl-24">
                            {open ? (
                                <div className="absolute">
                                    {open ? (
                                        <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                                <div className="border-2 border-trev m-10 p-5 bg-white rounded-xl space-y-4 w-[30rem]">
                                                    <div className="space-x-8">
                                                        <div className="pl-40 inline-block text-lg font-bold tracking-wide align-middle">
                                                            Add Tasks
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="inline-block pl-24 text-lg"
                                                            onClick={() =>
                                                                setOpen(false)
                                                            }
                                                        >
                                                            x
                                                        </button>
                                                    </div>
                                                    <div className="text-slate-500 tracking-wide text-justify text-sm pl-24">
                                                        Add Tasks on your To-Do
                                                        List
                                                    </div>
                                                    <form
                                                        onSubmit={
                                                            formik.handleSubmit
                                                        }
                                                    >
                                                        <InputComponent
                                                            id="task"
                                                            inputType="text"
                                                            name="task"
                                                            inputPlaceholder="Enter Your Task"
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            value={
                                                                formik.values
                                                                    .task
                                                            }
                                                            touched={
                                                                formik.touched
                                                                    .task
                                                            }
                                                            error={
                                                                formik.errors
                                                                    .task
                                                            }
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="text-white w-[50%] p-2 mx-[23%] bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600 rounded-lg"
                                                        >
                                                            Submit
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div> */}
            </Modal>
            <CardWrapper
                title="To Do List"
                cta={
                    <button
                        onClick={() => setOpen(true)}
                        type="button"
                        className="bg-zedex-button-blue rounded-lg flex justify-center items-center px-2 py-2 text-white  align-middle   hover:bg-zedex-button-blue-hover"
                    >
                        <span className="text-xs  ">Add Task</span>
                    </button>
                }
            >
                <div>
                    <ul>
                        {data?.getTodosById?.slice(0, 5).map((todo) => {
                            return (
                                <TodoListItem
                                    key={todo?.id}
                                    todo={todo}
                                    refetch={refetch}
                                />
                            );
                        })}
                    </ul>
                </div>
            </CardWrapper>
        </div>
    );
}
