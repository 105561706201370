import * as Yup from 'yup';
import { DADRMAX, DADRMIN } from '../components/main/Schema';

export const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)?$/


export const addDomainSchema = Yup.object().shape({
    siteUrl: Yup.string().required("required").matches(domainRegex, "please Enter valid Url"),
    contactType: Yup.number().required("required"),
    serviceType: Yup.number().required("required"),
    siteDA: Yup.number().required("required").max(100, DADRMAX).min(0, DADRMIN),
    siteDR: Yup.number().required("required").max(100, DADRMAX).min(0, DADRMIN),
    siteTraffic: Yup.number().required("required").integer("Floating values are not allowed").min(0, DADRMIN),
    priceAsked: Yup.number().required("required").min(0, DADRMIN),
    finalPrice: Yup.number().required("required").min(0, DADRMIN),
    specialPrice: Yup.number().required("required").min(0, DADRMIN),
    linkInsertionPrice: Yup.number().required("required").min(0, DADRMIN),
    nicheID: Yup.array()
        .of(Yup.string())
        .test(
            'length-test',
            'Atleast One Niche Should be selected',
            (value, options) => {
                return (value?.length || 0) > 0;
            }
        ),
    POC: Yup.string().required("required"),
    siteContactDetails: Yup.string().required("required"),
    dateOfContact: Yup.string().required("required"),
    comments: Yup.string(),
    status: Yup.string().required("required").oneOf(['Done', 'Negotiating']),
    // contactedByID: Yup.string().required("required"),
    // linkInsertionPrice:Yup.number().required("required")
});
