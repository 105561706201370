import { useFormikContext } from 'formik';
import { CardViewButton } from '../main/CardViewButton';
import { ListViewButton } from '../main/ListViewButton';
import { TransferDomainListInitialValues } from './TransferDomainListInitialValues';

export function PredefinedFilters(props: { className?: string }) {
    const formik = useFormikContext<TransferDomainListInitialValues>();
    return (
        <div className={`space-x-3 ${props.className}`}>
            <button
                type="button"
                className={`border-b w-44 p-2 ${
                    formik.values.predefinedFilters === 'Incoming'
                        ? 'border-blue-600 text-blue-600'
                        : ''
                }`}
                onClick={() => {
                    formik.setFieldValue('predefinedFilters', 'Incoming');
                }}
            >
                Incoming Requests
            </button>
            <button
                type="button"
                className={`border-b w-44 p-2 ${
                    formik.values.predefinedFilters === 'Outgoing'
                        ? 'border-blue-600 text-blue-600'
                        : ''
                }`}
                onClick={() => {
                    formik.setFieldValue('predefinedFilters', 'Outgoing');
                }}
            >
                Outgoing Requests
            </button>
            <button
                type="button"
                className={`border-b p-2 w-20 ${
                    formik.values.predefinedFilters === 'All'
                        ? 'border-blue-600 text-blue-600'
                        : ''
                }`}
                onClick={() => {
                    formik.setFieldValue('predefinedFilters', 'All');
                }}
            >
                All
            </button>
        </div>
    );
}

export function ToggleView(props: { className?: string }) {
    const formik = useFormikContext<TransferDomainListInitialValues>();
    return (
        <div className={`gap-4 text-sm flex justify-end ${props.className}`}>
            <div className="">
                <CardViewButton
                    active={formik.values.toggleView}
                    setFieldAttribute="toggleView"
                    setFieldValue
                />
            </div>
            <div className="">
                <ListViewButton
                    active={!formik.values.toggleView}
                    setFieldAttribute="toggleView"
                    setFieldValue={false}
                />
            </div>
        </div>
    );
}
