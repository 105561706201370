import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    useGetEmployeeUsingTokenQuery,
    useUpdatePasswordMutation,
} from '../../api/generated';
import ButtonComp from '../../components/main/Button';
import InputComponent from '../../components/main/Input';
import {
    PASSWORD_CHAR,
    PASSWORD_LOWER,
    PASSWORD_MATCH,
    PASSWORD_NUM,
    PASSWORD_SYMBOL,
    PASSWORD_UPPER,
    REQUIREDS,
} from '../../components/main/Schema';
import '../../styles/style.css';

const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, PASSWORD_CHAR)
        .matches(/[0-9]/, PASSWORD_NUM)
        .matches(/[a-z]/, PASSWORD_LOWER)
        .matches(/[A-Z]/, PASSWORD_UPPER)
        .matches(/[^\w]/, PASSWORD_SYMBOL)
        .required(REQUIREDS),
    confirmPassword: Yup.string().when('password', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string()
            .oneOf([Yup.ref('password')], PASSWORD_MATCH)
            .required(REQUIREDS),
    }),
});

export default function ResetPasswordPage() {
    const [updatePassword] = useUpdatePasswordMutation();
    const navigate = useNavigate();
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const tok = LoggedInUser?.getEmployeeUsingToken?.id as number;
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: ResetPasswordSchema,
        onSubmit: async () => {
            const { data } = await updatePassword({
                variables: {
                    input: {
                        id: tok,
                        password: formik.values.password,
                    },
                },
            });

            if (data?.updatePassword?.success === true) {
                notify('Success');
                navigate('/');
            } else {
                notify('Error, Try Again');
            }
        },
    });
    const notify = (statusMsg: string) => toast(statusMsg);

    return (
        <div
            className="Background bg-cover bg-center"
            style={{
                backgroundImage: "url('/img/background.png')",
            }}
        >
            <div className="  flex justify-center items-center py-8 px-2 sm:px-4 lg:px-6 ">
                <div className=" cardReset flex flex-col md:flex-row md:max-w-xl rounded-lg bg-gray shadow-2xl">
                    <div className="p-6 flex flex-col justify-start">
                        <form onSubmit={formik.handleSubmit}>
                            <img
                                className="mb-3 logo  "
                                src="/img/zedex.png"
                                alt=""
                            />
                            <h1 className="text-2xl font-bold text-mmad text-center mb-3">
                                Reset Password
                            </h1>
                            <div className="form-group mb-6 ">
                                <InputComponent
                                    id="password"
                                    name="password"
                                    inputType="password"
                                    inputPlaceholder="Password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    touched={formik.touched.password}
                                    error={formik.errors.password}
                                />
                            </div>
                            <div className="form-group mb-6">
                                <InputComponent
                                    inputType="password"
                                    inputPlaceholder="Confirm Password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    touched={formik.touched.confirmPassword}
                                    error={formik.errors.confirmPassword}
                                />
                            </div>
                            <ButtonComp buttonHeading="Reset Password" />
                            <ToastContainer />
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
