import {
    CmsOrders,
    Employee,
    Role,
    useGetCmsOrderByIdQuery,
    useGetEmployeeWithRoleQuery,
} from '../../api/generated';
import Comments from '../ViewOrder/Comments';
import ClientOrderHeader from './ClientOrderHeader';

import CmsInstructionAccordion from './InstructionAccordion';
import TablesOrders from './Table';

export default function CmsAdminViewOrder(props: { orderID: string }) {
    const { data } = useGetCmsOrderByIdQuery({
        variables: {
            input: {
                cmsOrderID: props.orderID,
            },
        },
    });

    const { data: employeesAssignable } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Cmsemployee],
            },
        },
    });

    const order = data && data.getCmsOrderByID;
    return (
        <>
            <ClientOrderHeader
                id={order?.id?.toString() || ''}
                clientName={
                    data?.getCmsOrderByID?.seoOrder?.order?.client?.name || ''
                }
                // seoOrderManagerID={
                //     data?.getOrderByID?.seoOrderManager?.id as number
                // }
                // seoOrderManagerName={`${data?.getOrderByID?.seoOrderManager?.firstName} ${data?.getOrderByID?.seoOrderManager?.lastName}`}
                startDate={order?.seoOrder?.order?.orderStartDate || ''}
                dueDate={order?.seoOrder?.order?.orderDueDate || ''}
                articleCount={order?.seoOrder?.order?.ArticleCounts || 0}
                requiredDA={order?.seoOrder?.order?.requiredDA || 0}
                requiredDR={order?.seoOrder?.order?.requiredDR || 0}
                clientSiteURL={
                    (order?.seoOrder?.ClientSiteUrl &&
                        order.seoOrder?.ClientSiteUrl[0]?.clientSiteURL) ||
                    ''
                }
                employeeAssignable={
                    employeesAssignable?.getEmployeeWithRole as Employee[]
                }
            />
            <CmsInstructionAccordion
                // seoInstruction={
                //     (order?.SeoOrders &&
                //         order?.SeoOrders[0]?.instructionsSeoEmployee) ||
                //     ''
                // }
                cmsInstructionEmp={order?.instructionCmsEmp || ''}
                cmsInstruction={order?.instructionCmsAdmin || ''}
            />
            <TablesOrders
                order={order as CmsOrders}
                employeesAssignable={
                    employeesAssignable?.getEmployeeWithRole as Employee[]
                }
            />
            <Comments orderID={order?.id as string} />
        </>
    );
}
