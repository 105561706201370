import { Link, NavLink } from 'react-router-dom';
import { Role, useGetEmployeeUsingTokenQuery } from '../../../api/generated';
import { navElemts } from '../../../constants/data';
import Icon from '../../../shared/Icons';
import { Roles, tabNames } from '../../../shared/Icons/types';
import '../../../styles/Navbar.css';

type NavBarProps = { activeElement: tabNames };

function NavBar({ activeElement }: NavBarProps) {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    const role =
        LoggedInUser?.getEmployeeUsingToken &&
        LoggedInUser?.getEmployeeUsingToken?.role;
    const OrderUrl =
        role !== Role.Cmsadmin
            ? role !== Role.Cmsemployee
                ? '/admin/order-list'
                : '/cms/employee/order-list'
            : '/admin/cms/order-list';

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    return (
        <div className="fixed z-40 w-16 bg-white h-screen flex flex-col gap-8 ">
            <div className="navbar-logo h-fit">
                <Link to="/">
                    <img
                        className="object-scale-down h-16 w-96"
                        src="/img/nav/zedexinfo-logo.svg"
                        alt="Logo"
                    />
                </Link>
            </div>

            <nav className="p-2 flex flex-col h-full justify-between ">
                <div className="flex flex-col gap-2">
                    {navElemts
                        .filter(
                            (nav) =>
                                nav.authorise.includes(
                                    LoggedInUser?.getEmployeeUsingToken
                                        ?.role as Roles
                                ) || nav.authorise.includes('ALL')
                        )
                        .map((nav) => (
                            <div
                                key={nav.name}
                                className="group relative cursor-pointer "
                            >
                                <div className="absolute hidden shadow-lg rounded-lg bg-slate-200 p-2 ml-16 align-baseline text-gray-700 group-hover:inline-block">
                                    <p className="text-sm">{nav.tooltip}</p>
                                </div>
                                <NavLink
                                    to={
                                        nav.name === 'order' ? OrderUrl : nav.to
                                    }
                                >
                                    <div
                                        className={`w-full transition-all ease-in-out duration-150  h-full flex justify-center items-center rounded-lg group-hover:bg-zedex-button-blue  aspect-square ${
                                            activeElement === nav.name
                                                ? 'navbar-active'
                                                : ''
                                        }`}
                                    >
                                        <Icon
                                            icon={nav.icon}
                                            size={23}
                                            className={` group-hover:fill-white  transition-all ease-in-out duration-150 rounded-lg w-full border-solid border-2 border-transparent  flex  justify-center 
                                            ${
                                                activeElement === nav.name
                                                    ? 'fill-white'
                                                    : 'opacity-50 fill-black'
                                            }`}
                                        />
                                    </div>
                                </NavLink>
                            </div>
                        ))}
                </div>
                <div>
                    <div className="group relative cursor-pointer">
                        <div className="realtive hidden rounded-lg bg-slate-200 p-2 ml-16 text-gray-700 group-hover:inline-block absolute">
                            <p className="text-sm">Logout</p>
                        </div>

                        <div
                            className={` 
                            group-hover:bg-red rounded-lg  transition-all ease-in-out duration-150
                            h-full aspect-square flex justify-center items-center
                                `}
                        >
                            <Icon
                                icon="logout"
                                className='fill-black transition-all ease-in-out duration-150 opacity-50 group-hover:opacity-100 group-hover:fill-white'
                                size={23}
                                onClick={handleLogout}
                            />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
