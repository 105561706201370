import '../../styles/welcome.css';

import NavBar from '../../components/main/Navbar/Navbar';
import ProfileBar from '../../components/main/ProfileBar';
import ProfilePage from '../../components/profilePage/ProfilePage';

export default function Profile() {
    return (
        <>
            <NavBar activeElement="dashboard" />
            <ProfileBar title="Profile" />
            <div className="backg">
                <ProfilePage />
            </div>
        </>
    );
}
