import CmsDashboard from '../../../components/cmsDashboard/Dashboard';
import CmsNavbar from '../../../components/main/Navbar/Navbar';
import ProfileBar from '../../../components/main/ProfileBar';
import '../../../styles/welcome.css';

export default function CmsHome() {
    return (
        <>
            <CmsNavbar activeElement="dashboard" />
            <ProfileBar title="CMS Dashboard" />
            <div className="backg">
                <CmsDashboard />
            </div>
        </>
    );
}
