import {
    RangeFilterDateAsString,
    RangeFilterInteger,
    SearchFilter,
} from '../../api/generated';

import { useFormikContext } from 'formik';
import { CardViewButton } from '../main/CardViewButton';
import { ListViewButton } from '../main/ListViewButton';

interface InitialValues {
    statusFilter: string[];
    filterToggle: boolean;
    search: SearchFilter;
    siteDaReqFilter: RangeFilterInteger;
    siteDrReqFilter: RangeFilterInteger;
    articleCountsFilter: RangeFilterInteger;
    dueDateFilter: RangeFilterDateAsString;
    toggleView: boolean;
}

export function PredefinedFilters() {
    const formik = useFormikContext<InitialValues>();
    return (
        <div className="predef-row grid sm:grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="grid grid-flow-col auto-cols-max gap-4 text-sm font-bold tracking-wide text-center">
                <button
                    type="button"
                    className="predef-option predef-overdue"
                    onChange={formik.handleChange}
                    onClick={() =>
                        formik.setFieldValue('statusFilter', ['Overdue'])
                    }
                >
                    Overdue
                </button>
                <button
                    type="button"
                    className="predef-option predef-process"
                    onChange={formik.handleChange}
                    onClick={() =>
                        formik.setFieldValue('statusFilter', ['Processing'])
                    }
                >
                    Processing
                </button>
                <button
                    type="button"
                    className="predef-option predef-done"
                    onChange={formik.handleChange}
                    onClick={() =>
                        formik.setFieldValue('statusFilter', ['Done'])
                    }
                >
                    Done
                </button>
                <button
                    type="button"
                    className="predef-option predef-cancel"
                    onChange={formik.handleChange}
                    onClick={() =>
                        formik.setFieldValue('statusFilter', ['Cancelled'])
                    }
                >
                    Cancelled
                </button>
                <button
                    type="button"
                    className="predef-option predef-issue"
                    onChange={formik.handleChange}
                    onClick={() =>
                        formik.setFieldValue('statusFilter', ['Issue-Raised'])
                    }
                >
                    Issue Raised
                </button>
                <button
                    type="button"
                    className="predef-option predef-all"
                    onChange={formik.handleChange}
                    onClick={() =>
                        formik.setFieldValue('statusFilter', [
                            'Done',
                            'Overdue',
                            'Processing',
                            'Cancelled',
                            'Issue-Raised',
                        ])
                    }
                >
                    All
                </button>
            </div>
            <div className="gap-4 text-sm flex justify-end">
                <div className="">
                    <CardViewButton
                        active={formik.values.toggleView}
                        setFieldAttribute="toggleView"
                        setFieldValue
                    />
                </div>
                <div className="">
                    <ListViewButton
                        active={!formik.values.toggleView}
                        setFieldAttribute="toggleView"
                        setFieldValue={false}
                    />
                </div>
            </div>
        </div>
    );
}
