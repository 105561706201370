import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconsList } from '../../../shared/Icons';

type CardWrapperProps = {
    title: string;
    icon?: IconsList;
    href?: string;
    cta?: React.ReactNode;
};

const CardWrapper: React.FC<
    HTMLAttributes<HTMLDivElement> & CardWrapperProps
> = ({ href, icon, title, cta, children }) => {
    const [cardShow, setCardShow] = useState<boolean>(false);

    useEffect(() => {
        setCardShow(true);
    }, []);

    return (
        <div
            className={`rounded-lg bg-white shadow-md hover:shadow-lg transition-all ease-in-out duration-300 overflow-hidden ${
                cardShow ? 'scale-100 translate-x-0' : 'scale-0 -translate-x-96'
            }`}
        >
            <div className="px-6 py-4">
                <div className=" font-bold text-xl mb-2 flex flex-row justify-between ">
                    <div className="flex flex-row gap-3 justify-start items-center text-black80">
                        {icon && (
                            <Icon
                                icon={icon}
                                size={30}
                                className="fill-black80 font-extrabold"
                            />
                        )}
                        <p className="text-xl"> {title}</p>
                    </div>
                    {href && (
                        <div className="w-fit bg-zedex-button-blue flex justify-center items-center py-1 px-1.5 rounded-md">
                            <Link to={href as string}>
                                <Icon icon="arrow" className="fill-white" />
                            </Link>
                        </div>
                    )}
                    {cta && cta}
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default CardWrapper;
