import NavBar from '../../../components/main/Navbar/Navbar';
import ProfileBar from '../../../components/main/ProfileBar';
import { tabNames } from '../types';

type RootLayoutProps = {
    activeElement: tabNames;
    ProfileBarTitle: string;
    children: React.ReactNode;
};

function RootLayout({
    children,
    ProfileBarTitle,
    activeElement,
}: RootLayoutProps) {
    return (
        <main className="relative bg-zedex-bg-gray w-full h-full min-h-screen">
            <NavBar activeElement={activeElement} />

            <ProfileBar title={ProfileBarTitle} />

            <div className="ml-16 px-5 py-4">{children}</div>
        </main>
    );
}

export default RootLayout;
