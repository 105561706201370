export const PASSWORD_CHAR = 'Password must be 8 characters long';
export const PASSWORD_NUM = 'Password requires a number';
export const PASSWORD_LOWER = 'Password requires a lowercase letter';
export const PASSWORD_UPPER = 'Password requires an uppercase letter';
export const PASSWORD_SYMBOL = 'Password requires a symbol';
export const PASSWORD_MATCH = 'Both password need to be the same';
export const REQUIREDS = 'Required';
export const EMAILS = 'Invalid Email';
export const PHONE_NUMBER = 'Phone number is not valid';
export const STRING_SHORT = 'Too Short!';
export const STRING_LONG = 'Too Long!';
export const ALPHA_ONLY = 'Only alphabets are allowed for this fields';
export const PHONES = 'Invalid Phone Number';
export const PHONE1 =
    'Phone number length must be min 10 and maximum 13 characters';
export const DADRMAX = 'Maximum Value should be 100';
export const DADRMIN = 'Minimum Value should be 0';
export const NUMBER = 'Input should be a number';
export const STRING = 'Input should be a string';
export const LENGTH_MSG = (size:any,field="Input") => {
    return `${field} must be greater than ${size}`;
}
