import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useDomainQuery, useUpdateDomainMutation } from '../../api/generated';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import { ViewDomainPage } from '../../components/viewDomain/ViewDomainPage';

export function ViewDomain() {
    const location = useLocation();
    const { domainID } = location.state;

    const [updateDomain] = useUpdateDomainMutation();
    const { data: domain, refetch } = useDomainQuery({
        variables: {
            input: {
                id: domainID,
            },
        },
    });

    return (
        <>
            <NavBar activeElement="outreach" />
            <ProfileBar title="Outreach-Sheet" />
            <div className="bg-[#f4f5fa] pt-20">
                <PathBar appendPath={`#${domainID.toString()}`} />
                <div className="ml-[4.3em] mr-1 bg-white py-[1em] px-[0.2em]">
                    <Formik
                        initialValues={{
                            toggleEdit: false,
                            siteDA: -1,
                            siteDR: -1,
                            siteTraffic: -1,
                            adminDomainDetails: { contactTypeID: 1 },
                            thirdPartyDomainDetails: { contactTypeID: 2 },
                            niches: [],
                            adminSiteContactDetails: { contactTypeID: 1 },
                            thirdPartySiteContactDetails: { contactTypeID: 2 },
                        }}
                        onSubmit={async (values, formik) => {
                            const { data } = await updateDomain({
                                variables: {
                                    input: {
                                        domainID,
                                        domainDetails: [
                                            values.adminDomainDetails,
                                            values.thirdPartyDomainDetails,
                                        ],
                                        niches: values.niches,
                                        siteDA:
                                            values.siteDA === -1
                                                ? domain?.domain?.siteDA
                                                : values.siteDA,
                                        siteDR:
                                            values.siteDR === -1
                                                ? domain?.domain?.siteDR
                                                : values.siteDR,
                                        siteTraffic:
                                            values.siteTraffic === -1
                                                ? domain?.domain?.siteTraffic
                                                : values.siteTraffic,
                                        siteContactDetails: [
                                            values.adminSiteContactDetails,
                                            values.thirdPartySiteContactDetails,
                                        ],
                                    },
                                },
                            });
                            if (data?.updateDomain?.success) {
                                formik.setFieldValue('toggleEdit', false);
                                refetch();
                            }
                        }}
                    >
                        {() => <ViewDomainPage domainID={domainID} />}
                    </Formik>
                </div>
            </div>
        </>
    );
}
