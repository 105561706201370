import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { ClientOrderInitialValues } from '../../types/ClientOrderInitialValues';

export function Filter() {
    const formik = useFormikContext<ClientOrderInitialValues>();
    if (formik.values.filterToggle) {
        return (
            <div className="bg-gray">
                <div className="p-5 mt-5 grid sm:grid-cols-3 lg:grid-cols-4 gap-4 transition ease-in-out">
                    <IntegerMinMaxFilter
                        label="ArticleCounts"
                        attribute="articleCounts"
                        setFieldAttribute="articleCountsFilter"
                    />
                    <DateFromToFilter
                        label="Due Date"
                        attribute="dueDate"
                        setFieldAttribute="dueDateFilter"
                    />
                    <div className="sm:col-span-3 lg:col-span-1 text-center p-3 align-middle">
                        <MultiSelect
                            label="Status"
                            options={[
                                'Done',
                                'Overdue',
                                'Processing',
                                'Cancelled',
                                'Issue-Raised',
                            ]}
                            setFieldAttribute="statusFilter"
                            attributeValue={formik.values.statusFilter}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return <div />;
}

function IntegerMinMaxFilter(props: {
    label: string;
    attribute: string;
    setFieldAttribute: string;
}) {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <div className="filter-options text-sm space-y-2 px-3 h-26">
            <span>{props.label}</span>
            <form className="grid grid-cols-2 gap-4">
                <div className="space-x-1">
                    <label htmlFor={'min'.concat(props.attribute)}>Min:</label>
                    <input
                        id={'min'.concat(props.attribute)}
                        className="form-control
                                    w-12
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    focus:outline-none"
                        type="number"
                        onChange={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.gte'),
                                Number(event.target.value)
                            );
                        }}
                    />
                </div>
                <div className="space-x-1">
                    <label
                        htmlFor={'max'.concat(props.attribute)}
                        className="space-x-2"
                    >
                        Max:
                    </label>
                    <input
                        id={'max'.concat(props.attribute)}
                        className="form-control
                                    w-12
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:outline-none"
                        type="number"
                        onChange={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.lte'),
                                Number(event.target.value)
                            );
                        }}
                    />
                </div>
            </form>
        </div>
    );
}

function MultiSelect(props: {
    label: string;
    options: string[];
    setFieldAttribute: string;
    attributeValue: string[];
}) {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <div className="w-full text-center">
            <Listbox
                value={props.attributeValue}
                onChange={(event) => {
                    if (event.length === 0) {
                        formik.setFieldValue(
                            props.setFieldAttribute,
                            props.options
                        );
                    } else {
                        formik.setFieldValue(props.setFieldAttribute, event);
                    }
                }}
                multiple
            >
                <Listbox.Button className="relative border border-black p-2 w-full rounded-lg inline-block text-sm space-x-2">
                    <span className="align-middle">{props.label}</span>
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400 inline-block"
                        aria-hidden="true"
                    />
                </Listbox.Button>
                <Listbox.Options className="bg-white mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {props.options.map((option) => (
                        <Listbox.Option
                            key={option}
                            value={option}
                            className="hover:bg-blue-100"
                        >
                            {props.attributeValue.includes(option) ? (
                                <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                            ) : (
                                <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                            )}
                            <span>{option}</span>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
}

function DateFromToFilter(props: {
    label: string;
    attribute: string;
    setFieldAttribute: string;
}) {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <div className="sm:col-span-3 lg:col-span-2 filter-options text-sm space-y-2 px-3 h-26 clign-middle">
            <span>{props.label}</span>
            <form className="grid grid-cols-2 gap-4">
                <div className="space-x-1">
                    <label
                        className="pr-2"
                        htmlFor={'min'.concat(props.attribute)}
                    >
                        From:
                    </label>
                    <input
                        id={'min'.concat(props.attribute)}
                        placeholder="From"
                        defaultValue={new Date().toDateString()}
                        className="form-control
                                    w-30
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    focus:outline-none"
                        type="date"
                        onChange={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.gte'),
                                event.target.value
                            );
                        }}
                    />
                </div>
                <div className="space-x-1">
                    <label
                        htmlFor={'max'.concat(props.attribute)}
                        className="pr-2"
                    >
                        To:
                    </label>
                    <input
                        id={'max'.concat(props.attribute)}
                        className="form-control
                                    w-32
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:outline-none"
                        type="date"
                        onChange={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.lte'),
                                event.target.value
                            );
                        }}
                    />
                </div>
            </form>
        </div>
    );
}
