import { GlobeAsiaAustraliaIcon } from '@heroicons/react/20/solid';
import {
    DomainNiche,
    useAcceptDomainTransferRequestMutation,
    useGetEmployeeUsingTokenQuery,
    useGetTransferRequestQuery,
    useRejectDomainTransferRequestMutation,
} from '../../api/generated';

export function ViewTransferDomainRequest(props: { requestID: number }) {
    const { data: request, refetch: refetchRequests } =
        useGetTransferRequestQuery({
            variables: {
                input: {
                    requestID: props.requestID,
                },
            },
        });

    const [acceptDomainTransferRequest] =
        useAcceptDomainTransferRequestMutation();
    const [rejectDomainTransferRequest] =
        useRejectDomainTransferRequestMutation();

    const domainDetail =
        request?.getTransferRequest?.domain?.domainDetails &&
        request?.getTransferRequest?.domain?.domainDetails.filter(
            (detail) =>
                detail?.contactType.id ===
                request.getTransferRequest?.contactType?.id
        );

    const contactDetails =
        request?.getTransferRequest?.domain?.siteContactDetails.filter(
            (detail) =>
                detail?.contactType?.id ===
                request.getTransferRequest?.contactType?.id
        );

    const details: string[] = [];

    contactDetails?.forEach((detail) =>
        details.push(detail?.contactDetails as string)
    );

    const domain = request?.getTransferRequest?.domain;
    const requestData = request?.getTransferRequest;
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    return (
        <div className="grid lg:grid-cols-3 gap-3">
            <SiteUrl
                url={request?.getTransferRequest?.domain?.siteUrl as string}
            />
            <ServiceType
                serviceType={
                    (domainDetail &&
                        domainDetail[0]?.serviceType?.serviceType) ||
                    ''
                }
            />
            <ContactType
                contactType={
                    (domainDetail &&
                        domainDetail[0]?.contactType.contactType) ||
                    ''
                }
            />
            <Niches
                niches={
                    request?.getTransferRequest?.domain
                        ?.DomainNiches as DomainNiche[]
                }
            />
            <ContactDetails details={details} />

            <div className="lg:col-span-3">
                {requestData?.status !== 'Processing' ? (
                    <div
                        className={`rounded-lg p-4 text-center ${
                            requestData?.status === 'Accepted'
                                ? 'bg-green-200'
                                : 'bg-rose-400'
                        }`}
                    >
                        {requestData?.status}
                    </div>
                ) : requestData?.employeeRequestBy?.id !==
                  LoggedInUser?.getEmployeeUsingToken?.id ? (
                    <div className="grid grid-cols-2 gap-3">
                        <button
                            type="button"
                            className="bg-green-200 rounded-md p-4 hover:bg-green-300"
                            onClick={async () => {
                                const { data } =
                                    await acceptDomainTransferRequest({
                                        variables: {
                                            input: {
                                                id: requestData?.id,
                                            },
                                        },
                                    });

                                if (
                                    data?.acceptDomainTransferRequest?.success
                                ) {
                                    refetchRequests();
                                }
                            }}
                        >
                            Accept
                        </button>
                        <button
                            type="button"
                            className="bg-rose-200 rounded-md hover:bg-rose-300"
                            onClick={async () => {
                                const { data } =
                                    await rejectDomainTransferRequest({
                                        variables: {
                                            input: {
                                                id: requestData?.id,
                                            },
                                        },
                                    });

                                if (
                                    data?.rejectDomainTransferRequest?.success
                                ) {
                                    refetchRequests();
                                }
                            }}
                        >
                            Reject
                        </button>
                    </div>
                ) : (
                    <div className="rounded-lg p-4 text-center bg-orange-200">
                        {requestData?.status}
                    </div>
                )}
            </div>
            <div className="lg:col-span-3 lg:flex lg:flex-row sm:space-y-5 lg:space-y-0 px-3 lg:space-x-10">
                <Data
                    label="Original Data"
                    className="lg:basis-1/2 p-3 rounded-lg bg-blue-50"
                    siteDA={domain?.siteDA as number}
                    siteDR={domain?.siteDR as number}
                    siteTraffic={domain?.siteTraffic as number}
                    priceAsked={
                        (domainDetail && domainDetail[0]?.priceAsked) as number
                    }
                    finalPrice={
                        (domainDetail && domainDetail[0]?.finalPrice) as number
                    }
                    specialPrice={
                        (domainDetail &&
                            domainDetail[0]?.specialPrice) as number
                    }
                    linkInsertionPrice={
                        (domainDetail &&
                            domainDetail[0]?.linkInsertionPrice) as number
                    }
                    POC={(domainDetail && domainDetail[0]?.POC) as string}
                    contactedBy={
                        (domainDetail &&
                            domainDetail[0]?.contactedBy.firstName?.concat(
                                ` ${domainDetail[0].contactedBy.lastName}`
                            )) as string
                    }
                />
                <Data
                    className="lg:basis-1/2 p-3 rounded-lg bg-blue-50"
                    label="Requested Data"
                    siteDA={requestData?.siteDA as number}
                    siteDR={requestData?.siteDR as number}
                    siteTraffic={requestData?.siteTraffic as number}
                    priceAsked={requestData?.priceAsked as number}
                    finalPrice={requestData?.finalPrice as number}
                    specialPrice={requestData?.specialPrice as number}
                    linkInsertionPrice={
                        requestData?.linkInsertionPrice as number
                    }
                    POC={requestData?.POC as string}
                    contactedBy={
                        requestData?.employeeRequestBy?.firstName?.concat(
                            ` ${requestData.employeeRequestBy.lastName}`
                        ) as string
                    }
                />
            </div>
        </div>
    );
}

function SiteUrl(props: { url: string }) {
    return (
        <div className="rounded p-2 bg-blue-50 flex">
            <div className="self-center">
                <GlobeAsiaAustraliaIcon className="w-10 h-10 rounded inline-block fill-blue-500 align-middle p-1 bg-[#DBDEEE] mr-4" />
                <div className="inline-block align-middle ml-2 text-sm">
                    <div className="text-blue-500 text">Site URL</div>
                    <div className="text-blue-500 text-lg font-semibold">
                        {props.url}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ServiceType(props: { serviceType: string }) {
    return props.serviceType ? (
        <div className=" bg-blue-50 rounded p-2 space-x-2 flex flex-requestData?">
            <div className="align-middle p-1 px-2 flex self-center basis-1/3">
                Service Type:&nbsp;
            </div>
            <div className="space-x-5 py-3 bg-blue-100 rounded-lg px-10 text-center self-center">
                {props.serviceType}
            </div>
        </div>
    ) : null;
}

function ContactType(props: { contactType: string }) {
    return props.contactType ? (
        <div className=" bg-blue-50 rounded p-2 space-x-2 flex flex-requestData?">
            <div className="align-middle p-1 px-2 flex self-center basis-1/3">
                Contact Type:&nbsp;
            </div>
            <div className="space-x-5 py-3 bg-blue-100 rounded-lg px-10 text-center self-center">
                {props.contactType}
            </div>
        </div>
    ) : null;
}

function Niches(props: { niches: DomainNiche[] }) {
    const niches: string[] = [];
    if (props.niches) {
        props.niches.forEach((niche) =>
            niches.push(niche.niche?.niche as string)
        );
    }
    return props.niches ? (
        <div className=" bg-blue-50 rounded p-2 space-x-2 flex flex-requestData? lg:col-span-3">
            <div className="align-middle p-1 px-2 flex self-center">
                Niches:&nbsp;{' '}
            </div>
            <div className="inline-block space-x-5 px-4 py-3 bg-blue-100 rounded-lg w-[100%]">
                {props.niches.map((niche) => (
                    <div
                        className="inline-block border-2 border-blue-600 p-1 rounded-xl px-2 bg-blue-200 align-middle my-4"
                        key={niche.nicheID}
                    >
                        {niche.niche?.niche}
                    </div>
                ))}
            </div>
        </div>
    ) : null;
}

function ContactDetails(props: { details: string[] }) {
    return props.details ? (
        <div className=" bg-blue-50 rounded p-2 space-x-2 flex flex-requestData? lg:col-span-3">
            <div className="align-middle p-1 px-2 flex basis-1/4 self-center">
                Site Contact Details:&nbsp;{' '}
            </div>
            <div className="inline-block space-x-5 px-4 py-3 bg-blue-100 rounded-lg w-[100%]">
                {props.details.toString()}
            </div>
        </div>
    ) : null;
}

function Data(props: {
    label: string;
    siteDA: number;
    siteDR: number;
    siteTraffic: number;
    priceAsked: number;
    finalPrice: number;
    specialPrice: number;
    linkInsertionPrice?: number;
    POC: string;
    contactedBy: string;
    className?: string;
}) {
    return (
        <div className={`${props.className} space-y-5`}>
            <div className="text-lg text-center">{props.label}</div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Site DA:</span>
                <span className="basis-3/4">{props.siteDA}</span>
            </div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Site DR:</span>
                <span className="basis-3/4">{props.siteDR}</span>
            </div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Site Traffic:</span>
                <span className="basis-3/4">{props.siteTraffic}</span>
            </div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Price Asked:</span>
                <span className="basis-3/4">{props.priceAsked}</span>
            </div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Final Price:</span>
                <span className="basis-3/4">{props.finalPrice}</span>
            </div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Special Price:</span>
                <span className="basis-3/4">{props.specialPrice}</span>
            </div>
            {props.linkInsertionPrice && (
                <div className="flex flex:requestData?">
                    <span className="basis-1/4">LinkInsertion Price:</span>
                    <span className="basis-3/4">
                        {props.linkInsertionPrice}
                    </span>
                </div>
            )}
            <div className="flex flex:requestData?">
                <span className="basis-1/4">POC:</span>
                <span className="basis-3/4">{props.POC}</span>
            </div>
            <div className="flex flex:requestData?">
                <span className="basis-1/4">Contacted By:</span>
                <span className="basis-3/4">{props.contactedBy}</span>
            </div>
        </div>
    );
}
