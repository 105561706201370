import { useFormik, useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import DataGrid, { SortColumn } from 'react-data-grid';
import {
    ArticleLinksUpdateInput,
    CmsOrders,
    Employee,
    SearchFilter,
    SubTaskReAssignInputCms,
    usePaginatedSubTaskListQuery,
    useViewOrderUpdateCmsMutation,
} from '../../api/generated';
import { PaginationFooter } from '../main/PaginationFooter';
import { SearchBar } from '../main/SearchBar';

interface orderDetailsTableRows {
    id: string;
    clientSiteURL: string;
    keyword: string;
    liveLinks: string;
    articleLinks: ReactElement;
    requiredDaDr: string;
    EmployeeAssigned: ReactElement;
    dateOfAssignment: string;
}

interface assignEmployee {
    cmsOrderID: string;
    cmsEmployeeID: number;
}

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    sortBy: string;
    sortType: string;
    edited: boolean;
    search: SearchFilter;
    subTaskAssignment: [assignEmployee];
}

export default function TablesOrder(props: {
    order: CmsOrders;
    employeesAssignable: Employee[];
}) {
    const formikContext = useFormikContext<InitialValues>();
    const [updateOrder] = useViewOrderUpdateCmsMutation();

    const formik = useFormik({
        initialValues: {
            subTaskAssignment: [],
            liveLinks: [],
            articleLinks: [],
            clientSiteURL: [],
            keyword: [],
            edited: false,
        },
        onSubmit: () => {
            updateOrder({
                variables: {
                    input: {
                        subTask: formik.values.subTaskAssignment,
                        liveLinks: formik.values.liveLinks,
                        articleLinks: formik.values.articleLinks,
                        clientSiteUrl: formik.values.clientSiteURL,
                        keyword: formik.values.keyword,
                    },
                },
            });
            formik.setFieldValue('subTaskAssignment', []);
            formik.setFieldValue('articleLinks', []);
            formik.setFieldValue('liveLinks', []);
            formik.setFieldValue('clientSiteURL', []);
            formik.setFieldValue('keyword', []);
            // subTaskRefetch();
        },
    });

    const columns = [
        { key: 'id', name: 'Sr.No' },
        {
            key: 'clientSiteURL',
            name: 'Client Site URL',
            sortable: true,
        },
        {
            key: 'keyword',
            name: 'Client Keyword',
            sortable: true,
        },
        // {
        //     key: 'selectedSite',
        //     name: 'Selected Site',
        //     sortable: true,
        //     editor: textEditor,
        // },
        {
            key: 'liveLinks',
            name: 'Live Links',
        },
        {
            key: 'articleLinks',
            name: 'Article Links',
        },
        // {
        //     key: 'requiredDaDr',
        //     name: 'DA/DR Required',
        //     sortable: true,
        // },
        // {
        //     key: '',
        //     name: 'Actual DA',
        //     sortable: true,
        // },
        // {
        //     key: 'price',
        //     name: 'Price',
        //     sortable: true,
        // },
        {
            key: 'EmployeeAssigned',
            name: 'Assigned To Employee ',
            sortable: true,
            // editor: textEditor,
        },
        {
            key: 'dateOfAssignment',
            name: 'Date of Assignment',
            sortable: true,
            // editor: textEditor,
        },
    ];

    const { data: subTasks } = usePaginatedSubTaskListQuery({
        variables: {
            input: {
                take: Number(formikContext.values.paginationRowCount),
                skip:
                    Number(formikContext.values.pageNum) *
                    Number(formikContext.values.paginationRowCount),
                orderID: props.order && props.order.seoOrder?.order?.id,
                search: formikContext.values.search,
            },
        },
    });
    // const dataset = subTasks?.paginatedSubTaskList?.seoOrderList;

    useEffect(() => {
        formikContext.setFieldValue('pageNum', 0);
    }, [formikContext.values.paginationRowCount]);

    useEffect(() => {
        formikContext.setFieldValue(
            'totalPages',
            subTasks?.paginatedSubTaskList?.totalPages
        );
    }, [subTasks?.paginatedSubTaskList?.totalPages]);

    useEffect(() => {
        formikContext.setFieldValue(
            'totalRows',
            subTasks?.paginatedSubTaskList?.totalRows
        );
    }, [subTasks?.paginatedSubTaskList?.totalRows]);

    const rows: orderDetailsTableRows[] = [];
    subTasks?.paginatedSubTaskList?.seoOrderList.map((seoOrder) => {
        const cmsOrder = seoOrder?.CmsOrders && seoOrder?.CmsOrders[0];
        return rows.push({
            id: cmsOrder?.id as string,
            clientSiteURL: ` ${
                seoOrder?.ClientSiteUrl &&
                seoOrder?.ClientSiteUrl[0]?.clientSiteURL
            } ${''} `,

            // (
            //     <input
            //         type="text"
            //         className="w-full text-center bg-transparent focus:no-border"
            //         defaultValue={
            //             (seoOrder?.ClientSiteUrl &&
            //                 seoOrder?.ClientSiteUrl[0]?.clientSiteURL) ||
            //             ''
            //         }
            //         onChange={(event) => {
            //             formik.setFieldValue('edited', true);
            //             formik.setFieldValue(`clientSiteURL`, [
            //                 {
            //                     id:
            //                         (seoOrder?.ClientSiteUrl &&
            //                             seoOrder?.ClientSiteUrl[0]?.id) ||
            //                         0,
            //                     clientSiteUrl: event.target.value,
            //                 },
            //                 ...formik.values.liveLinks.filter(
            //                     (siteURL: ClientSiteUrl) =>
            //                         siteURL.id !==
            //                         (seoOrder?.ClientSiteUrl &&
            //                             seoOrder?.ClientSiteUrl[0]?.id)
            //                 ),
            //             ]);
            //         }}
            //     />
            // ),
            keyword: ` ${
                seoOrder?.ClientSiteUrl && seoOrder?.ClientSiteUrl[0]?.keyword
            } ${''} `,

            // (
            //     <input
            //         type="text"
            //         className="w-full text-center bg-transparent focus:no-border"
            //         defaultValue={
            //             (seoOrder?.ClientSiteUrl &&
            //                 seoOrder?.ClientSiteUrl[0]?.keyword) ||
            //             ''
            //         }
            //         onChange={(event) => {
            //             formik.setFieldValue('edited', true);
            //             formik.setFieldValue(`keyword`, [
            //                 {
            //                     id:
            //                         (seoOrder?.ClientSiteUrl &&
            //                             seoOrder?.ClientSiteUrl[0]?.id) ||
            //                         0,
            //                     keyword: event.target.value,
            //                 },
            //                 ...formik.values.keyword.filter(
            //                     (siteURL: ClientSiteUrl) =>
            //                         siteURL.id !==
            //                         (seoOrder?.ClientSiteUrl &&
            //                             seoOrder?.ClientSiteUrl[0]?.id)
            //                 ),
            //             ]);
            //         }}
            //     />
            // ),
            liveLinks: ` ${
                seoOrder?.LiveLinks && seoOrder?.LiveLinks[0]?.liveLinks
            } ${''} `,
            // (
            //     <input
            //         type="text"
            //         className="w-full text-center bg-transparent focus:no-border"
            //         defaultValue={
            //             (seoOrder?.LiveLinks &&
            //                 seoOrder?.LiveLinks[0]?.liveLinks) ||
            //             ''
            //         }
            //         onChange={(event) => {
            //             formik.setFieldValue('edited', true);
            //             formik.setFieldValue(`liveLinks`, [
            //                 {
            //                     id:
            //                         (seoOrder?.LiveLinks &&
            //                             seoOrder?.LiveLinks[0]?.id) ||
            //                         0,
            //                     liveLink: event.target.value,
            //                 },
            //                 ...formik.values.liveLinks.filter(
            //                     (liveLink: LiveLinksUpdateInput) =>
            //                         liveLink.id !==
            //                         (seoOrder?.LiveLinks &&
            //                             seoOrder?.LiveLinks[0]?.id)
            //                 ),
            //             ]);
            //         }}
            //     />
            // ),
            articleLinks: (
                <input
                    className="w-full text-center bg-transparent"
                    type="text"
                    defaultValue={
                        (cmsOrder?.Articles &&
                            cmsOrder?.Articles[0]?.articleLink) ||
                        ''
                    }
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue(`articleLinks`, [
                            {
                                id:
                                    (seoOrder?.CmsOrders &&
                                        seoOrder?.CmsOrders[0]?.Articles &&
                                        seoOrder?.CmsOrders[0]?.Articles[0]
                                            ?.id) ||
                                    0,
                                articleLink: event.target.value,
                            },
                            ...formik.values.articleLinks.filter(
                                (articleLink: ArticleLinksUpdateInput) =>
                                    articleLink.id !==
                                    (seoOrder?.CmsOrders &&
                                        seoOrder?.CmsOrders[0]?.Articles &&
                                        seoOrder?.CmsOrders[0]?.Articles[0]?.id)
                            ),
                        ]);
                    }}
                />
            ),
            requiredDaDr: `${
                seoOrder?.ClientSiteUrl &&
                seoOrder?.ClientSiteUrl[0]?.requiredDA
            } / ${
                seoOrder?.ClientSiteUrl && seoOrder.ClientSiteUrl[0]?.requiredDR
            }`,
            EmployeeAssigned: (
                <select
                    className="w-full text-center bg-transparent p-2 m-0"
                    onChange={(event) => {
                        formik.setFieldValue('edited', true);
                        formik.setFieldValue('subTaskAssignment', [
                            {
                                id: cmsOrder?.id,
                                cmsEmpID: Number(event.target.value),
                            },
                            ...formik.values.subTaskAssignment.filter(
                                (subTask: SubTaskReAssignInputCms) =>
                                    subTask.id !== cmsOrder?.id
                            ),
                        ]);
                    }}
                >
                    <option
                        value={
                            (seoOrder?.CmsOrders &&
                                seoOrder?.CmsOrders[0]?.cmsEmp &&
                                seoOrder?.CmsOrders[0]?.cmsEmp.id) ||
                            0
                        }
                    >
                        {seoOrder?.CmsOrders &&
                            seoOrder?.CmsOrders[0]?.cmsEmp?.firstName?.concat(
                                ` ${seoOrder?.CmsOrders[0]?.cmsEmp?.lastName}`
                            )}
                    </option>
                    {props?.employeesAssignable?.map(
                        (employee) =>
                            employee && (
                                <option
                                    key={employee.id}
                                    value={employee.id?.toString()}
                                >
                                    {employee.firstName?.concat(
                                        ` ${employee?.lastName}` as string
                                    )}
                                </option>
                            )
                    )}
                </select>
            ),
            dateOfAssignment: seoOrder?.dateOfAssignment?.substring(0, 10),
        });
    });

    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
    const [selectedRows, setSelectedRows] = useState<Set<number>>(
        () => new Set()
    );
    return (
        <div className="text-center bg-transparent">
            <div className="text-left p-5 space-x-6 static">
                <div className=" text-centerp-5 inline-block align-middle text-zedex-button-blue lg:mr-48">
                    Tasks:
                    {props.order && props.order.seoOrder?.order?.ArticleCounts}
                </div>
                <SearchBar placeholder="Search for SubTask" />
            </div>
            {formik.values.edited ? (
                <div className="w-[30rem] grid grid-cols-2 mb-4 ml-5 p-3 float-left">
                    <button
                        type="button"
                        onClick={() => {
                            formik.setFieldValue('edited', false);
                            formik.handleSubmit();
                        }}
                        className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
                    >
                        <div className="space-x-2 align-middle">
                            <span className="">Save</span>
                        </div>
                    </button>

                    <button
                        type="button"
                        onClick={() => {
                            formik.setFieldValue('edited', false);
                            formik.resetForm();
                            // subTaskRefetch();
                            window.location.reload();
                        }}
                        className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
                    >
                        <div className="space-x-2 align-middle">
                            <span className="">Reset</span>
                        </div>
                    </button>
                </div>
            ) : null}
            <div>
                <DataGrid
                    className="rdg-light fill-grid w-full"
                    // rowKeyGetter={rowKeyGetter}
                    rows={rows}
                    columns={columns}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    defaultColumnOptions={{
                        sortable: true,
                        minWidth: 250,
                        resizable: true,
                    }}
                />
            </div>
            <div>
                <PaginationFooter />
            </div>
        </div>
    );
}

// function rowKeyGetter(row: Row) {
//     return row.id;
// }
// interface Row {
//     id: number;
// }
