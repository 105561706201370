import { useGetEmployeeIdQuery } from '../../api/generated';

export default function ViewEmployee(props: { empId: number }) {
    const { data: User } = useGetEmployeeIdQuery({
        variables: {
            getEmployeeIdId: props.empId,
        },
    });
    return (
        <div className="order-bar rounded-md grid grid-cols-2 ">
            <div className="profile-name grid grid-rows-2 pl-5 ">
                <div className="space-x-2 align-middle">
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block"
                    >
                        <path
                            d="M11.2659 4.36696C11.966 4.50354 12.6094 4.84592 13.1137 5.35028C13.6181 5.85463 13.9605 6.49801 14.097 7.19808M11.2659 1.5C12.7204 1.66158 14.0767 2.31291 15.1121 3.34704C16.1476 4.38118 16.8006 5.73665 16.964 7.19091M16.2473 12.9105V15.0607C16.2481 15.2603 16.2072 15.4579 16.1272 15.6408C16.0473 15.8237 15.93 15.9879 15.7829 16.1228C15.6358 16.2578 15.4621 16.3605 15.273 16.4244C15.0839 16.4884 14.8836 16.5121 14.6848 16.4942C12.4792 16.2545 10.3607 15.5009 8.49932 14.2938C6.76755 13.1934 5.29932 11.7251 4.19888 9.99336C2.98758 8.12353 2.23376 5.99467 1.99849 3.77923C1.98058 3.58103 2.00414 3.38127 2.06766 3.19267C2.13118 3.00407 2.23328 2.83076 2.36745 2.68378C2.50162 2.5368 2.66492 2.41937 2.84696 2.33896C3.029 2.25855 3.22579 2.21693 3.4248 2.21674H5.57502C5.92286 2.21332 6.26007 2.33649 6.52381 2.56331C6.78755 2.79012 6.95982 3.1051 7.0085 3.44953C7.09925 4.13765 7.26756 4.81329 7.51022 5.46357C7.60665 5.72011 7.62752 5.99891 7.57036 6.26695C7.51319 6.53498 7.38039 6.78101 7.18768 6.97589L6.27743 7.88614C7.29774 9.68053 8.78347 11.1663 10.5779 12.1866L11.4881 11.2763C11.683 11.0836 11.929 10.9508 12.1971 10.8936C12.4651 10.8365 12.7439 10.8574 13.0004 10.9538C13.6507 11.1964 14.3264 11.3648 15.0145 11.4555C15.3626 11.5046 15.6806 11.68 15.9079 11.9483C16.1352 12.2165 16.256 12.559 16.2473 12.9105Z"
                            stroke="#45464E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>

                    <span className="pr-8 ">
                        : {User?.getEmployeeId?.phone}
                    </span>
                </div>
                <div className="space-x-2 align-middle pt-3">
                    <svg
                        width="20"
                        height="16"
                        viewBox="0 0 20 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block"
                    >
                        <path
                            d="M18.1327 2.799L19.2187 2.12021C19.2345 2.20285 19.2427 2.28803 19.2427 2.375V13.625C19.2427 14.3801 18.6228 15 17.8677 15H2.86768C2.11257 15 1.49268 14.3801 1.49268 13.625V2.375C1.49268 2.28803 1.5009 2.20285 1.51661 2.12021L2.60268 2.799L10.1027 7.4865L10.3677 7.65212L10.6327 7.4865L18.1327 2.799ZM19.1477 1.875H17.8677H2.86768H1.58764C1.7891 1.36452 2.28863 1 2.86768 1H17.8677C18.4467 1 18.9462 1.36452 19.1477 1.875ZM17.8677 14.125H18.3677V13.625V4.25V3.34788L17.6027 3.826L10.3677 8.34788L3.13268 3.826L2.36768 3.34788V4.25V13.625V14.125H2.86768H17.8677Z"
                            fill="#45464E"
                            stroke="#45464E"
                        />
                    </svg>

                    <span className="">: {User?.getEmployeeId?.email}</span>
                </div>
            </div>
            <div className="profile-name grid grid-rows-3 pl-5 ">
                <div className="space-x-2 align-middle pt-3">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block"
                    >
                        <circle
                            cx="8.49268"
                            cy="8"
                            r="7.5"
                            fill="url(#paint0_linear_1183_7452)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_1183_7452"
                                x1="1.66289"
                                y1="12.65"
                                x2="11.2374"
                                y2="15.8173"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#5C72E8" />
                                <stop offset="1" stopColor="#5068E2" />
                            </linearGradient>
                        </defs>
                    </svg>

                    <span className="pr-5">Assigned Orders </span>
                    <span> | </span>
                    <span> 250 </span>
                </div>
                <div className="space-x-2 align-middle pt-1 ">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block"
                    >
                        <circle
                            cx="8.49268"
                            cy="8"
                            r="7.5"
                            fill="url(#paint0_linear_1183_7453)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_1183_7453"
                                x1="1.66289"
                                y1="12.65"
                                x2="11.2374"
                                y2="15.8173"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#3BBF68" />
                                <stop offset="1" stopColor="#34BE6B" />
                            </linearGradient>
                        </defs>
                    </svg>

                    <span className="pr-1.5">Completed Orders</span>
                    <span> | </span>
                    <span> 150 </span>
                </div>
                <div className="space-x-2 align-middle  ">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block"
                    >
                        <circle cx="8.49268" cy="8" r="7.5" fill="#FF3A29" />
                    </svg>

                    <span className="pr-6">Pending Orders</span>
                    <span> | </span>
                    <span> 50 </span>
                </div>
            </div>
        </div>
    );
}
