/* eslint-disable no-console */
import { Todo, useUpdateTodoMutation } from '../../../api/generated';
import Icon from '../../../shared/Icons';

type TodoListItemProps = {
    todo: Todo | null | undefined;
    refetch : ()=> Promise<any>
};

const TodoListItem = ({ todo, refetch }: TodoListItemProps) => {

    const [markComplete] = useUpdateTodoMutation()

    const handleCompleted = async ()=>{

        const response = await markComplete({
            variables : {
                input : {
                    id : [Number(todo?.id)]
                }
            },

        })

        console.log("response", response);
        refetch()
        

        


    }

    return (
        <li className="flex group cursor-pointer flex-row justify-between items-start py-0.5 mb-2">
            <div className="flex flex-row gap-2">
                <div className="h-8 w-8">
                    <Icon
                        icon={todo?.complete ? 'checked' : 'unchecked'}
                        className={`transition-all ease-in-out duration-150 group-hover:fill-zedex-button-blue hover:opacity-70 ${
                            todo?.complete
                                ? 'fill-zedex-button-blue'
                                : 'fill-black'
                        }`}
                        size={25}
                        onClick={()=>handleCompleted()}
                    />
                </div>
                <p
                    className={`transition-all ease-in-out duration-150 font-medium group-hover:text-zedex-button-blue ${
                        todo?.complete ? 'text-zedex-button-blue' : 'text-black'
                    }`}
                >
                    {todo?.task}
                </p>
            </div>
            <div className="h-8 w-8">
                <Icon
                    icon="cross"
                    size={28}
                    className="transition-all ease-in-out duration-150 opacity-0 group-hover:opacity-100 fill-zedex-text-blue hover:fill-red cursor-pointer hover:opacity-70"
                />
            </div>
        </li>
    );
};

export default TodoListItem;
