import { Formik, useFormikContext } from 'formik';
import {
    AllNichesQuery,
    useAllNichesQuery,
    useCreateNicheMutation,
} from '../../api/generated';
import { ToggleButton } from './ToggleButton';

export function AddNicheButton() {
    return (
        <div className="flex justify-center mb-8">
            <button
                type="submit"
                className="mt-3 p-3 bg-zedex-button-blue text-white hover:bg-zedex-button-blue-hover rounded-md"
            >
                + Add Category
            </button>
        </div>
    );
}

function AddNicheForm(props: { className?: string }) {
    const formik = useFormikContext<InitialValues>();
    return (
        <div
            className={`border border-slate-400 rounded-xl ${props.className}`}
        >
            <div className="flex flex-row p-5 border-b border-slate-400">
                <label className="basis-1/3 self-center">Niche:</label>
                <input
                    className="basis-2/3 border p-2 rounded-md border-slate-400"
                    type="text"
                    placeholder="Eg. Entertainment"
                    onChange={(event) => {
                        formik.setFieldValue(
                            'niche',
                            event.target.value.trim()
                        );
                    }}
                />
            </div>
            <div className="flex flex-row p-5 space-x-2">
                <label className="basis-1/3 self-center">
                    Niche Abbreviation:
                </label>
                <input
                    className="basis-2/3 border p-2 rounded-md border-slate-400"
                    type="text"
                    placeholder="Eg. ENT"
                    required
                    onBlur={(event) => {
                        formik.setFieldValue(
                            'nicheAbbr',
                            event.target.value.trim()
                        );
                    }}
                />
            </div>
            <AddNicheButton />
            <div className="mb-3 text-left ml-3 text-rose-600 font-semibold text-sm">
                *Warning: NicheOnce Added cannot be edited.
            </div>
        </div>
    );
}

function DisplayNiches(props: {
    className?: string;
    niches: AllNichesQuery | undefined;
}) {
    const formik = useFormikContext<InitialValues>();
    return (
        <div
            className={`border p-2 border-slate-400 rounded-xl ${props.className}`}
        >
            <div className="bg-slate-100 rounded-md pb-4">
                <div className="flex justify-center">
                    <div className="w-[100%] text-center">
                        <div className="bg-slate-200 rounded-md grid grid-cols-4 p-2">
                            <div className="">Sr. No</div>
                            <div className="">Niche</div>
                            <div className="">Abbreviation</div>
                            <div>Action</div>
                        </div>
                        <div className="mt-2 space-y-1">
                            {props.niches?.allNiches?.map((niche, index) => {
                                return (
                                    <div
                                        key={niche?.id}
                                        className={`grid grid-cols-4 p-3 m-5 my-0 rounded-md ${
                                            niche?.disabled
                                                ? 'bg-rose-100'
                                                : 'bg-green-200'
                                        }`}
                                    >
                                        <div>{index + 1}</div>
                                        <div>{niche?.niche}</div>
                                        <div>{niche?.nicheAbbr}</div>
                                        <div>
                                            <ToggleButton
                                                active={
                                                    niche?.disabled as boolean
                                                }
                                                nicheID={niche?.id as number}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div />
            </div>
        </div>
    );
}

interface InitialValues {
    niche: string;
    nicheAbbr: string;
}
export function Niche() {
    const [addNiche] = useCreateNicheMutation();
    const { data: niches, refetch } = useAllNichesQuery();
    return (
        <Formik
            initialValues={{
                niche: '',
                nicheAbbr: '',
            }}
            onSubmit={async (values) => {
                await addNiche({
                    variables: {
                        input: {
                            niches: [
                                {
                                    niche: values.niche,
                                    nicheAbbr: values.nicheAbbr,
                                },
                            ],
                        },
                    },
                });
                refetch();
            }}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                    <div className="pl-6 pt-5 text-2xl py-3">
                        Add New Category
                    </div>
                    <div className="space-y-10 lg:flex lg:flex-row lg:space-x-10 lg:space-y-0 pt-5">
                        <AddNicheForm className="lg:basis-1/2" />
                        <DisplayNiches
                            className="lg:basis-1/2"
                            niches={niches}
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}
