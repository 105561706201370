import { ApolloClient, ApolloProvider, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { TOKEN } from './constants/storageKeys';
import { cache } from './graphql/cache';
import {
    accessTokenVar,
    isLoggedInVar,
    userRoleVar,
} from './graphql/cache/account';

import { typeDefs } from './graphql/client';
import './index.css';

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(TOKEN);

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((error) => console.error(error));
    }
    // if (networkError) {
    //     console.error(networkError);
    //     isLoggedInVar(false);
    //     userRoleVar(null);
    //     accessTokenVar(null);
    //     localStorage.clear();
    // }
});

const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache,
    typeDefs,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>
);
