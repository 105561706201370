import { useFormikContext } from 'formik';
import { ClientOrderInitialValues } from '../../types/ClientOrderInitialValues';

export function ResetFilterButton() {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <button
            type="button"
            className="border border-blue-200 max-h-12 focus:border-blue-600 hover:border-blue-600 hover:bg-blue-100 p-2 px-3 rounded-md ml-2 align-middle mt-2 space-x-2"
            onClick={(event) => {
                formik.resetForm();
            }}
        >
            Reset Filter
        </button>
    );
}
