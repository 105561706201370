import { useFormik, useFormikContext } from 'formik';
import { useEffect } from 'react';
import {
    ContentOrder,
    ContentOrderServiceTypeAttributeValue,
    Role,
    useAssignCmsAdminMutation,
    useAssignCmsEmployeeMutation,
    useContentOrderArticleLinkMutation,
    useGetEmployeeUsingTokenQuery,
    useGetEmployeeWithRoleQuery,
} from '../../api/generated';
import { CopyIcon } from '../main/CopyIcon';
import ContentOrderHeader from './ClientOrderHeader';
import { InitialValues } from './ContentOrderInitalValues';
import InstructionAccordion from './InstructionAccordion';
import { OrderServiceType } from './OrderServiceType';

export default function ViewContentOrder(props: { data: ContentOrder }) {
    const formik = useFormikContext<InitialValues>();

    useEffect(() => {
        if (formik.values.wordCount === 0) {
            formik.setFieldValue('wordCount', props?.data?.wordCount);
        }

        if (formik.values.clientSiteUrl === undefined) {
            formik.setFieldValue('clientSiteUrl', props?.data?.clientSiteURL);
        }

        if (formik.values.pbnName === undefined) {
            formik.setFieldValue('pbnName', props?.data?.PbnName);
        }

        if (formik.values.projectDescription === undefined) {
            formik.setFieldValue(
                'projectDescription',
                props?.data?.orderDescription
            );
        }
    }, []);

    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();

    const order = props.data;
    return (
        <>
            <ContentOrderHeader
                id={order?.id?.toString() || ''}
                clientName={props.data.client?.name || ''}
                startDate={order?.orderStartDate || ''}
                dueDate={order?.orderDueDate || ''}
                wordCount={order?.wordCount || 0}
                pbnName={order?.PbnName || ''}
                clientSiteURL={order?.clientSiteURL || ''}
                status={order?.orderStatus || ''}
            />
            {loggedInUser?.getEmployeeUsingToken?.role ===
            Role.Cmsemployee ? null : (
                <AssignedTo
                    contentOrderID={props?.data?.id as string}
                    cmsAdminID={props?.data?.cmsAdmin?.id as number}
                    cmsAdminName={`${props?.data?.cmsAdmin?.firstName} ${props?.data?.cmsAdmin?.lastName}`}
                    cmsEmployeeID={props?.data?.cmsEmployeeID as number}
                    cmsEmployeeName={`${props?.data?.cmsEmployee?.firstName} ${props?.data?.cmsEmployee?.lastName}`}
                />
            )}
            <ArticleLinkInput
                contentOrderID={props?.data?.id as string}
                articleLink={props?.data?.articleLink || ''}
            />
            <InstructionAccordion
                projectDescription={order?.orderDescription || ''}
            />
            <OrderServiceType
                serviceTypeAttributeValues={
                    order?.contentOrderServiceTypeAttributeValue as ContentOrderServiceTypeAttributeValue[]
                }
            />
        </>
    );
}

function AssignedTo(props: {
    cmsAdminID: number;
    cmsAdminName: string;
    cmsEmployeeID: number;
    cmsEmployeeName: string;
    contentOrderID: string;
}) {
    const { data: cmsAdmins } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Cmsadmin],
            },
        },
    });

    const { data: cmsEmployees } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Cmsemployee],
            },
        },
    });

    const [assignCMSAdmin] = useAssignCmsAdminMutation();
    const [assignCMSEmployee] = useAssignCmsEmployeeMutation();
    return (
        <div className="grid rounded align-middle p-3 bg-zedex-bg-gray w-full lg:grid-cols-2 gap-5">
            <div className="flex flex-row bg-white p-2">
                <div className="basis-1/3 p-3">CMS Admin:</div>
                <div className="basis-2/3">
                    <select
                        className="p-3 bg-transparent text-center border-2 w-48 rounded-lg border-zedex-button-blue hover:bg-blue-50"
                        onChange={async (event) => {
                            await assignCMSAdmin({
                                variables: {
                                    input: {
                                        contentOrderID: props.contentOrderID,
                                        employeeID: Number(event.target.value),
                                    },
                                },
                            });
                        }}
                    >
                        <option value={props?.cmsAdminID}>
                            {props?.cmsAdminName}
                        </option>
                        {cmsAdmins?.getEmployeeWithRole &&
                            cmsAdmins?.getEmployeeWithRole.map((employee) => (
                                <option
                                    key={employee?.id as number}
                                    value={employee?.id as number}
                                >{`${employee?.firstName} ${employee?.lastName}`}</option>
                            ))}
                    </select>
                </div>
            </div>
            <div className="flex flex-row bg-white p-2">
                <div className="basis-1/3 p-3">CMS Employee:</div>
                <div className="basis-2/3">
                    <select
                        className="p-3 bg-transparent text-center border-2 w-48 rounded-lg border-zedex-button-blue hover:bg-blue-50"
                        onChange={async (event) => {
                            await assignCMSEmployee({
                                variables: {
                                    input: {
                                        contentOrderID: props.contentOrderID,
                                        employeeID: Number(event.target.value),
                                    },
                                },
                            });
                        }}
                    >
                        <option value={props?.cmsEmployeeID}>
                            {props?.cmsEmployeeName}
                        </option>
                        {cmsEmployees?.getEmployeeWithRole &&
                            cmsEmployees?.getEmployeeWithRole.map(
                                (employee) => (
                                    <option
                                        key={employee?.id as number}
                                        value={employee?.id as number}
                                    >{`${employee?.firstName} ${employee?.lastName}`}</option>
                                )
                            )}
                    </select>
                </div>
            </div>
        </div>
    );
}

function ArticleLinkInput(props: {
    contentOrderID: string;
    articleLink: string;
}) {
    const [articleLink] = useContentOrderArticleLinkMutation();
    const formikForm = useFormik({
        initialValues: {
            edited: false,
            articleLink: '',
        },
        onSubmit: async () => {
            const { data } = await articleLink({
                variables: {
                    input: {
                        articleLink: formikForm.values.articleLink,
                        contentOrderID: props.contentOrderID,
                    },
                },
            });
            if (data?.contentOrderArticleLink?.success) {
                formikForm.setFieldValue('edited', false);
            }
        },
    });
    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();
    return (
        <div className="rounded align-middle p-3 bg-zedex-bg-gray w-full">
            <form
                className="flex flex-row bg-white p-5"
                onSubmit={formikForm.handleSubmit}
            >
                <div className="basis-1/3 py-2 self-center">Article Link:</div>

                {loggedInUser?.getEmployeeUsingToken?.role ===
                Role.Cmsemployee ? (
                    <div className="basis-1/3 py-2 flex flex-row space-x-2">
                        <input
                            className="border w-full rounded-md py-2 text-center"
                            type="url"
                            defaultValue={props.articleLink}
                            onChange={(event) => {
                                formikForm.setFieldValue(
                                    'articleLink',
                                    event.target.value
                                );
                                formikForm.setFieldValue('edited', true);
                            }}
                        />
                        <CopyIcon text={props.articleLink} />
                    </div>
                ) : (
                    <div className="basis-2/3 py-2 space-x-2">
                        <div className="w-full rounded-md py-2 text-center flex flex-row space-x-4">
                            <input
                                type="text"
                                className="inline-block p-3 border w-full"
                                value={
                                    props.articleLink === ''
                                        ? 'No Link Submitted Yet'
                                        : props.articleLink
                                }
                                disabled
                            />
                            <CopyIcon text={props.articleLink} />
                        </div>
                    </div>
                )}
                {formikForm.values.edited ? (
                    <div className="basis-1/3 flex justify-center">
                        <button
                            className="px-4 py-2 text-white bg-zedex-button-blue hover:bg-zedex-button-blue-hover rounded-lg"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                ) : null}
            </form>
        </div>
    );
}
