import '../../styles/WebsiteOutreachPool.css';

import { useFormikContext } from 'formik';
import { WebsiteOutreachPoolInitialValues } from '../../types/WebsiteOutreachPoolInitialValues';

export function PredefinedFilters() {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    return (
        <div className="predef-row grid grid-flow-col auto-cols-max gap-4 mt-4 text-sm font-bold tracking-wide ml-2 text-center">
            <button
                type="button"
                className="predef-option predef-negotiate"
                onChange={formik.handleChange}
                onClick={() =>
                    formik.setFieldValue('statusFilters', ['Negotiating'])
                }
            >
                Negotiating
            </button>
            <button
                type="button"
                className="predef-option predef-done"
                onChange={formik.handleChange}
                onClick={() => formik.setFieldValue('statusFilters', ['Done'])}
            >
                Done
            </button>
            <button
                type="button"
                className="predef-option predef-all"
                onChange={formik.handleChange}
                onClick={() =>
                    formik.setFieldValue('statusFilters', [
                        'Negotiating',
                        'Done',
                    ])
                }
            >
                All
            </button>
        </div>
    );
}
