import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import { Link } from 'react-router-dom';
import {
    ClientSelectedSite,
    MasterSheet,
    useGetAllServiceTypeQuery,
    usePaginatedClientSelectedSiteListQuery
} from '../../api/generated';
import '../../styles/WebsiteOutreachPool.css';
import { WebsiteOutreachPoolInitialValues } from '../../types/WebsiteOutreachPoolInitialValues';
import { CopyIcon } from '../main/CopyIcon';

function TableRow(props: MasterSheet) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();
    const contactTypeFilteredDomainDetails =
        formik.values.contactTypeFilter.length === 2
            ? props.domainDetails
            : props.domainDetails.filter((domainDetail) => {
                return domainDetail?.contactType?.contactType?.includes(
                    formik.values.contactTypeFilter[0]
                );
            });
    const [contactType, setContactType] = useState('ADMIN');
    const contactTypesAvailable: string[] = [];
    const domainDetails = contactTypeFilteredDomainDetails.filter(
        (domainDetail) => {
            contactTypesAvailable.push(
                domainDetail?.contactType?.contactType as string
            );
            return domainDetail?.contactType?.contactType?.includes(
                contactType
            );
        }
    );
    const { data: serviceType } = useGetAllServiceTypeQuery();
    const linkInsertionID = serviceType?.getAllServiceType?.filter(
        (type) => type?.serviceType === 'LINK-INSERTION'
    )[0]?.id;
    return (
        <tr className="hover:bg-blue-100">
            <td>
                <input type="checkbox" />
            </td>
            <td className="site-url underline">
                <Link to=" " className="space-x-2" >
                    <span className="underline">{props.siteUrl}</span>
                    <CopyIcon text={props.siteUrl} />

                </Link>
            </td>
            <td>{props.siteDA}</td>
            <td>{props.siteDR}</td>
            <td>{props.siteTraffic}</td>
            <td>
                <select
                    className="p-3 w-32 text-center bg-blue-50 hover:bg-blue-200"
                    onChange={(event) => setContactType(event.target.value)}
                >
                    {contactTypesAvailable.map((type) => (
                        <option className="p-3 w-32 text-center" key={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </td>
            {domainDetails.map((domainDetail) => {
                return (
                    <>
                        <td>{domainDetail?.serviceType?.serviceType}</td>
                        <td>
                            {domainDetail?.serviceType?.id === linkInsertionID
                                ? domainDetail?.linkInsertionPrice
                                : 'NaN'}
                        </td>
                        <td>{domainDetail?.priceAsked}</td>
                        <td>{domainDetail?.finalPrice}</td>
                        <td>{domainDetail?.specialPrice}</td>
                        <td>
                            {domainDetail?.contactedBy?.firstName?.concat(
                                ` ${domainDetail.contactedBy?.lastName}`
                            )}
                        </td>
                        <td>{domainDetail?.POC}</td>
                        <td>{domainDetail?.dateOfContact.substring(0, 10)}</td>
                    </>
                );
            })}
            <td>
                {props.DomainNiches?.map((x, i) => (
                    <div className="block my-2" key={i.toString()}>
                        {x?.niche?.niche}
                    </div>
                ))}
            </td>
            <td>
                {props.siteContactDetails.map((x, i) =>
                    x?.contactType?.contactType === contactType ? (
                        <div className="block m-3" key={i.toString()}>
                            {x?.contactDetails}
                        </div>
                    ) : null
                )}
            </td>

            {domainDetails?.map((domainDetails) => {
                if (domainDetails?.status === 'Done') {
                    return (
                        <td key={domainDetails.status} className="font-bold">
                            <span className="block done-status p-4">Done</span>
                        </td>
                    );
                }
                if (domainDetails?.status === 'Negotiating') {
                    return (
                        <td key={domainDetails.status} className=" font-bold">
                            <span className="block nego-status p-4">
                                Negotiating
                            </span>
                        </td>
                    );
                }
                return <td key={domainDetails?.status} />;
            })}
        </tr>
    );
}

export function Table(props: { orderID: string }) {
    const formik = useFormikContext<WebsiteOutreachPoolInitialValues>();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data, loading } = usePaginatedClientSelectedSiteListQuery({
        variables: {
            input: {
                orderID: props.orderID,
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                search: {
                    ...formik.values.search,
                },
            },
        },
    });
    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedClientSelectedSiteList?.totalPages
        );
    }, [data?.paginatedClientSelectedSiteList?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.paginatedClientSelectedSiteList?.totalRows
        );
    }, [data?.paginatedClientSelectedSiteList?.totalRows]);

    const row: ClientSelectedSite[] = [];

    data?.paginatedClientSelectedSiteList?.siteUrls?.forEach((url) => {
        row.push(url as ClientSelectedSite);
    });
    return (
        <div className="website-outreach-pool-table overflow-x-auto overflow-y-auto text-center bg-transparent">
            {loading ? null : row.length === 0 ? (
                <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-scenter">
                    No Data To be Displayed
                </div>
            ) : (
                <DataGrid
                    className="rdg-light "
                    columns={[{ key: 'siteURL', name: 'Site URL' }]}
                    rows={row}
                />
            )}
        </div>
    );
}
