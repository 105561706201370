import {
    CalendarDaysIcon,
    GlobeAsiaAustraliaIcon,
} from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import {
    Employee,
    useUpdateMainOrderStatusMutation,
    useUpdateMainOrdersMutation,
    useUpdateSeoOrderManagerMutation,
} from '../../api/generated';
import { BlueButton } from '../main/BlueButton';
import { SelectExportColumns } from './SelectColumnsButton';
import { SelectColumns } from './SelectColumnsModule';
import { InitialValues } from './Table';

export default function ClientOrderHeader(props: {
    id: string;
    clientName: string;
    clientID: number;
    startDate: string;
    dueDate: string;
    seoOrderManagerID: number;
    seoOrderManagerName: string;
    articleCount: number;
    requiredDA: number;
    requiredDR: number;
    clientSiteURL: string;
    employeeAssignable: Employee[];
    status: string;
    refetch: any;
}) {
    const formik = useFormikContext<InitialValues>();
    const startDate = new Date().getTime();
    const dueDate = new Date(props.dueDate).getTime();
    const timeDue = startDate - dueDate;
    const timeDueinDays = timeDue / (1000 * 3600 * 24);
    const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
    const [updateSeoOrderManager] = useUpdateSeoOrderManagerMutation();
    const [updateMainOrder] = useUpdateMainOrdersMutation();

    const orderStatuses = [
        'Done',
        'Processing',
        'Issue-Raised',
        'Overdue',
        'Cancelled',
    ];
    const [updateMainOrderStatus] = useUpdateMainOrderStatusMutation();

    useEffect(() => {
        if (!formik.values.edittedDueDate) {
            formik.setFieldValue('edittedDueDate', props.dueDate);
        }
        if (!formik.values.edittedReqDA) {
            formik.setFieldValue(
                'edittedReqDA',
                props.requiredDA === 0 ? props.requiredDR : props.requiredDA
            );
        }
    }, []);
    return (
        <>
            <div className="space-x-2 grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="sm:col-span-2 align-middle">
                    <h3 className="text-[#5570F1] text-2xl mx-2 font-bold inline-block">
                        {'#'.concat(props?.id)}/ {props.clientName}
                    </h3>
                    <div
                        className={`font-semibold bg-opacity-30 inline-block p-1 px-3 rounded ${
                            timeDue <= 0
                                ? 'bg-[#519c66] text-[#519c66]'
                                : 'bg-orange-400 text-red'
                        }`}
                    >
                        {timeDue > 0
                            ? `Due Past ${
                                  timeDueinWeeks < 0
                                      ? `${Math.abs(
                                            Math.floor(timeDueinWeeks)
                                        )} weeks and ${Math.abs(
                                            Math.ceil(
                                                Math.floor(timeDueinDays) % 7
                                            )
                                        )} days`
                                      : Math.floor(timeDueinDays) > 0
                                      ? `${Math.abs(
                                            Math.floor(timeDueinDays)
                                        )} days`
                                      : ``
                              }`
                            : timeDue < 0
                            ? `Due After ${
                                  timeDueinWeeks < 0
                                      ? `${Math.abs(
                                            Math.ceil(timeDueinWeeks)
                                        )} weeks and ${Math.abs(
                                            Math.ceil(
                                                Math.ceil(timeDueinDays) % 7
                                            )
                                        )} days`
                                      : Math.ceil(timeDueinDays) < 0
                                      ? `${Math.abs(
                                            Math.ceil(timeDueinDays)
                                        )} days`
                                      : ``
                              }`
                            : 'Due Today'}
                    </div>
                </div>
                <div className="inline-block rounded align-middle">
                    <select
                        className="p-3 bg-transparent text-center border-2 w-48 rounded-lg border-zedex-button-blue hover:bg-blue-50"
                        onChange={(event) => {
                            updateSeoOrderManager({
                                variables: {
                                    input: {
                                        orderID: props.id,
                                        newSeoOrderManagerID: Number(
                                            event.target.value
                                        ),
                                    },
                                },
                            });
                        }}
                    >
                        <option value={props?.seoOrderManagerID}>
                            {props.seoOrderManagerID?props.seoOrderManagerName:"None"}
                        </option>
                        {props.employeeAssignable &&
                            props.employeeAssignable.map((employee) => (
                                <option
                                    key={employee.id as number}
                                    value={employee.id as number}
                                >{`${employee.firstName} ${employee.lastName}`}</option>
                            ))}
                    </select>
                </div>
                <div className="align-middle">
                    <select
                        className="basis-1/2 bg-blue-500 hover:bg-blue-700 text-white font-semibold border border-blue-700 rounded w-full h-10 p-2 text-center"
                        defaultValue={props.status}
                        onChange={async (event) => {
                            const { data } = await updateMainOrderStatus({
                                variables: {
                                    input: {
                                        orderID: props?.id as string,
                                        status: event.target.value,
                                    },
                                },
                            });
                            if (data?.updateMainOrderStatus?.success) {
                                props.refetch();
                            }
                        }}
                    >
                        <option value={props.status}>{props.status}</option>
                        {orderStatuses
                            .filter((status) => status !== props.status)
                            .map((status) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className=" grid md:grid-cols-1 lg:grid-cols-3 gap-4 my-2 py-2 bg-slate-50">
                <div className=" bg-white border border-white rounded p-2">
                    <GlobeAsiaAustraliaIcon className="w-10 h-10 rounded inline-block fill-blue-500 align-middle p-1 bg-[#DBDEEE] mr-4" />
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-blue-500 text-xs">
                            Client Site URL
                        </div>
                        <div
                            className="text-blue-500 text-lg font-semibold sm:hidden lg:block"
                            title={props.clientSiteURL}
                        >
                            {props.clientSiteURL.length > 42
                                ? props.clientSiteURL
                                      .substring(0, 42)
                                      .concat('...')
                                : props.clientSiteURL}
                        </div>
                        <div
                            className="text-blue-500 text-lg font-semibold lg:hidden"
                            title={props.clientSiteURL}
                        >
                            {props.clientSiteURL}
                        </div>
                    </div>
                </div>
                <div className=" bg-white border border-white rounded p-2 grid grid-cols-2 text-center">
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-slate-500">
                            No of Links to Build
                        </div>
                        <div className="text-black text-center">
                            {props.articleCount}
                        </div>
                    </div>
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-slate-500">
                            {props.requiredDA === 0 ? 'DR' : 'DA'} Required{' '}
                        </div>
                        <div className="text-black text-center">
                            {formik.values.toggleEdit ? (
                                <input
                                    className="border text-center"
                                    type="number"
                                    min={0}
                                    max={100}
                                    defaultValue={
                                        props.requiredDA === 0
                                            ? props.requiredDR
                                            : props.requiredDA
                                    }
                                    onChange={(event) =>
                                        formik.setFieldValue(
                                            'edittedReqDA',
                                            Number(event.target.value)
                                        )
                                    }
                                />
                            ) : props.requiredDA === 0 ? (
                                props.requiredDR
                            ) : (
                                props.requiredDA
                            )}
                        </div>
                    </div>
                </div>
                <div className=" bg-white border border-white rounded p-2 grid grid-cols-2">
                    <div>
                        <CalendarDaysIcon className="w-8 h-8 rounded inline-block bg-[#DBDEEE] fill-[#3BBF68] align-middle p-1" />
                        <div className="inline-block align-middle ml-2 mr-4 text-sm">
                            <div className="text-blue-500">Start Date</div>
                            <div className="text-blue-500">
                                {props?.startDate.substring(0, 10)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <CalendarDaysIcon className="w-8 h-8 rounded inline-block fill-[#FF3A29] align-middle p-1 bg-[#DBDEEE]" />
                        <div className="inline-block align-middle ml-2 text-sm">
                            <div className="text-blue-500">End Date</div>
                            <div className="text-blue-500">
                                {formik.values.toggleEdit ? (
                                    <input
                                        className="border text-center"
                                        type="date"
                                        defaultValue={props.dueDate.substring(
                                            0,
                                            10
                                        )}
                                        onChange={(event) =>
                                            formik.setFieldValue(
                                                'edittedDueDate',
                                                event.target.value
                                            )
                                        }
                                    />
                                ) : (
                                    props.dueDate.substring(0, 10)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between lg:col-span-2">
                    <BlueButton
                        to="/admin/potential-sheet"
                        state={{
                            clientID: props.clientID,
                        }}
                        label="Potential-Sites"
                    />
                    <BlueButton
                        to="/admin/client-selected-sites"
                        state={{
                            orderID: props.id,
                        }}
                        label="Selected-Sites"
                    />
                    <SelectExportColumns />
                </div>
                <div className="flex justify-between">
                    <AiOutlineEdit
                        className="fill-zedex-text-blue w-6 h-6 mt-3 inline-block"
                        onClick={() =>
                            formik.setFieldValue(
                                'toggleEdit',
                                !formik.values.toggleEdit
                            )
                        }
                    />
                    {formik.values.toggleEdit ? (
                        <>
                            <button
                                type="button"
                                onClick={async () => {
                                    const { data } = await updateMainOrder({
                                        variables: {
                                            input: {
                                                id: props.id,
                                                orderDueDate:
                                                    formik.values
                                                        .edittedDueDate ||
                                                    props.dueDate,
                                                requiredDA:
                                                    formik.values.edittedReqDA,
                                                DAorDR:
                                                    props.requiredDA === 0
                                                        ? 'DR'
                                                        : 'DA',
                                            },
                                        },
                                    });
                                    if (data?.updateMainOrders?.success) {
                                        formik.setFieldValue(
                                            'toggleEdit',
                                            false
                                        );
                                        props.refetch();
                                    }
                                }}
                                className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
                            >
                                <div className="space-x-2 align-middle">
                                    <span className="">Save</span>
                                </div>
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    formik.setFieldValue('toggleEdit', false);
                                }}
                                className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
                            >
                                <div className="space-x-2 align-middle">
                                    <span className="">Reset</span>
                                </div>
                            </button>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="my-3">
                {formik.values.toggleSelectColumns ? <SelectColumns /> : null}
            </div>
        </>
    );
}
