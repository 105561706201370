import { useFormik, useFormikContext } from 'formik';
import { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import {
    Comments,
    useAddNewCommentMutation,
    useGetCommentByOrderIdQuery,
    useGetEmployeeUsingTokenQuery,
    useGetEmployeesQuery,
} from '../../api/generated';
import defaultMentionStyle from '../Mentions/defaultMentionStyle';
import defaultStyle from '../Mentions/defaultStyle';
import '../Mentions/example.css';

function CommentsTest(props: { orderID: string }) {
    return (
        <div className=" grid grid-cols-3 bg-white py-4">
            <div className="col-span-3 space-y-2 p-2">
                <label
                    className=" text-sm font-semibold text-slate-500 inline-block float-left ml-2"
                    htmlFor="comments"
                >
                    Comments:
                </label>

                {/* <input
                    className="border bg-white h-20 border-slate-400 rounded-sm 
                    w-full p-1 inline-block float-right"
                    type="text"
                    name="comments"
                    id="commentsInput"
                    placeholder="Add your comments here"
                    required
                /> */}
                <SingleLine orderID={props.orderID} />
            </div>
        </div>
    );
}
interface InitialValues {
    description: string;
    employeeID: number;
    orderID: string;
}
function SingleLine(props: { orderID: string }) {
    const [value, setValue] = useState('');
    const { data } = useGetEmployeesQuery();
    const [addNewComment] = useAddNewCommentMutation();
    const { data: loggedin } = useGetEmployeeUsingTokenQuery();

    const formik = useFormik({
        initialValues: {
            description: '',
            employeeID: 0,
            orderID: '',
        },
        onSubmit: () => {
            addNewComment({
                variables: {
                    input: {
                        description: formik.values.description,
                        employeeID: loggedin?.getEmployeeUsingToken?.id,
                        orderID: props.orderID,
                    },
                },
            });
            formik.setFieldValue('description', '');
        },
    });
    const users =
        data?.getEmployees?.map((Employee) => ({
            display: Employee?.firstName?.concat(
                ` ${Employee?.lastName}` as string
            ) as string,
            id: Employee?.id as number,
        })) || [];

    return (
        <div className="single-line p-2">
            {/* <br />
            <p>
                <span className=" text-blue-500">
                    {
                        props.employee?.firstName?.concat(
                            props.employee?.lastName as string
                        ) as string
                    }
                </span>
                <span className="text-slate-400"> ({props.createdAt})</span>
                <br /> Hello
                <strong>
                    @{' '}
                    {props.employee?.firstName?.concat(
                        props.employee?.lastName as string
                    )}
                </strong>
                this is a test comment
            </p>
            <br /> */}
            <ViewComments
                data={{
                    __typename: undefined,
                    createdAt: undefined,
                    description: undefined,
                    employee: undefined,
                    id: undefined,
                    orderID: undefined,
                    updatedAt: undefined,
                }}
            />
            <form onSubmit={formik.handleSubmit}>
                <MentionsInput
                    className="border bg-white h-20 border-slate-400 rounded-sm 
                    w-full p-1 inline-block float-right"
                    // singleLine
                    // value={value}
                    value={formik.values.description}
                    onChange={(e) =>
                        formik.setFieldValue('description', e.target.value)
                    }
                    // onChange={(e) => setValue(e.target.value)}
                    // onBlur= {handleOnBlur}
                    // onChange={handleOnChange}
                    placeholder="Add your comments here. Mention people using '@'"
                    a11ySuggestionsListLabel="Suggested mentions"
                    style={defaultStyle}
                >
                    <Mention
                        style={defaultMentionStyle}
                        data={users}
                        trigger="@"
                    />
                </MentionsInput>
                <button
                    className="bg-blue-500 inline-block hover:bg-blue-700 text-white font-bold py-2 px-2 float-right rounded mx-3 my-3"
                    type="submit"
                >
                    Post Comment
                </button>
            </form>
        </div>
    );
}

function ViewComments(props: { data: Comments }) {
    const formik = useFormikContext<InitialValues>();
    const { data } = useGetCommentByOrderIdQuery({
        variables: {
            input: {
                orderID: formik.values.orderID,
            },
        },
    });
    function highlight(comment: string) {
        const regex = /@\[([\w\s]+)\]\(\d+\)/g;
        const parts = comment.split(regex);
        return parts.map((part, index) => {
            if (index % 2 === 1) {
                // This is a matched username, so enclose it in <strong> tags
                const username = part.replace('@[', '').replace(']', '');
                // eslint-disable-next-line react/no-array-index-key
                return <strong key={index}>@{username}</strong>;
            }
            // This is not a matched username, so return the part as is
            return part;
        });
    }

    return (
        <div>
            <br />
            <p>
                {data?.getCommentByOrderID?.map((comment) => (
                    <p
                        className="py-3 px-1 flex border-b-2 border-slate-200"
                        key={comment?.id}
                    >
                        <img
                            className="rounded-full mr-5"
                            src="/images/profilePic.jpg"
                            alt=""
                            width={50}
                            height={50}
                        />
                        <p>
                            {' '}
                            <span className=" text-blue-500 font-bold">
                                {comment?.employee?.firstName}{' '}
                                {comment?.employee?.lastName}
                            </span>
                            <span className="text-slate-400 ml-3">
                                {}
                                {new Date(comment?.createdAt)
                                    .toLocaleString()
                                    .replace(',', '')
                                    .replace(/:.. /, ' ')}
                            </span>
                            <br />
                            <p className="ml-1 ">
                                {highlight(comment?.description as string)}
                            </p>
                        </p>
                    </p>
                ))}
            </p>
            <br />
        </div>
    );
}

export default CommentsTest;
