import '../../styles/welcome.css';

import NavBar from '../../components/main/Navbar/Navbar';
import ProfileBar from '../../components/main/ProfileBar';
import EmpProfilePage from '../../components/ViewEmp/ProfilePage';

export default function ViewEmpProf() {
    return (
        <>
            <NavBar activeElement="dashboard" />
            <ProfileBar title="Profile" />
            <div className="backg">
                <EmpProfilePage />
            </div>
        </>
    );
}
