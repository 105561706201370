import { usePaginatedOrderListQuery } from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/notification.css';
import CardWrapper from './CardWrapper';

import Percent from './PercentBar';

export default function OrderDisplay() {
    const { data } = usePaginatedOrderListQuery();
    const total = data?.paginatedOrderList?.totalRows;
    const dataset = data?.paginatedOrderList?.orderList;
    let done = 0;
    let overdue = 0;
    let cancelled = 0;
    dataset?.map((row) => (row?.orderStatus === 'Done' ? done++ : done));
    dataset?.map((row) =>
        row?.orderStatus === 'Overdue' ? overdue++ : overdue
    );
    dataset?.map((row) =>
        row?.orderStatus === 'Cancelled' ? cancelled++ : cancelled
    );
    const percentTotal = String((Number(total) / (Number(total) + 10)) * 100);
    const percentDone = String((Number(done) / (Number(done) + 10)) * 100);
    const percentOverdue = String(
        (Number(overdue) / (Number(overdue) + 10)) * 100
    );
    const percentCancelled = String(
        (Number(cancelled) / (Number(cancelled) + 10)) * 100
    );

    return (
        <CardWrapper href="/admin/order-list" icon="Stack" title="Orders">
            <div>
                <Percent
                    bgcolor="blue"
                    progress={percentTotal}
                    height={10}
                    name="Total"
                    numbre={Number(total)}
                />
                <Percent
                    bgcolor="green"
                    progress={percentDone}
                    height={10}
                    name="Done"
                    numbre={done}
                />

                <Percent
                    bgcolor="orange"
                    progress={percentOverdue}
                    height={10}
                    name="Overdue"
                    numbre={overdue}
                />

                <Percent
                    bgcolor="red"
                    progress={percentCancelled}
                    height={10}
                    name="Cancelled"
                    numbre={cancelled}
                />
            </div>
        </CardWrapper>
    );
}
