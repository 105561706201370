import { TOKEN, USER_ROLE } from '../../constants/storageKeys';

import { makeVar } from '@apollo/client';

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem(TOKEN));

export const accessTokenVar = makeVar<string | null>(
    localStorage.getItem(TOKEN)
);

export const userRoleVar = makeVar<string | null>(
    localStorage.getItem(USER_ROLE)
);
