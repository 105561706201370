import { XCircleIcon } from '@heroicons/react/20/solid';
import { Formik, useFormikContext } from 'formik';
import {
    ContentOrderServiceTypeAttribute,
    GetAllContentOrderServiceTypesQuery,
    useCreateContentOrderServiceTypeMutation,
    useGetAllContentOrderServiceTypesQuery,
} from '../../api/generated';
import { ToggleButton } from './ToggleButton';

export function AddContentOrderServiceTypeButton() {
    return (
        <div className="flex justify-center mb-8">
            <button
                type="submit"
                className="mt-3 p-3 bg-zedex-button-blue text-white hover:bg-zedex-button-blue-hover rounded-md"
            >
                + Add Service Type
            </button>
        </div>
    );
}

function AddContentOrderServiceTypeForm(props: { className?: string }) {
    const formik = useFormikContext<InitialValues>();
    return (
        <div
            className={`border border-slate-400 rounded-xl ${props.className}`}
        >
            <div className="flex flex-row p-5 border-b border-slate-400">
                <label className="basis-1/3 self-center">
                    Service Type Name:
                </label>
                <input
                    className="basis-2/3 border p-2 rounded-md border-slate-400"
                    type="text"
                    placeholder="Eg. Blog"
                    onChange={(event) => {
                        formik.setFieldValue(
                            'serviceTypeName',
                            event.target.value.trim()
                        );
                    }}
                />
            </div>
            <div className="flex justify-center">
                <button
                    onClick={() => {
                        formik.setFieldValue('serviceTypeAttribute', [
                            ...formik.values.serviceTypeAttribute,
                            {
                                attributeName:
                                    formik.values.serviceTypeAttributeName,
                            },
                        ]);
                    }}
                    type="button"
                    className="mt-3 p-3 bg-slate-200 text-blue-600 font-semibold text-md border-2 border-blue-600 tracking-wide hover:bg-blue-600 hover:text-white rounded-md"
                >
                    + Add Service Attribute
                </button>
            </div>
            {/* {formik.values.serviceTypeAttribute.map((e, i) => ( */}
            <div className="flex flex-row p-5 space-x-2">
                <label className="basis-1/3 self-center">Attribute Name:</label>
                <input
                    className="basis-2/3 border p-2 rounded-md border-slate-400"
                    type="text"
                    placeholder="Eg. Keyword"
                    required
                    onBlur={(event) => {
                        formik.setFieldValue(
                            'serviceTypeAttributeName',
                            event.target.value
                        );
                    }}
                />
            </div>
            <div className="p-3 bg-slate-100 m-3">
                <div className="text-left ml-3 text-rose-600 font-semibold text-sm">
                    *Warning: Service Types and Atributes Once Added cannot be
                    edited
                </div>
                <div className=" rounded-md grid grid-cols-2 px-3 py-2 text-center bg-slate-200 m-3">
                    <div>AttributeName</div>
                    <div>Remove</div>
                </div>
                {formik.values.serviceTypeAttribute.map((attribute, i) => {
                    return (
                        <div
                            key={i.toString()}
                            className=" rounded-md grid grid-cols-2 px-3 py-2 text-center"
                        >
                            <div>{attribute.attributeName}</div>
                            <div>
                                <button
                                    className=""
                                    type="button"
                                    onClick={() => {
                                        const newAttributes =
                                            formik.values.serviceTypeAttribute;
                                        newAttributes.splice(i, 1);
                                        formik.setFieldValue(
                                            'serviceTypeAttribute',
                                            newAttributes
                                        );
                                    }}
                                >
                                    <XCircleIcon className="w-5 h-5 fill-red" />
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function DisplayContentOrderServiceTypes(props: {
    className?: string;
    serviceTypes: GetAllContentOrderServiceTypesQuery | undefined;
}) {
    return (
        <div
            className={`border p-2 border-slate-400 rounded-xl ${props.className}`}
        >
            {props.serviceTypes?.getAllContentOrderServiceTypes?.map(
                (serviceType) => {
                    return (
                        <div
                            key={serviceType?.id}
                            className={`${
                                !serviceType?.disabled
                                    ? 'bg-green-100'
                                    : 'bg-rose-100'
                            } rounded-md pb-4 my-5`}
                        >
                            <div className="p-5 text-center font-bold text-lg text-blue-600 flex flex-row">
                                <span
                                    className="basis-2/3
                                "
                                >
                                    Service Type: {serviceType?.serviceType}
                                </span>
                                <span className="basis-1/3">
                                    <ToggleButton
                                        active={
                                            serviceType?.disabled as boolean
                                        }
                                        serviceTypeID={
                                            serviceType?.id as number
                                        }
                                    />
                                </span>
                            </div>
                            <div className="flex justify-center">
                                <div className="w-[90%] text-center">
                                    <div
                                        className={`${
                                            !serviceType?.disabled
                                                ? 'bg-green-200'
                                                : 'bg-rose-200'
                                        } rounded-md grid grid-cols-2 p-2`}
                                    >
                                        <div className="">Sr. No</div>
                                        <div className="">Attribute Name</div>
                                    </div>
                                    <div className="mt-2 space-y-5">
                                        {serviceType?.ContentOrderServiceTypeAttribute?.map(
                                            (attribute, index) => {
                                                return (
                                                    <div
                                                        key={attribute?.id}
                                                        className="grid grid-cols-2"
                                                    >
                                                        <div>{index + 1}</div>
                                                        <div>
                                                            {
                                                                attribute?.attributeName
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div />
                        </div>
                    );
                }
            )}
        </div>
    );
}

interface InitialValues {
    serviceTypeName: string;
    serviceTypeAttributeName: string;
    serviceTypeAttribute: ContentOrderServiceTypeAttribute[];
}

export function ContentOrderServiceType() {
    const [createServiceType] = useCreateContentOrderServiceTypeMutation();
    const { data: serviceTypes, refetch } =
        useGetAllContentOrderServiceTypesQuery();
    return (
        <Formik
            initialValues={{
                serviceTypeName: '',
                serviceTypeAttribute: [],
                serviceTypeAttributeName: '',
            }}
            onSubmit={async (values) => {
                await createServiceType({
                    variables: {
                        input: {
                            serviceType: values.serviceTypeName,
                            attributes: values.serviceTypeAttribute,
                        },
                    },
                });
                refetch();
            }}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                    <AddContentOrderServiceTypeButton />
                    <div className="space-y-10 lg:flex lg:flex-row lg:space-x-10 lg:space-y-0">
                        <AddContentOrderServiceTypeForm className="lg:basis-1/2" />
                        <DisplayContentOrderServiceTypes
                            serviceTypes={serviceTypes}
                            className="lg:basis-1/2"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}
