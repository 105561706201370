import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisableEmployeeMutation } from '../../api/generated';

export function DisableEmployeeButton(props: { disableRows: number[] }) {
    const [disableEmployee] = useDisableEmployeeMutation();
    const pro = props.disableRows;
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            id: [1016],
        },
        onSubmit: async () => {
            const {data} = await disableEmployee({
                variables: {
                    input: {
                        id: pro,
                    },
                },
            });
            if (data?.disableEmployee?.success) {
                window.location.reload();
                // navigate('/admin/emp-list/');
            }
        },
    });

    const [open, setOpen] = React.useState(false);

    return (
        <>
            <button
                type="button"
                onClick={() => setOpen(true)}
                className="bg-zedex-button-blue text-white create-order align-middle p-1.5 w-48 hover:bg-zedex-button-blue-hover"
            >
                <span className="">Disable Employee</span>
            </button>
            {open ? (
                <div className="absolute">
                    {open ? (
                        <div className="hh justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                <div className="border-2 border-trev m-10 p-5 bg-white rounded-xl space-y-4 w-[30rem]">
                                    <div className="space-x-8">
                                        <div className="pl-32 inline-block text-lg font-bold tracking-wide align-middle">
                                            Disable Employee
                                        </div>
                                        <button
                                            type="button"
                                            className="inline-block pl-24 text-lg"
                                            onClick={() => setOpen(false)}
                                        >
                                            x
                                        </button>
                                    </div>
                                    <div className="text-slate-500 tracking-wide text-justify text-sm pl-8">
                                        Are you sure that you want to disable
                                        the Employees
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <button
                                            type="submit"
                                            onClick={() =>
                                                formik.setFieldValue('id', {
                                                    pro,
                                                })
                                            }
                                            className="text-white w-[50%] p-3 mx-[23%] bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600 rounded-lg"
                                        >
                                            Disable
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}
