import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import {
    ContentOrder,
    useGetContentOrderQuery,
    useUpdateContentOrderMutation,
} from '../../api/generated';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';
import ViewContentOrder from '../../components/viewContentOrder/ViewContentOrder';

export function ViewContentOrderPage() {
    const location = useLocation();
    const { orderID } = location.state;
    const { data, refetch } = useGetContentOrderQuery({
        variables: {
            input: {
                id: orderID,
            },
        },
    });
    const [updateContentOrder] = useUpdateContentOrderMutation();

    return data?.getContentOrder ? (
        <>
            <NavBar activeElement="contentOrders" />
            <ProfileBar title="Client Order" />
            <div className="bg-[#f4f5fa] pt-20">
                <PathBar appendPath={`#${orderID.toString()}`} />
                <div className="ml-[4.3em] mr-1 bg-white py-[1em] px-[0.2em]">
                    <Formik
                        initialValues={{
                            toggleEdit: false,
                            wordCount: 0,
                            pbnName: undefined,
                            projectDescription: undefined,
                            clientSiteUrl: undefined,
                        }}
                        onSubmit={async (values, formik) => {
                            const { data } = await updateContentOrder({
                                variables: {
                                    input: {
                                        id: orderID,
                                        wordCount: values.wordCount,
                                        clientSiteURL: values.clientSiteUrl,
                                        PbnName: values.pbnName,
                                        orderDescription:
                                            values.projectDescription,
                                    },
                                },
                            });

                            if (data?.updateContentOrder?.success) {
                                formik.setFieldValue('toggleEdit', false);
                                refetch();
                            }
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <ViewContentOrder
                                    data={data?.getContentOrder as ContentOrder}
                                />
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    ) : (
        <div className="bg-white text-zedex-button-blue p-5 text-lg">
            Loading .........
        </div>
    );
}
