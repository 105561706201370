import { useLocation } from 'react-router-dom';
import '../../styles/PackageSheet.css';

import Accordion from './Accordion';
import ImageBar from './ImageBar';
import ProfBar from './ProfBar';

export default function EmpProfilePage() {
    const location = useLocation();
    const { EmpID } = location.state;
    return (
        <div>
            <ImageBar />
            <ProfBar empId={EmpID} />
            <Accordion />
        </div>
    );
}
