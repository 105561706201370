import { createPortal } from 'react-dom';
import {
    useGetEmployeeUsingTokenQuery,
    useGetNotificationByIdQuery,
} from '../../api/generated';
import '../../styles/ProfileBar.css';

interface TableRowProps {
    name: string | undefined;
    lname: string | undefined;
    description: string | undefined;
    date: string | undefined;
}

function TableRow(props: TableRowProps) {
    return (
        <tr>
            <td className="px-2">
                <img
                    className="rounded-full shadow-lg w-10 h-10 inline-block "
                    src="/img/nav/profilePic.jpg"
                    alt="Profile_Picture"
                />
            </td>
            <tr className="text-xl font-bold">
                <td className="nameTitle px-2 ">
                    <span>
                        {props.name} {props.lname}
                    </span>{' '}
                    <span className="pl-4">{props.date?.substring(0, 10)}</span>
                </td>
            </tr>
            <tr className="text-xs ">
                <td className="px-2 pb-2 text-trev">{props.description}</td>
            </tr>
        </tr>
    );
}

export default function NotificationPanel() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data } = useGetNotificationByIdQuery({
        variables: {
            input: {
                toEmployeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });
    const length = data?.getNotificationById?.filter(
        (ro) => ro?.status === false
    ).length;

    const notificationContainer = document.createElement('div');
    document.body.appendChild(notificationContainer);
    return createPortal(
        <div className="notification-panel rounded-md">
            <p>You have {length} new notifications</p>
            {length !== 0 ? (
                <ul>
                    {data?.getNotificationById
                        ?.filter((ro) => ro?.status === false)
                        .slice(0, 12)
                        .map((row) => {
                            return (
                                <TableRow
                                    key={row?.id}
                                    name={
                                        row?.fromEmployeeNotification
                                            ?.firstName as string
                                    }
                                    lname={
                                        row?.fromEmployeeNotification
                                            ?.lastName as string
                                    }
                                    description={row?.description as string}
                                    date={row?.date as string}
                                />
                            );
                        })}
                </ul>
            ) : (
                <ul> No new notifications</ul>
            )}
        </div>,
        notificationContainer
    );
}
