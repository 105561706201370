import { useLocation } from 'react-router-dom';
import Navbar from '../../../components/main/Navbar/Navbar';
import PathBar from '../../../components/main/PathBar';
import ProfileBar from '../../../components/main/ProfileBar';
import { ViewTransferDomainRequest } from '../../../components/viewTransferDomain/ViewTransferDomain';

export function ViewTransferDomainRequestPage() {
    const location = useLocation();
    const { requestID } = location.state;
    return (
        <>
            <Navbar activeElement="transferDomain" />
            <ProfileBar title="Domain Transfer" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar appendPath={`#${requestID}`} />
                <div className="ml-[4.3em] mr-1 bg-white py-[1em] px-[0.2em]">
                    <ViewTransferDomainRequest requestID={requestID} />
                </div>
            </div>
        </>
    );
}
