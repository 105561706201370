/* eslint-disable react/function-component-definition */
import { FC, SVGAttributes } from "react";
import { ArrowIcon, CheckedIcon, ChecklistIcon, ContentOrderIcon, CrossIcon, DashboardIcon, InboxIcon, LogoutIcon, StackIcon, TeamIcon, TransferIcon, UnCheckedIcon } from "./svgs/IconsSvg";

export type IconsList = keyof typeof icons;
export interface IconProps {
    icon: IconsList;
    size?: number;
    fill2?: string;
  }

export type IconElement = FC<IconProps & SVGAttributes<SVGElement>>;

const Icon : IconElement = ({icon : name, ...props})=> {
  const I = icons[name]
  return <I icon={name} {...props} />
}


export const icons = {
    dashboard : DashboardIcon,
    team : TeamIcon,
    checklist : ChecklistIcon,
    Stack : StackIcon,
    inbox : InboxIcon,
    transfer : TransferIcon,
    contentorder : ContentOrderIcon,
    logout : LogoutIcon,
    arrow : ArrowIcon,
    checked : CheckedIcon,
    unchecked : UnCheckedIcon,
    cross : CrossIcon
}

export default Icon