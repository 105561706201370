import CalendarDaysIcon from '@heroicons/react/20/solid/CalendarDaysIcon';
import {
    CmsOrders,
    RangeFilterDateAsString,
    RangeFilterInteger,
    SearchFilter,
    useGetEmployeeUsingTokenQuery,
    usePaginatedCmsOrderListQuery,
} from '../../api/generated';

import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { Link } from 'react-router-dom';

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    sortBy: string;
    sortType: string;
    statusFilter: string[];
    filterToggle: boolean;
    search: SearchFilter;
    siteDaReqFilter: RangeFilterInteger;
    siteDrReqFilter: RangeFilterInteger;
    articleCountsFilter: RangeFilterInteger;
    dueDateFilter: RangeFilterDateAsString;
    toggleView: boolean;
}

interface CmsOrderListTableRow {
    id: string;
    orderID: string;
    articleCounts: number;
    clientName: string;
    cmsAdminName: string;
    cmsEmployeeName: string;
    startDate: string;
    dueDate: string;
    clientSiteURL: string;
    keyword: string;
    status: any;
}

export function TableAndGrid() {
    const formik = useFormikContext<InitialValues>();

    const { data: loggedInUser } = useGetEmployeeUsingTokenQuery();

    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data } = usePaginatedCmsOrderListQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                sortBy: formik.values.sortBy,
                sortType: formik.values.sortType,
                statusFilter: formik.values.statusFilter,
                search: {
                    ...formik.values.search,
                },
                articleCountsFilter: {
                    ...formik.values.articleCountsFilter,
                },
                dueDateFilter: {
                    ...formik.values.dueDateFilter,
                },
                forEmployeeID: loggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedCmsOrderList?.totalPages
        );
    }, [data?.paginatedCmsOrderList?.totalPages]);

    useEffect(() => {
        formik.setFieldValue(
            'totalRows',
            data?.paginatedCmsOrderList?.totalRows
        );
    }, [data?.paginatedCmsOrderList?.totalRows]);
    if (data?.paginatedCmsOrderList?.totalPages === 0) {
        return (
            <div className="rounded-lg mt-5 p-5 bg-blue-100 text-blue-500 text-center">
                No Data To be Displayed
            </div>
        );
    }
    if (!formik.values.toggleView) {
        const columns = [
            SelectColumn,
            { key: 'id', name: 'CMS Order ID ' },
            {
                key: 'orderID',
                name: 'Order ID',
                sortable: true,
            },
            {
                key: 'articleCounts',
                name: 'Article Counts',
                sortable: true,
            },
            {
                key: 'clientName',
                name: 'Client Name',
                sortable: true,
            },
            {
                key: 'cmsAdminName',
                name: 'CMS Admin',
                sortable: true,
            },
            {
                key: 'cmsEmployeeName',
                name: 'CMS Employee',
                sortable: true,
            },
            {
                key: 'startDate',
                name: 'Start Date',
                sortable: true,
            },
            {
                key: 'dueDate',
                name: 'Due Date',
                sortable: true,
            },
            {
                key: 'clientSiteURL',
                name: 'Client Site URL',
                sortable: true,
            },
            {
                key: 'keyword',
                name: 'Keyword',
                sortable: true,
            },
            {
                key: 'status',
                name: 'Status',
            },
        ];
        const rows: CmsOrderListTableRow[] = [];

        data?.paginatedCmsOrderList?.cmsOrderList.forEach((cmsOrder) => {
            const seoOrder = cmsOrder?.seoOrder && cmsOrder.seoOrder;
            const order = seoOrder?.order && seoOrder.order;
            const timeDue =
                new Date().getTime() -
                new Date(cmsOrder?.seoOrder?.order?.orderDueDate).getTime();
            const timeDueinDays = timeDue / (1000 * 3600 * 24);
            const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
            rows.push({
                id: cmsOrder?.id as string,
                orderID: order?.id as string,
                articleCounts: order?.ArticleCounts as number,
                clientName: order?.client?.name as string,
                cmsAdminName: `${cmsOrder?.cmsAdmin?.firstName} ${cmsOrder?.cmsAdmin?.lastName}`,
                cmsEmployeeName: `${cmsOrder?.cmsEmp?.firstName} ${cmsOrder?.cmsEmp?.lastName}`,
                startDate: order?.orderStartDate,
                dueDate: order?.orderDueDate,
                clientSiteURL: (seoOrder?.ClientSiteUrl &&
                    seoOrder?.ClientSiteUrl[0]?.clientSiteURL) as string,
                keyword: (seoOrder?.ClientSiteUrl &&
                    seoOrder?.ClientSiteUrl[0]?.keyword) as string,
                status:
                    cmsOrder?.orderStatus === 'Overdue' ? (
                        <div className="font-bold">
                            <span className="block overdue-status p-2 m-1">
                                Overdue
                                <br />
                                <span className="text-[10px] text-red">
                                    Due past{' '}
                                    {timeDueinDays === 0
                                        ? timeDueinWeeks
                                              .toString()
                                              .concat(' Hours')
                                        : timeDueinDays
                                              .toString()
                                              .concat(' Days')}
                                </span>
                            </span>
                        </div>
                    ) : cmsOrder?.orderStatus === 'Cancelled' ? (
                        <div className="font-bold">
                            <span className="block cancelled-status p-2 m-1">
                                Cancelled
                            </span>
                        </div>
                    ) : cmsOrder?.orderStatus === 'Done' ? (
                        <div className="font-bold">
                            <span className="block done-status p-2 m-1">
                                Done
                            </span>
                        </div>
                    ) : cmsOrder?.orderStatus === 'Processing' ? (
                        <div className="font-bold">
                            <span className="block processing-status p-2 m-1">
                                Processing
                                <br />
                                <span className="text-[10px] text-orange">
                                    Due in{' '}
                                    {timeDueinDays === 0
                                        ? timeDueinWeeks
                                              .toString()
                                              .concat(' Hours')
                                        : timeDueinDays
                                              .toString()
                                              .concat(' Days')}
                                </span>
                            </span>
                        </div>
                    ) : cmsOrder?.orderStatus === 'Issue Raised' ? (
                        <div className="font-bold">
                            <span className=" block issue-raised-status p-2 m-1">
                                Issue Raised
                            </span>
                        </div>
                    ) : null,
            });
        });

        return (
            <div className="client-order-table-grid overflow-x-auto overflow-y-auto text-center bg-transparent">
                <DataGrid
                    className="rdg-light fill-grid w-full"
                    columns={columns}
                    rows={rows}
                    defaultColumnOptions={{
                        sortable: true,
                        minWidth: 250,
                        resizable: true,
                    }}
                    rowHeight={60}
                />
            </div>
        );
    }
    return (
        <div className="client-order-table-grid grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 lg:gap-4 overflow-y-auto scrollbar-hide text-center bg-transparent">
            {data?.paginatedCmsOrderList?.cmsOrderList?.map(
                (row) =>
                    row && <GridCard key={row.id?.toString()} cmsOrder={row} />
            )}
        </div>
    );
}

function GridCard(props: { cmsOrder: CmsOrders }) {
    const timeDue =
        new Date(props.cmsOrder.seoOrder?.order?.orderDueDate).getTime() -
        new Date().getTime();
    const timeDueinDays = Math.ceil(timeDue / (1000 * 3600 * 24));
    const timeDueinHours = Math.ceil(timeDue / (1000 * 3600));

    const clientSiteURL =
        props.cmsOrder &&
        props.cmsOrder?.seoOrder &&
        props.cmsOrder?.seoOrder?.ClientSiteUrl &&
        props.cmsOrder.seoOrder.ClientSiteUrl;

    const clientSiteUrlTrimmed =
        clientSiteURL &&
        clientSiteURL[0]?.clientSiteURL &&
        clientSiteURL[0]?.clientSiteURL?.length > 29
            ? clientSiteURL[0]?.clientSiteURL.substring(0, 29).concat('....')
            : clientSiteURL && clientSiteURL[0]?.clientSiteURL;
    return (
        <Link
            to="/cms/employee/order-list/view-order"
            state={{ orderID: props.cmsOrder.id }}
        >
            <div className="grid-order-card p-3 rounded-xl shadow-lg border-2 border-slate-400 hover:bg-blue-100 h-40">
                <div className="border-b-2 h-12 border-slate-400 ">
                    <div className="text-xs font-bold text-left text-zedex-text-blue">
                        #{props.cmsOrder.id}
                    </div>
                    <div className="text-md mb-2">
                        <span className="float-left font-bold">
                            {props?.cmsOrder?.seoOrder?.order?.client?.name}
                        </span>
                        <span
                            className={
                                timeDueinDays > 0
                                    ? 'float-right done-status p-1 text-[10px]'
                                    : 'float-right overdue-status p-1 text-[10px]'
                            }
                        >
                            {timeDueinDays > 0 ? 'Due in' : 'Due Past'}{' '}
                            {timeDueinDays === 0
                                ? Math.abs(timeDueinHours)
                                      .toString()
                                      .concat(' Hours')
                                : Math.abs(timeDueinDays)
                                      .toString()
                                      .concat(' Days')}
                        </span>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="text-xs grid grid-cols-2 gap-3">
                        <span className="text-left">
                            No. of Articles:&nbsp;
                            <span className="font-bold">
                                {
                                    props?.cmsOrder?.seoOrder?.order
                                        ?.ArticleCounts
                                }
                            </span>
                        </span>
                    </div>
                    <div className="text-xs text-left mt-2">
                        <span>Client Site URL:</span>
                        <span>{clientSiteUrlTrimmed}</span>
                    </div>
                    <div className="text-xs grid grid-cols-2 mt-2">
                        <span className="text-left space-x-2">
                            <CalendarDaysIcon
                                color="#3bbf68"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">Start Date: </span>
                            <span className='align-middle block'>{props?.cmsOrder?.seoOrder?.order?.orderStartDate.substring(
                                0,
                                10
                            )}</span>
                        </span>
                        <span className="text-right space-x-2">
                            <CalendarDaysIcon
                                color="red"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">Due Date: </span>
                            <span className='align-middle'>{props?.cmsOrder?.seoOrder?.order?.orderDueDate.substring(
                                0,
                                10
                            )}</span>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}
