import { ContentOrderServiceType } from '../../components/contentServiceType/AddServiceTypeForm';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';

export function CreateContentOrderServiceTypes() {
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Content Order Service Types" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar />
                <div className="ml-[4.3em] bg-white py-[0.2] px-[1em] mr-[0.4em]">
                    <ContentOrderServiceType />
                </div>
            </div>
        </>
    );
}
