import { Employee } from '../../../api/generated';

type EmployeeCardProps = {
    employee: Employee;
};

const EmployeeCard = ({ employee }: EmployeeCardProps) => {
    return (
        <div className="grid grid-cols-2 ">
            <div className="flex flex-row gap-2 justify-start items-center">
                <div className='min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px]' >
                    <img
                        className="rounded-full inline-block "
                        src="/img/nav/profilePic.jpg"
                        alt="Profile_Picture"
                    />
                </div>
                <div>
                    <h4 className="text-sm font-bold text-black60">
                        {employee.firstName} {employee.lastName}
                    </h4>
                    <div className="text-xs text-black20 font-medium">
                        <span>Assigned Task <span className='text-zedex-button-blue'>120</span>/<span>135</span></span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-start">
                <h4 className='text-black20 text-xs font-medium'  >Employee Type : {employee.role?.slice(0,3)}</h4>
                <h4 className='text-black20 text-xs font-medium'  >{employee.email}</h4>
            </div> 
        </div>
    );
};

export default EmployeeCard;
