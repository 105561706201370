import {
    CalendarDaysIcon,
    GlobeAsiaAustraliaIcon,
} from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import {
    useGetContentOrderQuery,
    useUpdateContentOrderStatusMutation,
} from '../../api/generated';
import { InitialValues } from './ContentOrderInitalValues';

function EditIcon(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            color="blue"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
        </svg>
    );
}

export default function ContentOrderHeader(props: {
    id: string;
    clientName: string;
    startDate: string;
    dueDate: string;
    wordCount: number;
    pbnName: string;
    clientSiteURL: string;
    status: string;
}) {
    const formik = useFormikContext<InitialValues>();
    const startDate = new Date().getTime();
    const dueDate = new Date(props.dueDate).getTime();
    const timeDue = startDate - dueDate;
    const timeDueinDays = timeDue / (1000 * 3600 * 24);
    const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
    const [updateContentOrderStatus] = useUpdateContentOrderStatusMutation();
    const trimmedClientSiteURL =
        props.clientSiteURL.length > 34
            ? props.clientSiteURL.substring(0, 34).concat('...')
            : props.clientSiteURL;

    const orderStatuses = ['Assigned', 'In-Progress', 'In-Review', 'Done'];
    const { refetch } = useGetContentOrderQuery({
        variables: {
            input: {
                id: props.id,
            },
        },
    });

    return (
        <>
            <div className="space-x-2 grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="sm:col-span-2 align-middle">
                    <h3 className="text-[#5570F1] text-2xl mx-2 font-bold inline-block">
                        {'#'.concat(props?.id)}/ {props.clientName}
                    </h3>
                    <div
                        className={`font-semibold bg-opacity-30 inline-block p-1 px-3 rounded ${
                            timeDue <= 0
                                ? 'bg-[#519c66] text-[#519c66]'
                                : 'bg-orange-400 text-red'
                        }`}
                    >
                        {timeDue > 0
                            ? `Due Past ${
                                  timeDueinWeeks < 0
                                      ? `${Math.abs(
                                            Math.floor(timeDueinWeeks)
                                        )} weeks and ${Math.abs(
                                            Math.ceil(
                                                Math.floor(timeDueinDays) % 7
                                            )
                                        )} days`
                                      : Math.floor(timeDueinDays) > 0
                                      ? `${Math.abs(
                                            Math.floor(timeDueinDays)
                                        )} days`
                                      : ``
                              }`
                            : timeDue < 0
                            ? `Due After ${
                                  timeDueinWeeks < 0
                                      ? `${Math.abs(
                                            Math.ceil(timeDueinWeeks)
                                        )} weeks and ${Math.abs(
                                            Math.ceil(
                                                Math.ceil(timeDueinDays) % 7
                                            )
                                        )} days`
                                      : Math.ceil(timeDueinDays) < 0
                                      ? `${Math.abs(
                                            Math.ceil(timeDueinDays)
                                        )} days`
                                      : ``
                              }`
                            : 'Due Today'}
                    </div>
                </div>
                <div className="align-middle mx-3 flex flex-row space-x-10 sm:col-span-2 lg:col-span-1">
                    <select
                        className="basis-1/2 bg-blue-500 hover:bg-blue-700 text-white font-semibold border border-blue-700 rounded w-full h-10 p-2 text-center"
                        defaultValue={props.status}
                        onChange={async (event) => {
                            const { data } = await updateContentOrderStatus({
                                variables: {
                                    input: {
                                        id: props.id,
                                        orderStatus: event.target.value,
                                    },
                                },
                            });
                            if (data?.updateContentOrderStatus?.success) {
                                refetch();
                            }
                        }}
                    >
                        <option value={props.status}>{props.status}</option>
                        {orderStatuses
                            .filter((status) => status !== props.status)
                            .map((status) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                    </select>
                    <div className="basis-1/3 flex flex-row space-x-5 px-2">
                        <button
                            type="button"
                            className="self-center basis-1/3"
                            onClick={() =>
                                formik.setFieldValue(
                                    'toggleEdit',
                                    !formik.values.toggleEdit
                                )
                            }
                        >
                            <EditIcon />
                        </button>
                        {formik.values.toggleEdit ? (
                            <button
                                type="submit"
                                className="p-3 basis-2/3 bg-zedex-button-blue hover:bg-zedex-button-blue-hover text-white rounded-md w-40 ml-10"
                            >
                                Save
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className=" grid md:grid-cols-1 lg:grid-cols-3 gap-4 my-2 py-2 bg-slate-50">
                <div className=" bg-white border border-white rounded p-2">
                    <GlobeAsiaAustraliaIcon className="w-10 h-10 rounded inline-block fill-blue-500 align-middle p-1 bg-[#DBDEEE] mr-4" />
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-blue-500 text-xs">
                            Client Site URL
                        </div>
                        {formik.values.toggleEdit ? (
                            <div className="text-blue-500 text-lg font-semibold ">
                                <input
                                    type="text"
                                    className="border"
                                    defaultValue={props.clientSiteURL}
                                    onChange={(event) =>
                                        formik.setFieldValue(
                                            'clientSiteUrl',
                                            event.target.value
                                        )
                                    }
                                />
                            </div>
                        ) : (
                            <div
                                className="text-blue-500 text-lg font-semibold w-full"
                                title={props.clientSiteURL}
                            >
                                {trimmedClientSiteURL}
                            </div>
                        )}
                    </div>
                </div>
                <div className=" bg-white border border-white rounded p-2 grid grid-cols-2 text-center">
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-slate-500">Word Count</div>
                        <div className="text-black text-center">
                            {formik.values.toggleEdit ? (
                                <input
                                    type="number"
                                    className="border p-1 text-center"
                                    defaultValue={props.wordCount}
                                    onChange={(event) =>
                                        formik.setFieldValue(
                                            'wordCount',
                                            Number(event.target.value)
                                        )
                                    }
                                />
                            ) : (
                                props.wordCount
                            )}
                        </div>
                    </div>
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-slate-500">PBN Name</div>
                        <div className="text-black text-center">
                            {formik.values.toggleEdit ? (
                                <input
                                    type="text"
                                    className="border p-1 text-center"
                                    defaultValue={props.pbnName}
                                    onChange={(event) =>
                                        formik.setFieldValue(
                                            'pbnName',
                                            event.target.value
                                        )
                                    }
                                />
                            ) : (
                                props.pbnName
                            )}
                        </div>
                    </div>
                </div>
                <div className=" bg-white border border-white rounded p-2 grid grid-cols-2">
                    <div>
                        <CalendarDaysIcon className="w-8 h-8 rounded inline-block bg-[#DBDEEE] fill-[#3BBF68] align-middle p-1" />
                        <div className="inline-block align-middle ml-2 mr-4 text-sm">
                            <div className="text-blue-500">Start Date</div>
                            <div className="text-blue-500">
                                {props?.startDate.substring(0, 10)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <CalendarDaysIcon className="w-8 h-8 rounded inline-block fill-[#FF3A29] align-middle p-1 bg-[#DBDEEE]" />
                        <div className="inline-block align-middle ml-2 text-sm">
                            <div className="text-blue-500">End Date</div>
                            <div className="text-blue-500">
                                {props.dueDate.substring(0, 10)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
