import { PencilSquareIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import {
    useGetPackageQuery,
    useUpdatePackageMutation
} from '../../api/generated';

import { Link, useParams } from 'react-router-dom';


export default function UpdatePackageModal() {
    const [updatePackage] = useUpdatePackageMutation();

    const { type } = useParams();

    const { data: packages, loading } = useGetPackageQuery({
        variables: {
            getPackageId: Number(type),
        },
    });
    const formik = useFormik({
        initialValues: {
            id: packages?.getPackage?.id as number,
            packageName: '',
            packageDescription: '',
            subPackageName: '',
            requiredDA: '',
            description: '',
            price: 0,
        },
        onSubmit: () => {
            updatePackage({
                variables: {
                    input: {
                        id: formik.values.id,
                        packageName: formik.values.packageName,
                        description: formik.values.description,
                        price: formik.values.price,
                    },
                },
            });
            window.location.href = '/admin/packages';
        },
    });
    const [showModal, setShowModal] = React.useState(false);
    useEffect(() => {
        formik.setFieldValue('id', packages?.getPackage?.id);
        formik.setFieldValue('packageName', packages?.getPackage?.packageName);
        formik.setFieldValue('description', packages?.getPackage?.description);
        formik.setFieldValue('price', packages?.getPackage?.price);
    }, [packages]);
    if (!loading) {
        return (
            <>
                <button
                    className=" inline-block bg-transparent object-right hover:bg-blue float-right mr-3 text-blue-dark font-semibold rounded"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    <PencilSquareIcon className="h-6 w-6 p-1" />
                </button>

                {showModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl rounded-lg">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/* header */}
                                    {/* body */}
                                    <div className="relative h-96 w-96 flex-auto  object-center align-middle rounded-lg">
                                        {/* <CloseButton2 className="float-right" /> */}
                                        <button
                                            type="button"
                                            className="float-right"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                        <div className="bg-white justify-content-center rounded-lg  ">
                                            <div className="flex-1 h-full max-w-md mx-auto rounded-lg shadow-xl align-middle justify-content-center border border-black">

                                                <form
                                                    onSubmit={
                                                        formik.handleSubmit
                                                    }
                                                    className="justify-content-center rounded-lg"
                                                >
                                                    <h1 className="text-2xl px-2 mb-4 mt-4 font-bold text-black-400 text-justify">
                                                        Update SEO Package
                                                    </h1>
                                                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                                        <div className="mb-4 ">
                                                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                                                SEO Package Id :
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="text"
                                                                id="id"
                                                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .id
                                                                }
                                                            // touched={formik.touched.packageName}
                                                            // error={formik.errors.packageName}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                                        <div className="mb-4 ">
                                                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                                                SEO Package Name
                                                                :
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="text"
                                                                id="packageName"
                                                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .packageName
                                                                }
                                                            // touched={formik.touched.packageName}
                                                            // error={formik.errors.packageName}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                                        <div className="mb-4 ">
                                                            <label className="inline relative text-xl font-medium text-black-900 dark:text-gray-300 px-3 py-3 ">
                                                                Price Asked:
                                                            </label>
                                                            <br />
                                                            <input
                                                                type="number"
                                                                id="price"
                                                                className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .price
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center ">
                                                        <Link to="/admin/packages"
                                                            className=" bg-transparent w-3/4 hover:bg-gray-200 text-blue-700 font-semibold py-2 px-4 ml-4 border border-blue-500 rounded mr-4 text-center"
                                                        >
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                    <br />
                                                    <div className="flex flex-col sm:flex-row rounded-md object-center justify-center">
                                                        <button
                                                            className=" w-3/4  bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4  mx-4 border border-blue-700 rounded "
                                                            type="submit"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                    <br />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* footer */}
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                    </>
                ) : null}
            </>
        );
    }
    return null;
}
