import { useFormikContext } from 'formik';
import { InitialValues } from './ContentOrderInitalValues';

export default function InstructionAccordion(props: {
    projectDescription: string;
}) {
    const formik = useFormikContext<InitialValues>();
    return (
        <div className="w-full">
            <div className="inline-block object-left items-left w-full">
                {formik.values.toggleEdit ? (
                    <>
                        <label
                            htmlFor="panel-1"
                            className=" block bg-slate-50 text-black p-4 shadow border border-white rounded"
                        >
                            Project Description
                        </label>
                        <textarea
                            className="border p-1 w-full block"
                            defaultValue={props.projectDescription}
                            onLoad={() =>
                                formik.setFieldValue(
                                    'projectDescription',
                                    props.projectDescription
                                )
                            }
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'projectDescription',
                                    event.target.value
                                )
                            }
                        />
                    </>
                ) : (
                    <>
                        <input
                            type="checkbox"
                            name="panel"
                            id="panel-1"
                            className="hidden"
                        />
                        <label
                            htmlFor="panel-1"
                            className="plus relative block bg-slate-50 text-black p-4 shadow border border-white rounded"
                        >
                            Project Description
                        </label>
                        <div className="accordion__content overflow-hidden bg-grey-lighter">
                            <p className="accordion__body p-4" id="panel1">
                                {props.projectDescription}
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
