export function exportCSV(data: string[][], header: string[]) {
    // Convert data to CSV format
    const csvHeader = header.join(',').concat('\n');
    const csvData = data.map((row) => row.join(',')).join('\n');

    // Create a Blob object with the CSV data
    const blob = new Blob([csvHeader, csvData], {
        type: 'text/csv;charset=utf-8;',
    });

    // Create a URL object from the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');

    // Append the link element to the DOM
    document.body.appendChild(link);

    // Click the link to trigger the download
    link.click();

    // Cleanup the URL object
    URL.revokeObjectURL(url);
}
