export const Paths = new Map();

Paths.set('outreach-sheet', 'Outreach Sheet');
Paths.set('emp-list', 'Employee List');
Paths.set('packages', 'Packages');
Paths.set('order-list', 'Orders');
Paths.set('add-domain', 'Add Domain');
Paths.set('create-order', 'Create Order');
Paths.set('create-content-order', 'Create Content Order');
Paths.set('content-order-list', 'Content Order List');
Paths.set('content-order-service-types', 'Content Order Service Types');
Paths.set('categories', 'Domain Categories');
Paths.set('transfer-domain-list', 'Domain Transfer');
Paths.set('potential-sheet', 'Potential Sites');
Paths.set('client-selected-sites', 'Client Selected Site');
