import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    useGetPackageQuery,
    useUpdatePackageMutation
} from '../../api/generated';
import { SeoPackageSchema } from '../../form/SeoPackageSchema';
import InputComponent from '../main/Input';
import NavBar from '../main/Navbar/Navbar';
import ProfileBar from '../main/ProfileBar';


export default function UpdatePackage() {
    const [updatePackage] = useUpdatePackageMutation();

    const { type } = useParams();

    const { data: packages, loading } = useGetPackageQuery({
        variables: {
            getPackageId: Number(type),
        },
    });
    const formik = useFormik({
        initialValues: {
            id: packages?.getPackage?.id as number,
            packageName: '',
            description: '',
            subPackageName: '',
            requiredDA: '',
            // description: '',
            price: 0,
        },
        validationSchema: SeoPackageSchema,
        onSubmit: () => {
            updatePackage({
                variables: {
                    input: {
                        id: formik.values.id,
                        packageName: formik.values.packageName,
                        description: formik.values.description,
                        // subPackageName: formik.values.subPackageName,
                        // requiredDA: formik.values.requiredDA,
                        // description: formik.values.description,
                        price: formik.values.price,
                    },
                },
            });
            window.location.href = '/admin/packages';
        },
    });

    useEffect(() => {
        formik.setFieldValue('id', packages?.getPackage?.id);
        formik.setFieldValue('packageName', packages?.getPackage?.packageName);
        formik.setFieldValue('description', packages?.getPackage?.description);
        formik.setFieldValue('price', packages?.getPackage?.price);
    }, [packages]);

    if (!loading) {
        return (
            <div className="bg-[#f4f5fa]">
                <NavBar activeElement="inbox" />
                <ProfileBar title="Packages" />
                <div className="justify-content-center rounded-lg  pt-24">
                    <div className="flex-1 h-auto max-w-md mx-auto rounded-lg shadow-xl align-middle justify-content-center border border-black">
                        <Link to="/admin/packages" type="button"
                            className="bg-white rounded-md  float-right relative top-2 right-100 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        >
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>

                        </Link>
                        <form
                            onSubmit={formik.handleSubmit}
                            className="rounded-lg"
                        >
                            <h1 className="text-2xl p-2 mb-4 mt-4 font-bold text-black-400 text-center">
                                Update SEO Package
                            </h1>
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mb-2">
                                <div className="mb-4 ">
                                    <label className="inline-block relative text-lg font-medium text-black-900 dark:text-gray-300 px-3 pb-1">
                                        SEO Package Id
                                    </label>
                                    <br />
                                    <InputComponent
                                        inputType="text"
                                        inputPlaceholder="SEO Package Id"
                                        name="id"
                                        id="id"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.id}
                                        touched={formik.touched.id}
                                        error={formik.errors.id}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mb-2">
                                <div className="mb-4 ">
                                    <label className="inline-block relative text-lg font-medium text-black-900 dark:text-gray-300 px-3 pb-1">
                                        SEO Package Name
                                    </label>
                                    <br />
                                    <InputComponent
                                        inputType="text"
                                        id="packageName"
                                        name="packageName"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.packageName}
                                        touched={formik.touched.packageName}
                                        error={formik.errors.packageName}
                                        inputPlaceholder="SEO Package Name"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mb-2">
                                <div className="mb-4 ">
                                    <label className="inline-block relative text-lg font-medium text-black-900 dark:text-gray-300 px-3 pb-1">
                                        SEO Package Description
                                    </label>
                                    <br />
                                    <InputComponent
                                        inputType="text"
                                        inputPlaceholder="Seo Package Description"
                                        name="description"
                                        touched={formik.touched.description}
                                        error={formik.errors.description}
                                        id="description"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mb-2">
                                <div className="mb-4 ">
                                    <label className="inline-block relative text-lg font-medium text-black-900 dark:text-gray-300 px-3 pb-1">
                                        Price Asked
                                    </label>
                                    <br />
                                    <InputComponent
                                        inputType="number"
                                        inputPlaceholder="Price"
                                        name="price"
                                        touched={formik.touched.price}
                                        error={formik.errors.price}
                                        id="price"
                                        className="form-control w-80 px-3 py-3 ml-2 mr-2 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:ring-blue-500 shadow-lg"
                                        onChange={formik.handleChange}
                                        value={formik.values.price}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center mt-3">
                                <Link to="/admin/packages"
                                    className=" bg-transparent w-3/4 hover:bg-gray-200 text-blue-700 font-semibold py-2 px-4 ml-4 border border-blue-500 rounded mr-4 text-center"
                                >
                                    Cancel
                                </Link>
                            </div>
                            <br />
                            <div className="flex flex-col sm:flex-row rounded-md object-center justify-center">
                                <button
                                    className=" w-3/4  bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4  mx-4 border border-blue-700 rounded "
                                    type="submit"
                                >
                                    Save
                                </button>
                            </div>
                            <br />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}
