import * as Yup from 'yup';
import { LENGTH_MSG, REQUIREDS } from '../components/main/Schema';

const packageReg = /^[a-zA-Z_-]+$/;
export const SeoPackageSchema = Yup.object().shape({
    packageName: Yup.string()
        .required(REQUIREDS)
        .matches(packageReg, "Package names should only contain letters, underscores, and hyphens.")
        .min(5, LENGTH_MSG(5, 'Package Name')),
    description: Yup.string()
        .required(REQUIREDS)
        .min(10, LENGTH_MSG(10, 'Description')),
    price: Yup.number()
        .required(REQUIREDS)
        .moreThan(1, LENGTH_MSG('$1', 'Price')),
});
