interface order {
    orderName: string;
}
export default function Panel(orderName: order) {
    return (
        <div className="bg-white rounded-md ">
            <details>
                <summary className="question py-3 px-4 cursor-pointer select-none w-full">
                    {orderName.orderName}
                </summary>
                <p className="pt-1 pb-3 px-4 flex flex-row">
                    Of course. Its yours to use wherever and whenever you like.
                    mvmnbvknkkjfnjnbngjfgjkjnbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb
                </p>
            </details>
        </div>
    );
}
