import {
    useGetEmployeeUsingTokenQuery,
    useGetNotificationByIdQuery,
    useUpdateNotificationMutation,
} from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/notification.css';

import { useEffect, useState } from 'react';

interface TableRowProps {
    notifKey: number | undefined | null;
    name: string | undefined;
    lname: string | undefined;
    description: string | undefined;
    date: string | undefined;
    refetch: any;
    status: boolean | undefined | null;
}

function TableRow(props: TableRowProps) {
    const [updateNotification] = useUpdateNotificationMutation();

    return (
        <tr>
            <td className="px-2 ">
                <img
                    className="rounded-full shadow-lg w-10 h-10 inline-block "
                    src="/img/nav/profilePic.jpg"
                    alt="Profile_Picture"
                />
            </td>
            <tr className="text-xl font-bold">
                <td className="nameTitle px-2 ">
                    <span>
                        {props.name} {props.lname}
                    </span>{' '}
                    <span className="pl-4">{props.date?.substring(0, 10)}</span>
                </td>
            </tr>
            <tr className="text-xs ">
                <td className="px-2 pb-2 text-trev">{props.description}</td>
                {props.status === false ? (
                    <td className="px-2 pb-2 text-trev">
                        <button
                            type="button"
                            onClick={async () => {
                                const { data } = await updateNotification({
                                    variables: {
                                        input: {
                                            id: props.notifKey,
                                        },
                                    },
                                });
                                if (data?.updateNotification?.success) {
                                    props.refetch();
                                }
                            }}
                        >
                            mark as read
                        </button>
                    </td>
                ) : null}
            </tr>
        </tr>
    );
}

export default function Notif() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const [cardShow, setCardShow] = useState<boolean>(false);

    useEffect(() => {
        setCardShow(true);
    }, []);

    const { data, refetch } = useGetNotificationByIdQuery({
        variables: {
            input: {
                toEmployeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });
    return (
        <div
            className={`rounded-lg bg-white shadow-md hover:shadow-lg transition-all ease-in-out duration-300 overflow-hidden ${
                cardShow ? 'scale-100 translate-x-0' : 'scale-0 -translate-x-96'
            }`}
        >
            <div className="px-6 py-4">
                <div className=" font-bold text-xl mb-2">Notifications</div>
            </div>
            <tbody>
                {data?.getNotificationById?.slice(0, 3).map((row) => {
                    return (
                        <TableRow
                            key={row?.id}
                            notifKey={row?.id}
                            name={
                                row?.fromEmployeeNotification
                                    ?.firstName as string
                            }
                            lname={
                                row?.fromEmployeeNotification
                                    ?.lastName as string
                            }
                            description={row?.description as string}
                            date={row?.date as string}
                            refetch={refetch}
                            status={row?.status}
                        />
                    );
                })}
            </tbody>
        </div>
    );
}
