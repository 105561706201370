/* eslint-disable react/function-component-definition */
import { IconElement } from '..';

export const DashboardIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={size}
            {...props}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M5.81537 10.6182C7.03556 10.6182 8.01344 11.6057 8.01344 12.8345V15.7847C8.01344 17.0049 7.03556 18.0001 5.81537 18.0001H2.89039C1.67886 18.0001 0.692322 17.0049 0.692322 15.7847V12.8345C0.692322 11.6057 1.67886 10.6182 2.89039 10.6182H5.81537ZM15.8019 10.6182C17.0135 10.6182 18 11.6057 18 12.8345V15.7847C18 17.0049 17.0135 18.0001 15.8019 18.0001H12.877C11.6568 18.0001 10.6789 17.0049 10.6789 15.7847V12.8345C10.6789 11.6057 11.6568 10.6182 12.877 10.6182H15.8019ZM5.81537 0.692383C7.03556 0.692383 8.01344 1.68759 8.01344 2.90867V5.85881C8.01344 7.08767 7.03556 8.07422 5.81537 8.07422H2.89039C1.67886 8.07422 0.692322 7.08767 0.692322 5.85881V2.90867C0.692322 1.68759 1.67886 0.692383 2.89039 0.692383H5.81537ZM15.8019 0.692383C17.0135 0.692383 18 1.68759 18 2.90867V5.85881C18 7.08767 17.0135 8.07422 15.8019 8.07422H12.877C11.6568 8.07422 10.6789 7.08767 10.6789 5.85881V2.90867C10.6789 1.68759 11.6568 0.692383 12.877 0.692383H15.8019Z"
                fill={fill}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_167_504"
                    x1="0.692322"
                    y1="0.991939"
                    x2="21.1942"
                    y2="12.1098"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const TeamIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={size}
            fill="none"
            viewBox="0 0 20 20"
            {...props}
        >
            <path
                d="M5.56664 7.48196C5.35499 7.48196 5.14089 7.48196 4.92433 7.48196C4.04781 7.45028 3.17413 7.59908 2.35752 7.91912C1.5409 8.23917 0.798773 8.72362 0.177187 9.34243L0 9.54915V15.6621H3.01218V12.1922L3.41824 11.7345L3.60281 11.5204C4.56399 10.5329 5.76064 9.80628 7.08011 9.40887C6.41949 8.9062 5.89848 8.24286 5.56664 7.48196Z"
                fill={fill}
            />
            <path
                d="M19.8448 9.32021C19.2233 8.7014 18.4811 8.21695 17.6645 7.89691C16.8479 7.57686 15.9742 7.42806 15.0977 7.45974C14.8289 7.46051 14.5603 7.47529 14.293 7.50404C13.9549 8.21789 13.4481 8.83869 12.8164 9.31283C14.225 9.70253 15.5011 10.4682 16.5078 11.5277L16.6924 11.7344L17.0911 12.1921V15.6694H19.9999V9.52693L19.8448 9.32021Z"
                fill={fill}
            />
            <path
                d="M4.90195 6.04232H5.13082C5.02447 5.12924 5.18469 4.20491 5.59211 3.38088C5.99954 2.55686 6.63677 1.86837 7.42687 1.39853C7.14046 0.960991 6.74533 0.60533 6.28017 0.366378C5.81502 0.127426 5.29577 0.013375 4.77329 0.0353929C4.25081 0.0574108 3.743 0.214743 3.2996 0.491983C2.85619 0.769223 2.49239 1.15687 2.24381 1.61695C1.99523 2.07704 1.8704 2.5938 1.88155 3.11662C1.89269 3.63945 2.03942 4.15042 2.30738 4.5995C2.57533 5.04858 2.95532 5.42037 3.41014 5.67846C3.86495 5.93656 4.379 6.07211 4.90195 6.07185V6.04232Z"
                fill={fill}
            />
            <path
                d="M14.7434 5.48852C14.7524 5.65821 14.7524 5.82825 14.7434 5.99794C14.8851 6.0204 15.0282 6.03273 15.1716 6.03485H15.3119C15.8325 6.00709 16.3371 5.84499 16.7765 5.56433C17.2159 5.28367 17.5752 4.89402 17.8193 4.43331C18.0634 3.9726 18.184 3.45653 18.1695 2.93535C18.155 2.41417 18.0057 1.90563 17.7363 1.45925C17.4669 1.01287 17.0865 0.643855 16.6321 0.388133C16.1777 0.132412 15.6649 -0.00130204 15.1435 9.5569e-06C14.6221 0.00132116 14.11 0.137614 13.6569 0.395618C13.2038 0.653622 12.8253 1.02455 12.5581 1.47228C13.2263 1.90855 13.7758 2.50388 14.1572 3.20485C14.5386 3.90582 14.74 4.69051 14.7434 5.48852Z"
                fill={fill}
            />
            <path
                d="M9.90021 8.79603C11.7228 8.79603 13.2003 7.31852 13.2003 5.49591C13.2003 3.67331 11.7228 2.1958 9.90021 2.1958C8.07761 2.1958 6.6001 3.67331 6.6001 5.49591C6.6001 7.31852 8.07761 8.79603 9.90021 8.79603Z"
                fill={fill}
            />
            <path
                d="M10.0773 10.5532C9.11314 10.5143 8.15107 10.6709 7.24897 11.0134C6.34688 11.356 5.52342 11.8775 4.82812 12.5466L4.64355 12.7533V17.4266C4.64644 17.5789 4.67928 17.729 4.74021 17.8686C4.80113 18.0081 4.88895 18.1342 4.99865 18.2398C5.10834 18.3454 5.23776 18.4283 5.37952 18.4839C5.52127 18.5394 5.67258 18.5665 5.8248 18.5636H14.3076C14.4599 18.5665 14.6112 18.5394 14.7529 18.4839C14.8947 18.4283 15.0241 18.3454 15.1338 18.2398C15.2435 18.1342 15.3313 18.0081 15.3922 17.8686C15.4532 17.729 15.486 17.5789 15.4889 17.4266V12.7681L15.3117 12.5466C14.6209 11.8754 13.8003 11.3525 12.9002 11.0097C12.0002 10.6669 11.0395 10.5116 10.0773 10.5532Z"
                fill={fill}
            />
        </svg>
    );
};

export const ChecklistIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <g>
                <path
                    d="M2.42309 1.36914C1.46251 1.36914 0.692322 2.2309 0.692322 3.30568V7.17876C0.692322 8.25353 1.46251 9.11529 2.42309 9.11529H5.88463C6.84521 9.11529 7.6154 8.25353 7.6154 7.17876V3.30568C7.6154 2.2309 6.84521 1.36914 5.88463 1.36914M6.05771 2.82154L6.97501 3.83823L3.52213 7.66289L1.33271 5.1938L2.25867 4.17712L3.53078 5.61984M2.42309 11.0518C1.46251 11.0518 0.692322 11.9136 0.692322 12.9884V16.8614C0.692322 17.9362 1.46251 18.798 2.42309 18.798H5.88463C6.84521 18.798 7.6154 17.9362 7.6154 16.8614V12.9884C7.6154 11.9136 6.84521 11.0518 5.88463 11.0518M2.42309 12.9884H5.88463V16.8614H2.42309M9.34617 3.30568H18V5.24222H9.34617M9.34617 16.8614V14.9249H18V16.8614M9.34617 9.11529H18V11.0518H9.34617V9.11529Z"
                    fill={fill}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_167_506"
                    x1="0.692322"
                    y1="1.67079"
                    x2="21.259"
                    y2="12.7463"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const StackIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <g>
                <path
                    d="M17.6573 12.6692L16.6186 12.1752C16.4744 12.0881 16.2724 12.0881 16.1282 12.1752L9.92497 15.1387C9.57875 15.3131 9.14597 15.3131 8.79974 15.1387L2.5677 12.1752C2.42344 12.0881 2.22148 12.0881 2.07722 12.1752L1.03855 12.6692C0.576914 12.9016 0.576914 13.5117 1.03855 13.7442L8.77089 17.434C9.11711 17.6083 9.54989 17.6083 9.89612 17.434L17.6285 13.7442C18.1189 13.5117 18.1189 12.8725 17.6573 12.6692V12.6692Z"
                    fill={fill}
                />
                <path
                    d="M17.6573 8.60152L16.5898 8.10761C16.4455 8.02045 16.2436 8.02045 16.0993 8.10761L9.92497 11.0711C9.57875 11.2454 9.14597 11.2454 8.79974 11.0711L2.59656 8.10761C2.4523 8.02045 2.25033 8.02045 2.10607 8.10761L1.03855 8.60152C0.576914 8.83396 0.576914 9.44409 1.03855 9.67652L8.77089 13.3954C9.11711 13.5698 9.54989 13.5698 9.89612 13.3954L17.6285 9.70558C18.1189 9.47315 18.1189 8.83396 17.6573 8.60152V8.60152Z"
                    fill={fill}
                />
                <path
                    d="M1.03855 5.60898L8.77089 9.29883C9.11711 9.47316 9.54989 9.47316 9.89612 9.29883L17.6285 5.60898C18.0901 5.37654 18.0901 4.76641 17.6285 4.53398L9.89612 0.844122C9.54989 0.669798 9.11711 0.669798 8.77089 0.844122L1.03855 4.56303C0.576914 4.76641 0.576914 5.4056 1.03855 5.60898V5.60898Z"
                    fill={fill}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_167_494"
                    x1="0.692322"
                    y1="12.2043"
                    x2="7.39124"
                    y2="23.7305"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_167_494"
                    x1="0.692322"
                    y1="8.13715"
                    x2="7.44457"
                    y2="19.6936"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_167_494"
                    x1="0.692322"
                    y1="0.864236"
                    x2="12.9792"
                    y2="14.0755"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const InboxIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            {...props}
            viewBox="0 0 20 20"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.45222 5.19749C7.24266 4.99509 6.962 4.8831 6.67067 4.88563C6.37934 4.88816 6.10066 5.00502 5.89465 5.21103C5.68864 5.41703 5.57178 5.69572 5.56925 5.98705C5.56672 6.27837 5.67871 6.55904 5.88111 6.7686L9.21445 10.1019C9.42281 10.3102 9.70537 10.4273 10 10.4273C10.2946 10.4273 10.5772 10.3102 10.7856 10.1019L14.1189 6.7686C14.3213 6.55904 14.4333 6.27837 14.4307 5.98705C14.4282 5.69572 14.3114 5.41703 14.1054 5.21103C13.8993 5.00502 13.6207 4.88816 13.3293 4.88563C13.038 4.8831 12.7573 4.99509 12.5478 5.19749L11.1111 6.63416V0.42749H16.6667C17.5507 0.42749 18.3986 0.77868 19.0237 1.4038C19.6488 2.02892 20 2.87677 20 3.76082V11.5386H14.4444C13.8551 11.5386 13.2898 11.7727 12.8731 12.1895C12.4563 12.6062 12.2222 13.1715 12.2222 13.7608H7.77778C7.77778 13.1715 7.54365 12.6062 7.1269 12.1895C6.71016 11.7727 6.14493 11.5386 5.55556 11.5386H0V3.76082C0 2.87677 0.35119 2.02892 0.976311 1.4038C1.60143 0.77868 2.44928 0.42749 3.33333 0.42749H8.88889V6.63416L7.45222 5.19749ZM0 17.0942C0 17.9782 0.35119 18.8261 0.976311 19.4512C1.60143 20.0763 2.44928 20.4275 3.33333 20.4275H16.6667C17.5507 20.4275 18.3986 20.0763 19.0237 19.4512C19.6488 18.8261 20 17.9782 20 17.0942V13.7608H14.4444C14.4444 14.3502 14.2103 14.9154 13.7936 15.3322C13.3768 15.7489 12.8116 15.983 12.2222 15.983H7.77778C7.18841 15.983 6.62318 15.7489 6.20643 15.3322C5.78968 14.9154 5.55556 14.3502 5.55556 13.7608H0V17.0942Z"
                fill={fill}
            />
        </svg>
    );
};

export const TransferIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height={size}
            width={size}
            {...props}
            viewBox="0 0 20 20"
        >
            <path
                d="M19.995 11.6627C19.8758 11.9349 19.6839 12.1059 19.3703 12.1059C19.0152 12.1033 18.7482 11.8623 18.7353 11.5046C18.7016 10.574 18.4709 9.70831 17.911 8.95144C17.1049 7.8602 16.0292 7.23812 14.6658 7.14999C13.4709 7.07223 12.2734 7.13185 11.0759 7.11889C10.2438 7.11111 9.40919 7.12148 8.57715 7.1137C8.41645 7.1137 8.36201 7.16554 8.36461 7.32625C8.36979 8.1194 8.36979 8.90997 8.3672 9.70313C8.36461 10.1256 8.16761 10.3796 7.79436 10.4444C7.56886 10.4859 7.38742 10.39 7.2293 10.2423C7.00121 10.0297 6.7757 9.81458 6.55019 9.59944C5.29307 8.40193 4.04631 7.19665 2.77622 6.01209C2.41074 5.67254 2.40815 5.21894 2.77881 4.87161C4.27181 3.47192 5.74667 2.05149 7.2293 0.638844C7.43666 0.441851 7.67254 0.369275 7.9447 0.478139C8.24278 0.597372 8.36461 0.835838 8.36461 1.1417C8.3672 1.92189 8.36461 2.6995 8.3672 3.47969C8.3672 3.74667 8.3672 3.74667 8.63936 3.74667C10.2412 3.74667 11.8405 3.74408 13.4424 3.74667C15.0572 3.75186 16.5009 4.24175 17.7322 5.2967C19.01 6.39053 19.7643 7.77467 19.9742 9.44652C19.9794 9.4854 19.9924 9.52168 20.0002 9.56056C19.995 10.2604 19.995 10.9628 19.995 11.6627Z"
                fill={fill}
            />
            <path
                d="M0 7.53106C0.114049 7.26667 0.298082 7.09301 0.60394 7.09041C0.982374 7.08523 1.24676 7.32888 1.25972 7.70991C1.29601 8.74153 1.5915 9.67984 2.26024 10.4756C3.0534 11.4217 4.06687 11.9738 5.30845 12.0438C6.89995 12.1319 8.49663 12.0619 10.0881 12.0775C10.5314 12.0826 10.972 12.0723 11.4152 12.08C11.5759 12.0826 11.6304 12.0308 11.6278 11.8701C11.6226 11.0718 11.6226 10.2734 11.6278 9.47507C11.6304 9.06812 11.8326 8.81929 12.198 8.7519C12.4339 8.70783 12.6153 8.8141 12.7786 8.96444C13.0067 9.17699 13.2322 9.39212 13.4577 9.60726C14.7045 10.7944 15.9435 11.9919 17.2032 13.1687C17.5894 13.529 17.592 13.9748 17.2032 14.3377C15.7206 15.7296 14.2561 17.1397 12.7838 18.5445C12.5739 18.7441 12.3354 18.8322 12.0529 18.7208C11.7548 18.6016 11.633 18.3631 11.633 18.0598C11.6304 17.2744 11.6252 16.4891 11.6356 15.7037C11.6382 15.5041 11.5759 15.4497 11.379 15.4497C9.75894 15.4574 8.13893 15.46 6.51892 15.4523C4.94038 15.4419 3.52514 14.9572 2.31208 13.9411C1.02385 12.8602 0.261794 11.4787 0.0285122 9.81203C0.0233281 9.76278 0.0103681 9.71872 0 9.67465C0 8.96185 0 8.24645 0 7.53106Z"
                fill={fill}
            />
        </svg>
    );
};

export const ContentOrderIcon: IconElement = ({
    fill,
    size = 20,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={fill}
            height={size}
            width={size}
            {...props}
        >
            <path
                fill={fill}
                fillRule="evenodd"
                d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 003 3h15a3 3 0 01-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125zM12 9.75a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H12zm-.75-2.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM6 12.75a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5H6zm-.75 3.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM6 6.75a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-3A.75.75 0 009 6.75H6z"
                clipRule="evenodd"
            />
            <path
                fill={fill}
                d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 01-3 0V6.75z"
            />
        </svg>
    );
};

export const LogoutIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={size}
            {...props}
            viewBox="0 0 18 14"
            fill="none"
        >
            <path
                d="M17.0682 7.22853L11.2986 13.0384C10.7835 13.5572 9.8906 13.1941 9.8906 12.4505V9.13058H5.22004C4.76329 9.13058 4.39582 8.76055 4.39582 8.3006V4.98065C4.39582 4.5207 4.76329 4.15067 5.22004 4.15067H9.8906V0.830722C9.8906 0.0906507 10.7801 -0.275927 11.2986 0.242815L17.0682 6.05272C17.3875 6.3778 17.3875 6.90345 17.0682 7.22853ZM6.59373 12.8655V11.4822C6.59373 11.254 6.40828 11.0672 6.18162 11.0672H3.29687C2.68901 11.0672 2.19791 10.5727 2.19791 9.96057V3.32068C2.19791 2.70857 2.68901 2.21403 3.29687 2.21403H6.18162C6.40828 2.21403 6.59373 2.02729 6.59373 1.79904V0.415729C6.59373 0.187482 6.40828 0.000735526 6.18162 0.000735526H3.29687C1.47672 0.000735526 0 1.48779 0 3.32068V9.96057C0 11.7935 1.47672 13.2805 3.29687 13.2805H6.18162C6.40828 13.2805 6.59373 13.0938 6.59373 12.8655Z"
                fill={fill}
            />
        </svg>
    );
};

export const ArrowIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 21 18"
            fill="none"
            {...props}
        >
            <path
                d="M12.2031 17.5598L19.7331 10.0638C20.2991 9.49983 20.2991 8.50183 19.7331 7.93583L12.2031 0.439831C11.6151 -0.144169 10.6651 -0.142171 10.0811 0.445829C9.4971 1.03383 9.4971 1.98183 10.0851 2.56783L15.0391 7.50183H2.3291C1.4991 7.50183 0.829102 8.17383 0.829102 9.00183C0.829102 9.82983 1.4991 10.5018 2.3291 10.5018H15.0391L10.0851 15.4338C9.7911 15.7278 9.6451 16.1118 9.6451 16.4978C9.6451 16.8798 9.7911 17.2638 10.0811 17.5558C10.6651 18.1418 11.6151 18.1438 12.2031 17.5598Z"
                fill={fill}
            />
        </svg>
    );
};

export const CheckedIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 24"
            fill="none"
            {...props}
        >
            <path
                d="M19.0438 3H5.01145C4.4798 3 3.96991 3.21071 3.59398 3.58579C3.21804 3.96086 3.00684 4.46957 3.00684 5V19C3.00684 19.5304 3.21804 20.0391 3.59398 20.4142C3.96991 20.7893 4.4798 21 5.01145 21H19.0438C19.5754 21 20.0853 20.7893 20.4613 20.4142C20.8372 20.0391 21.0484 19.5304 21.0484 19V5C21.0484 4.46957 20.8372 3.96086 20.4613 3.58579C20.0853 3.21071 19.5754 3 19.0438 3ZM10.023 17L5.01145 12L6.42471 10.59L10.023 14.17L17.6305 6.58L19.0438 8L10.023 17Z"
                fill={fill}
            />
        </svg>
    );
};

export const UnCheckedIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 24"
            fill="none"
            {...props}
        >
            <path
                d="M19.0438 5V19H5.01145V5H19.0438ZM19.0438 3H5.01145C3.90891 3 3.00684 3.9 3.00684 5V19C3.00684 20.1 3.90891 21 5.01145 21H19.0438C20.1463 21 21.0484 20.1 21.0484 19V5C21.0484 3.9 20.1463 3 19.0438 3Z"
                fill={fill}
            />
        </svg>
    );
};

export const CrossIcon: IconElement = ({ fill, size = 20, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 24"
            fill="none"
            {...props}
        >
            <path
                d="M18.7555 7.05L17.7031 6L12.7416 10.95L7.78021 6L6.72778 7.05L11.6892 12L6.72778 16.95L7.78021 18L12.7416 13.05L17.7031 18L18.7555 16.95L13.7941 12L18.7555 7.05Z"
                fill={fill}
            />
        </svg>
    );
};
