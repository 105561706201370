import Panel from './Panel';

export default function Accordion() {
    return (
        <div className="accordion flex flex-col acc-bar">
            <div className="pl-2 font-bold text-lg">Task List</div>
            <Panel orderName="order 1" />
            <br />
            <Panel orderName="order 2" />
            <br />
            <Panel orderName="order 3" />
            <br />
            <Panel orderName="order 4" />
            <br />
            <Panel orderName="order 5" />
            <br />
        </div>
    );
}
