import '../../styles/welcome.css';

import Dashboard from '../../components/dashboard/Dashboard';
import NavBar from '../../components/main/Navbar/Navbar';
import ProfileBar from '../../components/main/ProfileBar';

export default function Home() {
    return (
        <>
            <NavBar activeElement="dashboard" />
            <ProfileBar title="Admin Dashboard" />
            <div className="backg">
                <Dashboard />
            </div>
        </>
    );
}
