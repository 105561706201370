import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetEmployeeUsingTokenQuery } from '../../api/generated';
import '../../styles/ProfileBar.css';
import NotificationPanel from './NotificationPanel';

export default function ProfileBar(props: { title: string }) {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    const [showPanel, setShowPanel] = useState(false);

    const handleClick = () => {
        setShowPanel(!showPanel);
    };
    useEffect(() => {
        function handleKeyDown(event: { key: string }) {
            if (event.key === 'Escape') {
                setShowPanel(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="h-20 w-full fixed bg-white z-30">
            <div className="headerTitle ml-24 mt-6">
                <span className="tracking-wide mt-0 mb-2">{props.title}</span>
            </div>
            <div className="profile-pic float-right rounded-lg text-xs w-[12rem] bg-blue-100 py-2">
                <div className="mx-2 w-full">

                    <Link to="/profile-page" className="space-x-4">
                        <img
                            className="rounded-full shadow-lg w-10 h-10 inline-block"
                            src="/img/nav/profilePic.jpg"
                            alt="Profile_Picture"
                        />
                        <span className="text-sm">
                            {LoggedInUser?.getEmployeeUsingToken?.firstName?.concat(
                                ` ${LoggedInUser.getEmployeeUsingToken.lastName?.toString()}` as string
                            )}
                        </span>
                    </Link>

                </div>
            </div>
            <div className="notification float-right">
                <button type="button" onClick={handleClick}>
                    <img
                        src="/img/nav/notify.svg"
                        className="notification-icon object-scale-down"
                        alt=""
                    />
                </button>
                {showPanel && <NotificationPanel />}
            </div>
        </div>
    );
}
