import '../../styles/card.css';
import '../../styles/ProfileBar.css';

import { Link } from 'react-router-dom';
import { useGetEmployeesQuery } from '../../api/generated';

interface TableRowProps {
    name: string | undefined;
    lname: string | undefined;
    email: string | undefined;
}

function TableRow(props: TableRowProps) {
    return (
        <tr>
            <td className="px-2">
                <img
                    className="rounded-full shadow-lg w-10 h-10 inline-block "
                    src="/img/nav/profilePic.jpg"
                    alt="Profile_Picture"
                />
            </td>
            <tr className="text-xl font-bold">
                <td className="nameTitle px-2 ">
                    {props.name} {props.lname}
                </td>
            </tr>
            <tr className="text-xs">
                <td className="px-2 pb-2 text-trev">
                    <span>Assigned Task :120/135</span>{' '}
                    <span className="pl-4">{props.email}</span>
                </td>
            </tr>
        </tr>
    );
}

export default function Cards() {
    const { data } = useGetEmployeesQuery();

    return (
        <div className="cards max-w-sm rounded-lg  ">
            <div className="px-4 pt-4 pb-2">
                <div className="headerTitle font-bold text-xl mb-2 grid grid-cols-2">
                    Employees
                    <div className="pl-36">
                        <Link to="/admin/emp-list">
                            <svg
                                width="31"
                                height="30"
                                viewBox="0 0 31 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="0.493164"
                                    width="30"
                                    height="30"
                                    rx="5"
                                    fill="#5570F1"
                                />
                                <path
                                    d="M17.2031 23.5598L24.7331 16.0638C25.2991 15.4998 25.2991 14.5018 24.7331 13.9358L17.2031 6.43983C16.6151 5.85583 15.6651 5.85783 15.0811 6.44583C14.4971 7.03383 14.4971 7.98183 15.0851 8.56783L20.0391 13.5018H7.3291C6.4991 13.5018 5.8291 14.1738 5.8291 15.0018C5.8291 15.8298 6.4991 16.5018 7.3291 16.5018H20.0391L15.0851 21.4338C14.7911 21.7278 14.6451 22.1118 14.6451 22.4978C14.6451 22.8798 14.7911 23.2638 15.0811 23.5558C15.6651 24.1418 16.6151 24.1438 17.2031 23.5598Z"
                                    fill="white"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="px-4">
                <div className="headerTitle font-bold text-xl mb-2">
                    SEO Employees
                </div>
            </div>
            <tbody>
                {data?.getEmployees?.slice(0, 3).map((row) => {
                    if (row?.role === 'SEOADMIN' || row?.role === 'SEOEMPLOYEE')
                        return (
                            <TableRow
                                key={row?.id}
                                name={row?.firstName as string}
                                lname={row?.lastName as string}
                                email={row?.email as string}
                            />
                        );

                    return null;
                })}
            </tbody>
            <div className="px-4 pt-4">
                <div className="headerTitle font-bold text-xl mb-2">
                    CMS Employees
                </div>
            </div>

            <tbody>
                {data?.getEmployees?.slice(0, 12).map((row) => {
                    if (row?.role === 'CMSADMIN' || row?.role === 'CMSEMPLOYEE')
                        return (
                            <TableRow
                                key={row?.id}
                                name={row?.firstName as string}
                                lname={row?.lastName as string}
                                email={row?.email as string}
                            />
                        );
                    return null;
                })}
            </tbody>
        </div>
    );
}
