import { useGetCountsQuery } from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/notification.css';
import CardWrapper from './CardWrapper';

import Percent from './PercentBar';

export default function Notifb() {
    const { data } = useGetCountsQuery();
    const done = data?.getCounts?.doneDMSCounts;
    const total = data?.getCounts?.outreachedDMSCounts;
    const negotiating = data?.getCounts?.negotiatingDMSCounts;
    const percentTotal = String((Number(total) / (Number(total) + 10)) * 100);
    const percentDone = String((Number(done) / (Number(done) + 10)) * 100);
    const percentNegotiating = String(
        (Number(negotiating) / (Number(negotiating) + 10)) * 100
    );

    return (
        <CardWrapper
            icon="checklist"
            href="/admin/outreach-sheet"
            title="Outreach"
        >
            <div>
                <Percent
                    bgcolor="blue"
                    progress={percentTotal}
                    height={10}
                    name="Total"
                    numbre={Number(total)}
                />

                <Percent
                    bgcolor="green"
                    progress={percentDone}
                    height={10}
                    name="Done"
                    numbre={Number(done)}
                />

                <Percent
                    bgcolor="orange"
                    progress={percentNegotiating}
                    height={10}
                    name="Negotiating"
                    numbre={Number(negotiating)}
                />
            </div>
        </CardWrapper>
    );
}
