import {
    useGetEmployeeUsingTokenQuery,
    useGetNotificationByIdQuery,
} from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/welcome.css';

export default function Welcome() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data } = useGetNotificationByIdQuery({
        variables: {
            input: {
                toEmployeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });
    const count = data?.getNotificationById?.filter(
        (ro) => ro?.status === false
    ).length;
    return (
        <div className=" rounded-md w-full bg-white flex flex-row gap-10 shadow-headerShadow">
            <div className="flex items-center  w-32  bg-gradient-to-r from-trev to-indigo rounded-lg float-left">
                <img src="/img/wel.png" alt="welcome_img" />
            </div>
            <div className='flex flex-col justify-center items-start w-full' >  
                <h1 className="text-2xl font-bold text-black80 ">
                    Welcome {LoggedInUser?.getEmployeeUsingToken?.firstName} !!!
                </h1>
                <p className="text-black40 text-base">
                    You have <span className="text-mmad">{count} unread {`${Number(count) > 1 ? 'alerts' : 'alert'}`} </span>
                </p>
            </div>
        </div>
    );
}
