import { useFormik, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useCreateDomainTransferRequestMutation,
    useGetAllNichesForDomainQuery,
    useGetAllSiteContactDetailsForDomainQuery,
    useGetEmployeeUsingTokenQuery,
    useMasterSheetQuery,
} from '../../api/generated';
import { TransferDomainInitialValues } from '../../types/TransferDomainInitialValues';
import { CloseButton } from '../main/CloseButton';

export function TransferDomainForm(props: {
    domainID: number;
    contactTypeID: number;
}) {
    const formik = useFormikContext<TransferDomainInitialValues>();
    const [transferDomainRequest] = useCreateDomainTransferRequestMutation();

    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    useEffect(() => {
        formik.setFieldValue(
            'contactedByID',
            LoggedInUser?.getEmployeeUsingToken?.id
        );
    }, [LoggedInUser]);

    const { data: domain } = useMasterSheetQuery({
        variables: {
            input: {
                id: props.domainID,
                contactTypeID: props.contactTypeID,
            },
        },
    });

    const domainDetails = domain?.masterSheet?.domainDetails.filter(
        (domainDetail) => domainDetail?.contactType.id === props.contactTypeID
    );

    const { data: details } = useGetAllSiteContactDetailsForDomainQuery({
        variables: {
            input: {
                id: props.domainID,
            },
        },
    });

    const { data: niches } = useGetAllNichesForDomainQuery({
        variables: {
            input: {
                id: props.domainID,
            },
        },
    });

    const navigate = useNavigate();

    const formikForm = useFormik({
        initialValues: {
            domainID: props.domainID,
            contactTypeID: props.contactTypeID,

            employeeRequestByID: LoggedInUser?.getEmployeeUsingToken?.id,
            siteDA: domain?.masterSheet?.siteDA,
            siteDR: domain?.masterSheet?.siteDR,
            siteTraffic: domain?.masterSheet?.siteTraffic,
            priceAsked: domainDetails && domainDetails[0]?.priceAsked,
            finalPrice: domainDetails && domainDetails[0]?.finalPrice,
            specialPrice: domainDetails && domainDetails[0]?.specialPrice,
            linkInsertionPrice:
                domainDetails && domainDetails[0]?.linkInsertionPrice,
            POC: domainDetails && domainDetails[0]?.POC,
            status: 'Processing',
        },
        onSubmit: async () => {
            await transferDomainRequest({
                variables: {
                    input: {
                        domainID: formikForm.values.domainID,
                        contactTypeID: formikForm.values.contactTypeID,
                        employeeRequestByID: Number(
                            formikForm.values.employeeRequestByID
                        ),
                        ahrefTraffic: 0,

                        finalPrice: Number(formikForm?.values?.finalPrice)
                            ? Number(formikForm.values.finalPrice)
                            : Number(
                                  domain?.masterSheet?.domainDetails[0]
                                      ?.finalPrice
                              ),
                        priceAsked: Number(formikForm.values.priceAsked)
                            ? Number(formikForm.values.priceAsked)
                            : Number(
                                  domainDetails && domainDetails[0]?.priceAsked
                              ),
                        linkInsertionPrice:
                            domainDetails &&
                            domainDetails[0]?.contactType.contactType ===
                                'LINK-INSERTION'
                                ? Number(formikForm.values.linkInsertionPrice)
                                    ? Number(
                                          formikForm.values.linkInsertionPrice
                                      )
                                    : Number(domainDetails[0]?.priceAsked)
                                : 0,
                        siteDA: Number(formikForm.values.siteDA)
                            ? Number(formikForm.values.siteDA)
                            : Number(domain?.masterSheet?.siteDA),
                        siteDR: Number(formikForm.values.siteDR)
                            ? Number(formikForm.values.siteDR)
                            : Number(domain?.masterSheet?.siteDR),
                        siteTraffic: Number(formikForm.values.siteTraffic)
                            ? Number(formikForm.values.siteTraffic)
                            : Number(domain?.masterSheet?.siteTraffic),
                        specialPrice: Number(formikForm.values.specialPrice)
                            ? Number(formikForm.values.specialPrice)
                            : Number(
                                  domain?.masterSheet?.domainDetails[0]
                                      ?.specialPrice
                              ),
                        POC: String(formikForm.values.POC)
                            ? String(formikForm.values.POC)
                            : String(
                                  domain?.masterSheet?.domainDetails[0]?.POC
                              ),
                        status: formikForm.values.status,
                    },
                },
            });
            navigate('/admin/outreach-sheet');
        },
    });
    return (
        <div className="p-4">
            <CloseButton
                redirectURL="/admin/outreach-sheet"
                className="float-right"
            />
            <form
                className=" grid grid-cols-3 my-4"
                onSubmit={formikForm.handleSubmit}
                onReset={formikForm.handleReset}
            >
                <div className="col-span-2 space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="siteUrlInput"
                    >
                        Site URL:
                    </label>
                    <input
                        className="border border-slate-400 rounded-sm w-full p-1"
                        type="text"
                        name="siteUrl"
                        id="siteUrlInput"
                        placeholder="Site Url"
                        required
                        disabled
                        value={domain?.masterSheet?.siteUrl}
                        onBlur={(event) => {
                            if (event.target.value.trim().length === 0) {
                                formik.setFieldValue('siteUrl', undefined);
                            } else {
                                formik.setFieldValue(
                                    'siteUrl',
                                    event.target.value
                                );
                            }
                        }}
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="contactTypeInput"
                    >
                        Contact Type:
                    </label>
                    <select
                        id="contactTypeInput"
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        required
                        disabled
                        onChange={(event) =>
                            formik.setFieldValue(
                                'contactType',
                                Number(event.target.value)
                            )
                        }
                    >
                        <option
                            value={
                                domain?.masterSheet?.domainDetails[0]
                                    ?.contactType.id as number
                            }
                        >
                            {
                                domain?.masterSheet?.domainDetails[0]
                                    ?.contactType.contactType
                            }
                        </option>
                    </select>
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="dateOfContactInput"
                    >
                        Date of Contact:
                    </label>
                    <input
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="date"
                        name="dateOfContact"
                        id="dateOfContactInput"
                        placeholder="Date of Contact"
                        disabled
                        value={domain?.masterSheet?.domainDetails[0]?.dateOfContact.substring(
                            0,
                            10
                        )}
                        required
                        onChange={(event) =>
                            formik.setFieldValue(
                                'dateOfContact',
                                event.target.value
                            )
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="contactedByInput"
                    >
                        Transfer Request By:
                    </label>
                    <input
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="text"
                        name="contactedBy"
                        id="contactedByInput"
                        value={LoggedInUser?.getEmployeeUsingToken?.firstName?.concat(
                            ` ${LoggedInUser.getEmployeeUsingToken.lastName}`
                        )}
                        required
                        onChange={(event) => {
                            formik.setFieldValue(
                                'employeeRequestByID',
                                Number(event.target.value)
                            );
                        }}
                        disabled
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="serviceTypeInput"
                    >
                        Service Type:
                    </label>
                    <select
                        id="serviceTypeInput"
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        required
                        disabled
                        onChange={(event) =>
                            formik.setFieldValue(
                                'serviceType',
                                Number(event.target.value)
                            )
                        }
                    >
                        <option
                            value={
                                domain?.masterSheet?.domainDetails[0]
                                    ?.serviceType.id as number
                            }
                        >
                            {
                                domain?.masterSheet?.domainDetails[0]
                                    ?.serviceType.serviceType as string
                            }
                        </option>
                    </select>
                </div>
                <div className="space-y-2 p-2 col-span-3">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="siteContactDetailsInput"
                    >
                        Site Contact Details:
                    </label>
                    <input
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="text"
                        name="siteContactDetails"
                        id="siteContactDetailsInput"
                        placeholder="Site Contact Email ID"
                        required
                        disabled
                        value={details?.getAllSiteContactDetailsForDomain?.toString()}
                    />
                </div>
                <div className="col-span-2 space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="nicheInput"
                    >
                        Niche:
                    </label>
                    <input
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="text"
                        name="niches"
                        id="nicheInput"
                        // placeholder="Eg. Health, Education, General"
                        required
                        disabled
                        value={niches?.getAllNichesForDomain?.toString()}
                    />
                    {/* <ComboBoxInput
                        label="Niche"
                        setFieldAttribute="nicheID"
                        attributeValue={formik.values.nicheID}
                        disabled={false}
                    /> */}
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="specialPriceInput"
                    >
                        Link Insertion Price:
                    </label>
                    <input
                        min={0}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="linkInsertionPrice"
                        id="linkInsertionPricesInput"
                        placeholder="Eg. 50"
                        defaultValue={
                            domainDetails &&
                            (domainDetails[0]?.linkInsertionPrice as number)
                        }
                        onChange={(event) =>
                            formik.setFieldValue(
                                'linkInsertionPrice',
                                Number(event.target.value)
                            )
                        }
                        disabled={
                            !(
                                domainDetails &&
                                domainDetails[0]?.serviceType?.serviceType ===
                                    'LINK-INSERTION'
                            )
                        }
                    />
                </div>
                {/* enabled */}
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="siteDaInput"
                    >
                        Site DA:
                    </label>
                    <input
                        min={0}
                        max={100}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="siteDA"
                        id="siteDaInput"
                        placeholder={domain?.masterSheet?.siteDA.toString()}
                        defaultValue={domain?.masterSheet?.siteDA}
                        required
                        onChange={(event) =>
                            formikForm.setFieldValue(
                                'siteDA',
                                Number(event.target.value)
                            )
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="siteDrInput"
                    >
                        Site DR:
                    </label>
                    <input
                        min={0}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="siteDR"
                        id="siteDrInput"
                        placeholder={domain?.masterSheet?.siteDR.toString()}
                        required
                        defaultValue={domain?.masterSheet?.siteDR}
                        onChange={(event) =>
                            formikForm.setFieldValue(
                                'siteDR',
                                Number(event.target.value)
                            )
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="siteTrafficInput"
                    >
                        Site Traffic:
                    </label>
                    <input
                        min={0}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="siteTraffic"
                        id="siteTrafficInput"
                        placeholder={domain?.masterSheet?.siteTraffic.toString()}
                        defaultValue={domain?.masterSheet?.siteTraffic}
                        required
                        onChange={(event) =>
                            formikForm.setFieldValue(
                                'siteTraffic',
                                Number(event.target.value)
                            )
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="priceAskedInput"
                    >
                        Price Asked:
                    </label>
                    <input
                        min={0}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="priceAsked"
                        id="priceAskedInput"
                        placeholder={
                            domain &&
                            (
                                domain?.masterSheet?.domainDetails[0]
                                    ?.priceAsked as number
                            ).toString()
                        }
                        defaultValue={
                            domain?.masterSheet?.domainDetails[0]
                                ?.priceAsked as number
                        }
                        required
                        onChange={(event) =>
                            formikForm.setFieldValue(
                                'priceAsked',
                                Number(event.target.value)
                            )
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="finalPriceInput"
                    >
                        Final Price:
                    </label>
                    <input
                        min={0}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="finalPrice"
                        id="finalPriceInput"
                        placeholder={
                            domain &&
                            (
                                domain?.masterSheet?.domainDetails[0]
                                    ?.finalPrice as number
                            ).toString()
                        }
                        defaultValue={
                            domain?.masterSheet?.domainDetails[0]
                                ?.finalPrice as number
                        }
                        required
                        onChange={(event) =>
                            formikForm.setFieldValue(
                                'finalPrice',
                                Number(event.target.value)
                            )
                        }
                    />
                </div>
                <div className="space-y-2 p-2">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="specialPriceInput"
                    >
                        Special Price:
                    </label>
                    <input
                        min={0}
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="number"
                        name="specialPrice"
                        id="specialPriceInput"
                        placeholder={
                            domain &&
                            (
                                domain?.masterSheet?.domainDetails[0]
                                    ?.specialPrice as number
                            ).toString()
                        }
                        defaultValue={
                            domain?.masterSheet?.domainDetails[0]
                                ?.specialPrice as number
                        }
                        required
                        onChange={(event) =>
                            formikForm.setFieldValue(
                                'specialPrice',
                                Number(event.target.value)
                            )
                        }
                    />
                </div>

                <div className="space-y-2 p-2 col-span-3">
                    <label
                        className="block text-sm font-semibold text-slate-500"
                        htmlFor="PocInput"
                    >
                        POC:
                    </label>
                    <input
                        className="border bg-white border-slate-400 rounded-sm w-full p-1"
                        type="text"
                        name="POC"
                        id="PocInput"
                        required
                        placeholder={
                            domain?.masterSheet?.domainDetails[0]?.POC as string
                        }
                        defaultValue={
                            domain?.masterSheet?.domainDetails[0]?.POC as string
                        }
                        onChange={(event) =>
                            formikForm.setFieldValue('POC', event.target.value)
                        }
                    />
                </div>
                <div className="col-span-3 mt-5 p-2 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                    <button
                        type="button"
                        onClick={() => {
                            window.location.reload();
                        }}
                        className="border-2 border-blue-600 text-blue-500 p-2 rounded-md"
                    >
                        Reset Form
                    </button>
                    <button
                        type="submit"
                        className="text-white create-order align-middle p-1.5 w-48 bg-zedex-button-blue hover:bg-zedex-button-blue-hover border-blue-600"
                    >
                        Request Domain Transfer
                    </button>
                </div>
            </form>
        </div>
    );
}
