import {
    useGetContentOrderServiceTypesQuery,
    useUpdateContentOrderServiceTypeStatusMutation,
} from '../../api/generated';

export function ToggleButton(props: {
    serviceTypeID?: number;
    active?: boolean;
}) {
    const [disableServiceType] =
        useUpdateContentOrderServiceTypeStatusMutation();
    const { data: serviceTypes, refetch } =
        useGetContentOrderServiceTypesQuery();
    return (
        <label className="relative inline-flex items-center cursor-pointer ">
            <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={!props.active}
                onClick={async () => {
                    const { data } = await disableServiceType({
                        variables: {
                            input: {
                                id: props.serviceTypeID,
                                disabled: !props.active,
                            },
                        },
                    });
                    if (data?.updateContentOrderServiceTypeStatus?.success) {
                        refetch();
                    }
                }}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer ring-4 ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />

            {!props.active ? (
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Enabled
                </span>
            ) : (
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Disabled
                </span>
            )}
        </label>
    );
}
