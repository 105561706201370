import { CreateContentOrderForm } from '../../components/createContentOrder/CreateContentOrderForm';
import NavBar from '../../components/main/Navbar/Navbar';
import PathBar from '../../components/main/PathBar';
import ProfileBar from '../../components/main/ProfileBar';

export function CreateContentOrder() {
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Create Content Order" />
            <div className="bg-zedex-bg-gray pt-20">
                <PathBar />
                <div className="ml-[4.3em] bg-white py-[0.2] px-[1em] mr-[0.4em]">
                    <CreateContentOrderForm />
                </div>
            </div>
        </>
    );
}
