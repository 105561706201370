interface IInputProps {
    inputType: string;
    inputPlaceholder: string;
    className?: string;
    name: string;
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number;
    error: string | undefined;
    touched: boolean | undefined;
}

export default function InputComponent(IInputProp: IInputProps) {
    return (
        <div>
            <input
                type={IInputProp.inputType}
                className={IInputProp.className ? IInputProp.className : "form-control block w-full mx-px  px-3 py-1.5 text-base font-normal text-gray-400 bg-gray bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-lg"}
                placeholder={IInputProp.inputPlaceholder}
                id={IInputProp.id}
                name={IInputProp.name}
                onChange={IInputProp.onChange}
                value={IInputProp.value}
            />
            {IInputProp.error && IInputProp.touched ? (
                <div className="text-red px-2 pt-2">{IInputProp.error}</div>
            ) : null}
        </div>
    );
}
