import Dashboard from '../../components/dashboard/Dashboard';
import RootLayout from '../../shared/Icons/RootLayout';
import '../../styles/welcome.css';

export default function Home() {
    return (
        <RootLayout activeElement='dashboard' ProfileBarTitle='Admin Dashboard'>
            <Dashboard />
        </RootLayout>
    );
}
