import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ContentOrderServiceTypeAttributeValue,
    Role,
    useCreateContentOrderMutation,
    useGenerateContentOrderIdQuery,
    useGetContentOrderServiceTypeAttributeQuery,
    useGetContentOrderServiceTypesQuery,
    useGetEmployeeUsingTokenQuery,
    useGetEmployeeWithRoleQuery,
} from '../../api/generated';
import { CloseButton } from '../main/CloseButton';

interface InitialValues {
    orderID: string;
    clientName: string;
    pbnName: string;
    clientSiteURL: string;
    wordCount: number;
    cmsEmployeeID: number;
    orderAdminID: number;
    orderStartDate: string;
    orderDueDate: string;
    orderStatus: string;
    projectDescription: string;
    serviceTypeID: number;
    serviceTypeAttributeValues: ContentOrderServiceTypeAttributeValue[];
}

function ContentOrderDetails() {
    const [selectedRadio, setSelectedRadio] = useState(0);
    const orderStatus = ['Assigned', 'In-progress', 'Done', 'In-Review'];

    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();

    const formik = useFormikContext<InitialValues>();

    const { data: generatedOrderID } = useGenerateContentOrderIdQuery({
        variables: {
            input: {
                clientName: formik.values.clientName,
            },
        },
    });
    useEffect(() => {
        formik.setFieldValue(
            'orderID',
            generatedOrderID?.generateContentOrderID
        );
    }, [generatedOrderID]);

    useEffect(() => {
        formik.setFieldValue(
            'orderAdminID',
            Number(LoggedInUser?.getEmployeeUsingToken?.id)
        );
    }, [LoggedInUser?.getEmployeeUsingToken?.id]);

    return (
        <div className="grid lg:grid-cols-3 gap-y-5 gap-x-3  px-5 py-3 mx-3">
            <div className="grid grid-cols-3 lg:col-span-2">
                <label className="block text-gray-700 col-span-3 ml-3">
                    Order ID
                </label>
                <div className="col-span-3 grid grid-cols-2 gap-2 p-3">
                    <div className="flex items-center pl-4 space-x-4 ">
                        <input
                            type="radio"
                            checked={selectedRadio === 0}
                            onChange={() => setSelectedRadio(0)}
                            value="0"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <div
                            className={`p-2 rounded-md w-full text-center ${
                                selectedRadio === 0
                                    ? 'bg-blue-100'
                                    : 'bg-slate-300'
                            }`}
                        >
                            {`#${generatedOrderID?.generateContentOrderID}`}
                        </div>
                    </div>
                    <div className="flex items-center pl-4 dark:border-gray-700 space-x-4">
                        <input
                            checked={selectedRadio === 1}
                            type="radio"
                            onChange={() => setSelectedRadio(1)}
                            value="1"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <input
                            disabled={selectedRadio !== 1}
                            type="text"
                            required
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'orderID',
                                    event.target.value
                                )
                            }
                            className="disabled:bg-slate-200 form-check-label inline-block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                    </div>
                </div>
            </div>
            <div className="">
                <label className="ml-3 form-label block text-left text-gray-700 ">
                    Client Name
                </label>
                <div className="order col-span-3 p-3">
                    <div>
                        <input
                            required
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'clientName',
                                    event.target.value
                                )
                            }
                            type="text"
                            className="form-check-label w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                    </div>
                </div>
            </div>
            <div className="">
                <label className="ml-3 form-label block text-left text-gray-700 ">
                    Client Site URL
                </label>
                <div className="order col-span-3 p-3">
                    <div>
                        <input
                            required
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'clientSiteURL',
                                    event.target.value.trim()
                                )
                            }
                            type="text"
                            className="form-check-label w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                    </div>
                </div>
            </div>
            <div className="">
                <label className="ml-3 form-label block text-left text-gray-700 ">
                    PBN Name
                </label>
                <div className="order col-span-3 p-3">
                    <div>
                        <input
                            required
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'pbnName',
                                    event.target.value
                                )
                            }
                            type="text"
                            className="form-check-label w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                    </div>
                </div>
            </div>
            <div className="">
                <label className="ml-3 block w-full text-gray-700">
                    Word Count
                </label>
                <div className="col-span-3 p-3">
                    <div>
                        <input
                            required
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'wordCount',
                                    Number(event.target.value)
                                )
                            }
                            type="number"
                            className="form-check-label w-full p-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                <label
                    className="ml-3 block w-full text-gray-700"
                    htmlFor="orderStartDate"
                >
                    Order Start Date:
                </label>
                <input
                    required
                    className="ml-3 border bg-white border-slate-400 block w-full p-2 rounded-md"
                    type="date"
                    name="orderStartDate"
                    id="orderStartDateInput"
                    placeholder="Order Start Date"
                    defaultValue={new Date().toISOString().substring(0, 10)}
                    onChange={(event) =>
                        formik.setFieldValue(
                            'orderStartDate',
                            event.target.value
                        )
                    }
                />
            </div>
            <div className="space-y-2">
                <label
                    className="ml-3 block w-full text-gray-700"
                    htmlFor="orderDueDate"
                >
                    Order Due Date:
                </label>
                <input
                    className="block ml-3 w-full col-span-3 border bg-white border-slate-400 p-2 rounded-md"
                    type="date"
                    name="orderDueDate"
                    id="orderDueDateInput"
                    placeholder="Order Due Date"
                    defaultValue={new Date().toISOString().substring(0, 10)}
                    onChange={(event) =>
                        formik.setFieldValue('orderDueDate', event.target.value)
                    }
                    required
                />
            </div>
            <div className="space-y-2 flex justify-center">
                <AssignTaskToCMSEmployee />
            </div>
            <div className="space-y-2 flex justify-center p-5">
                <label className="text-center flex self-center w-40">
                    Order Status:
                </label>
                <select
                    onChange={(event) =>
                        formik.setFieldValue('orderStatus', event.target.value)
                    }
                    className="text-center px-6 py-2 rounded-md w-60"
                >
                    {orderStatus.map((status) => (
                        <option key={status} value={status}>
                            {status}
                        </option>
                    ))}
                </select>
            </div>
            <div className="lg:col-span-3">
                <label className="text-center flex self-center w-40">
                    Project Description
                </label>
                <textarea
                    className="w-full border rounded-md sm:h-40 lg:h-20 p-4"
                    onChange={(event) =>
                        formik.setFieldValue(
                            'projectDescription',
                            event.target.value
                        )
                    }
                />
            </div>
            <ServiceTypeDetails />
            <div className="lg:col-span-3 flex flex-row justify-center">
                <div className="basis-1/2 flex justify-center">
                    <button
                        type="submit"
                        className="px-6 py-2 w-48 rounded-md bg-zedex-button-blue hover:bg-zedex-button-blue-hover text-white"
                    >
                        Save
                    </button>
                </div>

                <div className="basis-1/2 flex lg:justify-start sm:justify-center">
                    <button
                        type="button"
                        className="px-6 py-2 w-48 rounded-md bg-zedex-button-blue hover:bg-zedex-button-blue-hover text-white"
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    );
}

function ServiceTypeDetails() {
    const formik = useFormikContext<InitialValues>();
    const { data: serviceTypes } = useGetContentOrderServiceTypesQuery();
    const { data: attributes } = useGetContentOrderServiceTypeAttributeQuery({
        variables: {
            input: {
                serviceTypeID: formik.values.serviceTypeID,
            },
        },
    });

    const serviceType =
        (serviceTypes &&
            serviceTypes.getContentOrderServiceTypes &&
            serviceTypes.getContentOrderServiceTypes) ||
        [];

    useEffect(() => {
        if (!formik.values.serviceTypeID) {
            formik.setFieldValue('serviceTypeID', serviceType[0]?.id);
        }
    });
    return (
        <>
            <div className="lg:col-span-3 flex justify-center">
                <select
                    className="bg-blue-100 px-5 py-3 rounded-lg"
                    onChange={(event) =>
                        formik.setFieldValue(
                            'serviceTypeID',
                            Number(event.target.value)
                        )
                    }
                >
                    {serviceTypes?.getContentOrderServiceTypes?.map(
                        (serviceType) => (
                            <option
                                key={serviceType?.id}
                                value={serviceType?.id as number}
                            >
                                {serviceType?.serviceType}
                            </option>
                        )
                    )}
                </select>
            </div>
            <div className="lg:col-span-3 space-y-5 my-8">
                {attributes?.getContentOrderServiceTypeAttribute?.map(
                    (attribute) => (
                        <div
                            className="w-full flex space-x-10 flex-row justify-center"
                            key={attribute?.id}
                        >
                            <label className="self-center basis-1/3">
                                {attribute?.attributeName}:
                            </label>
                            <input
                                required
                                className="p-2 text-center border align-middle border-slate-500 rounded-md sm:2/3 lg:basis-1/3"
                                type="text"
                                placeholder={attribute?.attributeName as string}
                                onChange={(event) => {
                                    const attributes =
                                        formik.values.serviceTypeAttributeValues.filter(
                                            (attr) =>
                                                attr.serviceTypeAttributeID !==
                                                attribute?.id
                                        );
                                    formik.setFieldValue(
                                        'serviceTypeAttributeValues',
                                        [
                                            ...attributes,
                                            {
                                                contentOrderID:
                                                    formik.values.orderID,
                                                serviceTypeAttributeID:
                                                    attribute?.id,
                                                serviceTypeAttributeValue:
                                                    event.target.value.trim(),
                                            },
                                        ]
                                    );
                                }}
                            />
                        </div>
                    )
                )}
            </div>
        </>
    );
}

function AssignTaskToCMSEmployee() {
    const formik = useFormikContext<InitialValues>();

    const { data: cmsEmployee } = useGetEmployeeWithRoleQuery({
        variables: {
            input: {
                role: [Role.Cmsemployee],
            },
        },
    });

    const cmsEmployees =
        (cmsEmployee &&
            cmsEmployee.getEmployeeWithRole &&
            cmsEmployee.getEmployeeWithRole) ||
        [];

    useEffect(() => {
        if (!formik.values.cmsEmployeeID) {
            formik.setFieldValue('cmsEmployeeID', cmsEmployees[0]?.id);
        }
    });

    return (
        <div className="flex justify-center p-5 pt-8">
            <label className="text-center flex self-center w-40">
                CMS Employee:
            </label>
            <select
                onChange={(event) =>
                    formik.setFieldValue(
                        'cmsEmployeeID',
                        Number(event.target.value)
                    )
                }
                className="text-center px-6 py-3 rounded-md w-60"
            >
                {cmsEmployee?.getEmployeeWithRole?.map((employee) => (
                    <option key={employee?.id} value={employee?.id as number}>
                        {`${employee?.firstName} ${employee?.lastName}`}
                    </option>
                ))}
            </select>
        </div>
    );
}

export function CreateContentOrderForm() {
    const [createContentOrder] = useCreateContentOrderMutation();
    const navigate = useNavigate();
    return (
        <Formik
            initialValues={{
                orderID: '',
                clientName: '',
                pbnName: '',
                clientSiteURL: '',
                wordCount: 0,
                cmsEmployeeID: undefined,
                orderAdminID: undefined,
                orderStartDate: new Date().toISOString().substring(0, 10),
                orderDueDate: new Date().toISOString().substring(0, 10),
                orderStatus: 'Assigned',
                projectDescription: '',
                serviceTypeID: undefined,
                serviceTypeAttributeValues: [],
            }}
            onSubmit={async (values) => {
                const { data } = await createContentOrder({
                    variables: {
                        input: {
                            clientName: values.clientName,
                            id: values.orderID,
                            clientSiteURL: values.clientSiteURL,
                            cmsAdminID: values.orderAdminID,
                            cmsEmployeeID: values.cmsEmployeeID,
                            orderDescription: values.projectDescription,
                            orderDueDate: values.orderDueDate,
                            orderStartDate: values.orderDueDate,
                            PbnName: values.pbnName,
                            serviceTypeID: values.serviceTypeID,
                            orderStatus: values.orderStatus,
                            wordCount: values.wordCount,
                            serviceTypeAttributeValue:
                                values.serviceTypeAttributeValues,
                        },
                    },
                });
                if (data?.createContentOrder?.success) {
                    navigate('/admin/content-order-list');
                }
            }}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                    <div className="bg-white rounded-md">
                        <CloseButton
                            redirectURL="/admin/create-order"
                            className="float-right mx-3 my-3"
                        />
                        <div className="text-xl font-semibold mx-4 my-2 mb-4 pt-4">
                            Create New Content Order
                        </div>
                        <ContentOrderDetails />
                    </div>
                </form>
            )}
        </Formik>
    );
}
