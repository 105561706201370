import {
    Employee,
    MainOrders,
    RangeFilterDateAsString,
    RangeFilterInteger,
    SearchFilter,
    useGetEmployeeUsingTokenQuery,
    usePaginatedOrderListQuery,
} from '../../api/generated';
import {
    TableHeaderColumnName,
    TableHeaderColumnSorting,
} from '../main/TableHeaderColumnSorting';

import CalendarDaysIcon from '@heroicons/react/20/solid/CalendarDaysIcon';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface InitialValues {
    pageNum: number;
    paginationRowCount: number;
    totalPages: number;
    totalRows: number;
    sortBy: string;
    sortType: string;
    statusFilter: string[];
    filterToggle: boolean;
    search: SearchFilter;
    siteDaReqFilter: RangeFilterInteger;
    siteDrReqFilter: RangeFilterInteger;
    articleCountsFilter: RangeFilterInteger;
    dueDateFilter: RangeFilterDateAsString;
    toggleView: boolean;
}

export function TableAndGrid() {
    const formik = useFormikContext<InitialValues>();
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    useEffect(() => {
        formik.setFieldValue('pageNum', 0);
    }, [formik.values.paginationRowCount]);

    const { data } = usePaginatedOrderListQuery({
        variables: {
            input: {
                take: Number(formik.values.paginationRowCount),
                skip:
                    Number(formik.values.pageNum) *
                    Number(formik.values.paginationRowCount),
                sortBy: formik.values.sortBy,
                sortType: formik.values.sortType,
                statusFilter: formik.values.statusFilter,
                search: {
                    ...formik.values.search,
                },
                siteDaReq: {
                    ...formik.values.siteDaReqFilter,
                },
                siteDrReq: {
                    ...formik.values.siteDrReqFilter,
                },
                articleCountsFilter: {
                    ...formik.values.articleCountsFilter,
                },
                dueDateFilter: {
                    ...formik.values.dueDateFilter,
                },
                forEmployeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });

    useEffect(() => {
        formik.setFieldValue(
            'totalPages',
            data?.paginatedOrderList?.totalPages
        );
    }, [data?.paginatedOrderList?.totalPages]);

    useEffect(() => {
        formik.setFieldValue('totalRows', data?.paginatedOrderList?.totalRows);
    }, [data?.paginatedOrderList?.totalRows]);
    if (!formik.values.toggleView) {
        if (data?.paginatedOrderList?.totalRows === 0) {
            return (
                <div className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center">
                    No Data To be Displayed
                </div>
            );
        }
        return (
            <div className="client-order-table-grid overflow-x-auto overflow-y-auto text-center bg-transparent">
                <table className="table-auto">
                    <thead className="table-header text-xs font-bold">
                        <tr className="space-x-2 overflow-x-auto">
                            <th className="edit-option">
                                <input
                                    className="align-middle"
                                    type="checkbox"
                                />
                            </th>
                            <TableHeaderColumnSorting
                                columnName="Order ID"
                                sortBy="id"
                            />
                            <TableHeaderColumnSorting
                                columnName="Number of Links"
                                sortBy="ArticleCounts"
                            />
                            <TableHeaderColumnName columnName="Client Name" />
                            <TableHeaderColumnSorting
                                columnName="Start Date"
                                sortBy="orderStartDate"
                            />
                            <TableHeaderColumnSorting
                                columnName="Due Date"
                                sortBy="orderDueDate"
                            />
                            <TableHeaderColumnName columnName="Client Site URL" />
                            <TableHeaderColumnName columnName="DA Required" />
                            <TableHeaderColumnName columnName="DR Required" />
                            <TableHeaderColumnSorting
                                columnName="Status"
                                sortBy="orderStatus"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {data?.paginatedOrderList?.orderList?.map(
                            (row) =>
                                row && (
                                    <TableRow
                                        key={row.id?.toString()}
                                        id={row.id}
                                        ArticleCounts={row.ArticleCounts}
                                        client={row.client}
                                        orderStartDate={row?.orderStartDate.substring(
                                            0,
                                            10
                                        )}
                                        orderDueDate={row?.orderDueDate.substring(
                                            0,
                                            10
                                        )}
                                        orderStatus={row?.orderStatus}
                                        paymentStatus={row.paymentStatus}
                                        SeoOrders={row.SeoOrders}
                                        createdBy={row.createdBy as Employee}
                                        clientSiteURL={row.clientSiteURL}
                                        requiredDA={row.requiredDA}
                                        requiredDR={row.requiredDR}
                                        createdAt={undefined}
                                        updatedAt={undefined}
                                    />
                                )
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
    return (
        <div className="py-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 sm:gap-4 lg:gap-4 overflow-y-auto scrollbar-hide text-center bg-transparent">
            {data?.paginatedOrderList?.totalRows === 0 ? (
                <div
                    className="rounded-lg p-5 bg-blue-100 text-blue-500 text-center md:col-span-2 lg:col-span-4 h-32
                "
                >
                    No Data To be Displayed
                </div>
            ) : (
                data?.paginatedOrderList?.orderList?.map(
                    (row) =>
                        row && (
                            <GridCard
                                key={row.id?.toString()}
                                id={row.id}
                                ArticleCounts={row.ArticleCounts}
                                client={row.client}
                                orderStartDate={row?.orderStartDate.substring(
                                    0,
                                    10
                                )}
                                orderDueDate={row?.orderDueDate.substring(
                                    0,
                                    10
                                )}
                                clientSiteURL={row?.clientSiteURL}
                                orderStatus={row?.orderStatus}
                                createdAt={row?.createdAt}
                                paymentStatus={row.paymentStatus}
                                updatedAt={row?.updatedAt}
                                SeoOrders={row.SeoOrders}
                                createdBy={row.createdBy}
                                requiredDA={row.requiredDA}
                                requiredDR={row.requiredDR}
                            />
                        )
                )
            )}
        </div>
    );
}

function GridCard(props: MainOrders) {
    const timeDue =
        new Date().getTime() - new Date(props.orderDueDate).getTime();
    const timeDueinDays = timeDue / (1000 * 3600 * 24);
    const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);
    const clientSiteUrlTrimmed =
        props.clientSiteURL && props?.clientSiteURL?.length > 29
            ? props?.clientSiteURL?.substring(0, 29).concat('....')
            : props.clientSiteURL;
    return (
        <Link to="/admin/order-list/view-order" state={{ orderID: props.id }}>
            <div className="grid-order-card p-3 rounded-xl shadow-lg border-2 border-slate-400 hover:bg-blue-100 h-40">
                <div className="border-b-2 h-12 border-slate-400 ">
                    <div className="text-xs font-bold text-left text-zedex-text-blue">
                        #{props.id}
                    </div>
                    <div className="text-md mb-2">
                        <span className="float-left font-bold">
                            {props.client?.name}
                        </span>
                        <span
                            className={`p-1 text-[10px] float-right ${
                                timeDue < 0 ? 'done-status' : 'overdue-status'
                            }`}
                        >
                            {timeDue > 0
                                ? `Due Past ${
                                      timeDueinWeeks < 0
                                          ? `${Math.abs(
                                                Math.floor(timeDueinWeeks)
                                            )} weeks and ${Math.abs(
                                                Math.ceil(
                                                    Math.floor(timeDueinDays) %
                                                        7
                                                )
                                            )} days`
                                          : Math.floor(timeDueinDays) > 0
                                          ? `${Math.abs(
                                                Math.floor(timeDueinDays)
                                            )} days`
                                          : ``
                                  }`
                                : timeDue < 0
                                ? `Due After ${
                                      timeDueinWeeks >= 0
                                          ? `${Math.abs(
                                                Math.ceil(timeDueinWeeks)
                                            )} weeks and ${Math.abs(
                                                Math.ceil(
                                                    Math.ceil(timeDueinDays) % 7
                                                )
                                            )} days`
                                          : Math.ceil(timeDueinDays) < 0
                                          ? `${Math.abs(
                                                Math.ceil(timeDueinDays)
                                            )} days`
                                          : ``
                                  }`
                                : 'Due Today'}
                        </span>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="text-xs grid grid-cols-2 gap-3">
                        <span className="text-left">
                            No. of Links to Build:&nbsp;
                            <span className="font-bold">
                                {props.ArticleCounts}
                            </span>
                        </span>
                        <span className="text-right">
                            DA / DR Required:&nbsp;
                            <span className="font-bold">
                                {props.requiredDA === 0
                                    ? props.requiredDR
                                    : props.requiredDA}
                            </span>
                        </span>
                    </div>
                    <div className="text-xs text-left mt-2">
                        <span className="">Client Site URL:</span>
                        <span className="">{` ${clientSiteUrlTrimmed}`}</span>
                    </div>
                    <div className="text-xs grid grid-cols-2 mt-2">
                        <span className="text-left space-x-2">
                            <CalendarDaysIcon
                                color="#3bbf68"
                                className="w-5 h-5 inline-block align-middle"
                            />

                            <span className="align-middle">Start Date: </span>
                            <span className='align-middle block'>{props.orderStartDate.substring(
                                0,
                                10
                            )}</span>
                        </span>
                        <span className="text-right space-x-2">
                            <CalendarDaysIcon
                                color="red"
                                className="w-5 h-5 inline-block align-middle"
                            />
                            <span className="align-middle">Due Date:</span>
                            <span className='align-middle block'> {props.orderDueDate.substring(
                                0,
                                10
                            )}</span>
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

function TableRow(props: MainOrders) {
    const timeDue =
        new Date(props.orderDueDate).getTime() - new Date().getTime();
    const timeDueinDays = Math.ceil(timeDue / (1000 * 3600 * 24));
    const timeDueinHours = Math.ceil(timeDue / (1000 * 3600));

    return (
        <tr className="hover:bg-blue-50">
            <td className="py-5">
                <input type="checkbox" />
            </td>
            <td className="underline">
                <Link
                    to="/admin/order-list/view-order"
                    state={{ orderID: props.id }}
                >
                    <span>{`#${props.id}`}</span>
                </Link>
            </td>
            <td>{props.ArticleCounts}</td>
            <td>{props.client?.name}</td>
            <td>{props.orderStartDate}</td>
            <td>{props.orderDueDate}</td>
            <td>{props.clientSiteURL}</td>
            <td>{props.requiredDA}</td>
            <td>{props.requiredDR}</td>
            {props.orderStatus === 'Overdue' && (
                <td className="font-bold">
                    <span className="block overdue-status p-2 m-1">
                        Overdue
                        <br />
                        <span className="text-[10px] text-red">
                            Due past{' '}
                            {timeDueinDays === 0
                                ? timeDueinHours.toString().concat(' Hours')
                                : timeDueinDays.toString().concat(' Days')}
                        </span>
                    </span>
                </td>
            )}
            {props.orderStatus === 'Cancelled' && (
                <td className="font-bold">
                    <span className="block cancelled-status p-2 m-1">
                        Cancelled
                    </span>
                </td>
            )}
            {props.orderStatus === 'Done' && (
                <td className="font-bold">
                    <span className="block done-status p-2 m-1">Done</span>
                </td>
            )}
            {props.orderStatus === 'Processing' && (
                <td className="font-bold">
                    <span className="block processing-status p-2 m-1">
                        Processing
                        <br />
                        <span className="text-[10px] text-orange">
                            Due in{' '}
                            {timeDueinDays === 0
                                ? timeDueinHours.toString().concat(' Hours')
                                : timeDueinDays.toString().concat(' Days')}
                        </span>
                    </span>
                </td>
            )}
            {props.orderStatus === 'Issue Raised' && (
                <td className="font-bold">
                    <span className=" block issue-raised-status p-2 m-1">
                        Issue Raised
                    </span>
                </td>
            )}
        </tr>
    );
}
