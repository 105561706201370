import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useFormikContext } from 'formik';
import { ClientOrderInitialValues } from '../../types/ClientOrderInitialValues';
import { ResetFilterButton } from './ResetFilterButton';

export function Filter() {
    const formik = useFormikContext<ClientOrderInitialValues>();

    if (formik.values.filterToggle) {
        return (
            <div className="p-5 mt-5 grid sm:grid-cols-3 gap-4 transition ease-in-out bg-blue-50 rounded-2xl">
                <IntegerMinMaxFilter
                    min={0}
                    max={100}
                    label="Site DA Required"
                    attribute="siteDaReq"
                    setFieldAttribute="siteDaReqFilter"
                />
                <IntegerMinMaxFilter
                    min={0}
                    max={100}
                    label="Site DR Required"
                    attribute="siteDrReq"
                    setFieldAttribute="siteDrReqFilter"
                />
                <IntegerMinMaxFilter
                    min={0}
                    label="Number of links to build"
                    attribute="articleCounts"
                    setFieldAttribute="articleCountsFilter"
                />
                <DateFromToFilter
                    label="Due Date"
                    attribute="dueDate"
                    setFieldAttribute="dueDateFilter"
                />
                <div className="sm:col-span-3 lg:col-span-1 text-center p-3 align-middle">
                    <MultiSelect
                        label="Status"
                        options={[
                            'Done',
                            'Overdue',
                            'Processing',
                            'Cancelled',
                            'Issue-Raised',
                        ]}
                        setFieldAttribute="statusFilter"
                        attributeValue={formik.values.statusFilter}
                    />
                </div>
                <ResetFilterButton />
            </div>
        );
    }
    return <div />;
}

function IntegerMinMaxFilter(props: {
    label: string;
    attribute: string;
    setFieldAttribute: string;
    min?: number;
    max?: number;
}) {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <div className="filter-options rounded-md text-sm space-y-2 bg-blue-100">
            <span>{props.label}</span>
            <form className="grid grid-cols-1 gap-4">
                <div>
                    <label
                        htmlFor={'min'.concat(props.attribute)}
                        className="text-[13px]"
                    >
                        Min:
                    </label>
                    <input
                        min={props.min}
                        max={props.max}
                        id={'min'.concat(props.attribute)}
                        className="form-control
                        text-center
                        input-number-disable-arrows 
                        text-sm
                                    text-gray-700
                                    border border-solid 
                                    border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    focus:outline-none font-medium
                                    p-1 ml-1"
                        type="number"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.gte'),
                                Number(event.target.value)
                            );
                        }}
                        onReset={formik.handleReset}
                        name={props.setFieldAttribute.concat('.gte')}
                    />
                </div>
                <div>
                    <label
                        htmlFor={'max'.concat(props.attribute)}
                        className="text-[13px]"
                    >
                        Max:
                    </label>
                    <input
                        min={props.min}
                        max={props.max}
                        id={'max'.concat(props.attribute)}
                        className="form-control
                        text-center
                        input-number-disable-arrows 
                        text-sm
                        text-gray-700
                        border border-solid 
                        border-gray-300
                        rounded
                        transition
                        ease-in-out
                        focus:outline-none font-medium
                        p-1 ml-1"
                        type="number"
                        // onBlur={(event) => {
                        //     formik.setFieldValue(
                        //         props.setFieldAttribute.concat('.lte'),
                        //         Number(event.target.value)
                        //     );
                        // }}
                        name={props.setFieldAttribute.concat('.lte')}
                        onBlur={formik.handleBlur}
                    />
                </div>
            </form>
        </div>
    );
}

function MultiSelect(props: {
    label: string;
    options: string[];
    setFieldAttribute: string;
    attributeValue: string[];
}) {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <div className="w-full text-center bg-blue-100 rounded-lg">
            <Listbox
                value={props.attributeValue}
                onChange={(event) => {
                    if (event.length === 0) {
                        formik.setFieldValue(
                            props.setFieldAttribute,
                            props.options
                        );
                    } else {
                        formik.setFieldValue(props.setFieldAttribute, event);
                    }
                }}
                multiple
            >
                <Listbox.Button className="relative p-2 w-full rounded-lg inline-block text-sm space-x-2">
                    <span className="align-middle">{props.label}</span>
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400 inline-block"
                        aria-hidden="true"
                    />
                </Listbox.Button>
                <Listbox.Options className="bg-blue-50 mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {props.options.map((option) => (
                        <Listbox.Option
                            key={option}
                            value={option}
                            className="hover:bg-blue-100"
                        >
                            {props.attributeValue.includes(option) ? (
                                <CheckIcon className="w-5 h-5 inline-block float-left border rounded-sm" />
                            ) : (
                                <div className="w-5 h-5 inline-block float-left fill-white border rounded-sm bg-white p-1" />
                            )}
                            <span>{option}</span>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
}

function DateFromToFilter(props: {
    label: string;
    attribute: string;
    setFieldAttribute: string;
}) {
    const formik = useFormikContext<ClientOrderInitialValues>();
    return (
        <div className="sm:col-span-3 lg:col-span-1 filter-options text-sm space-y-4 px-1 align-middle bg-blue-100">
            <span>{props.label}</span>
            <form className="grid grid-cols-1 gap-4 content-center">
                <div className="space-x-3">
                    <label
                        className="text-[13px]"
                        htmlFor={'min'.concat(props.attribute)}
                    >
                        From:
                    </label>
                    <input
                        id={'min'.concat(props.attribute)}
                        className="form-control
                                    w-32
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    focus:outline-none"
                        type="date"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.gte'),
                                event.target.value
                            );
                        }}
                        // defaultValue={new Date().toISOString().substring(0, 10)}
                    />
                </div>
                <div className="space-x-6">
                    <label
                        htmlFor={'max'.concat(props.attribute)}
                        className="text-[13px]"
                    >
                        To:
                    </label>
                    <input
                        id={'max'.concat(props.attribute)}
                        className="form-control
                                    w-32
                                    p-1
                                    text-sm
                                    text-gray-700
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:outline-none"
                        type="date"
                        onBlur={(event) => {
                            formik.setFieldValue(
                                props.setFieldAttribute.concat('.lte'),
                                event.target.value
                            );
                        }}
                        // defaultValue={new Date().toISOString().substring(0, 10)}
                    />
                </div>
            </form>
        </div>
    );
}
