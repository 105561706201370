import Dashboard from '../../../components/dashboard/Dashboard';
import SeoNavbar from '../../../components/main/Navbar/Navbar';
import ProfileBar from '../../../components/main/ProfileBar';
import '../../../styles/welcome.css';

export default function SeoHome() {
    return (
        <>
            <SeoNavbar activeElement="dashboard" />
            <ProfileBar title="SEO Dashboard" />
            <div className="backg">
                <Dashboard />
            </div>
        </>
    );
}
