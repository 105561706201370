import { useFormikContext } from 'formik';

interface InitalValues {
    toggleSeoOrderManager: boolean;
}

export function ToggleButton() {
    const formik = useFormikContext<InitalValues>();
    return (
        <label className="relative inline-flex items-center cursor-pointer ">
            <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={formik.values.toggleSeoOrderManager}
                onClick={() => {
                    formik.setFieldValue(
                        'toggleSeoOrderManager',
                        !formik.values.toggleSeoOrderManager
                    );
                }}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer ring-4 ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />

            {!formik.values.toggleSeoOrderManager ? (
                <span className="ml-3 text-xs text-gray-900 dark:text-blue-600 font-bold">
                    SEO EMPLOYEE
                </span>
            ) : (
                <span className="ml-3 text-xs font-bold text-gray-900 dark:text-blue-600">
                    SEO ORDER MANAGER
                </span>
            )}
        </label>
    );
}
