import {
    CalendarDaysIcon,
    GlobeAsiaAustraliaIcon,
} from '@heroicons/react/20/solid';
import {
    Employee,
    useUpdateMainOrderStatusMutation,
    useUpdateSeoOrderManagerMutation,
} from '../../api/generated';

export default function ClientOrderHeader(props: {
    id: string;
    clientName: string;
    startDate: string;
    dueDate: string;
    articleCount: number;
    requiredDA: number;
    requiredDR: number;
    clientSiteURL: string;
    employeeAssignable: Employee[];
    status: string;
    refetch: any;
}) {
    const startDate = new Date().getTime();
    const dueDate = new Date(props.dueDate).getTime();
    const timeDue = startDate - dueDate;
    const timeDueinDays = timeDue / (1000 * 3600 * 24);
    const timeDueinWeeks = timeDue / (1000 * 3600 * 24 * 7);

    const [updateSeoOrderManager] = useUpdateSeoOrderManagerMutation();
    const orderStatuses = [
        'Done',
        'Processing',
        'Issue-Raised',
        'Overdue',
        'Cancelled',
    ];

    const [updateMainOrderStatus] = useUpdateMainOrderStatusMutation();

    return (
        <>
            <div className="space-x-2 grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="sm:col-span-2 align-middle">
                    <h3 className="text-[#5570F1] text-2xl mx-2 font-bold inline-block">
                        {'#'.concat(props?.id)}/ {props.clientName}
                    </h3>
                    <div
                        className={`font-semibold bg-opacity-30 inline-block p-1 px-3 rounded ${
                            timeDue <= 0
                                ? 'bg-[#519c66] text-[#519c66]'
                                : 'bg-orange-400 text-red'
                        }`}
                    >
                        {timeDue > 0
                            ? `Due Past ${
                                  timeDueinWeeks < 0
                                      ? `${Math.abs(
                                            Math.floor(timeDueinWeeks)
                                        )} weeks and ${Math.abs(
                                            Math.ceil(
                                                Math.floor(timeDueinDays) % 7
                                            )
                                        )} days`
                                      : Math.floor(timeDueinDays) > 0
                                      ? `${Math.abs(
                                            Math.floor(timeDueinDays)
                                        )} days`
                                      : ``
                              }`
                            : timeDue < 0
                            ? `Due After ${
                                  timeDueinWeeks < 0
                                      ? `${Math.abs(
                                            Math.ceil(timeDueinWeeks)
                                        )} weeks and ${Math.abs(
                                            Math.ceil(
                                                Math.ceil(timeDueinDays) % 7
                                            )
                                        )} days`
                                      : Math.ceil(timeDueinDays) < 0
                                      ? `${Math.abs(
                                            Math.ceil(timeDueinDays)
                                        )} days`
                                      : ``
                              }`
                            : 'Due Today'}
                    </div>
                </div>
                {/* <div className="inline-block rounded align-middle">
                    // <ListBoxEmployee
                    //     label="Assign Task To"
                    //     options={props.employeeAssignable}
                    //     attributeValue={[props.seoOrderManager]}
                    // />
                    <select
                        className="p-3 bg-transparent text-center border-2 w-48 rounded-lg border-zedex-button-blue hover:bg-blue-50"
                        onChange={(event) => {
                            updateSeoOrderManager({
                                variables: {
                                    input: {
                                        orderID: props.id,
                                        newSeoOrderManagerID: Number(
                                            event.target.value
                                        ),
                                    },
                                },
                            });
                        }}
                    >
                        <option value={props?.seoOrderManagerID}>
                            {props.seoOrderManagerName}
                        </option>
                        {props.employeeAssignable &&
                            props.employeeAssignable.map((employee) => (
                                <option
                                    key={employee.id as number}
                                    value={employee.id as number}
                                >{`${employee.firstName} ${employee.lastName}`}</option>
                            ))}
                    </select>
                </div> */}
                <div className="align-middle">
                    <select
                        className="basis-1/2 bg-blue-500 hover:bg-blue-700 text-white font-semibold border border-blue-700 rounded w-full h-10 p-2 text-center"
                        defaultValue={props.status}
                        onChange={async (event) => {
                            const { data } = await updateMainOrderStatus({
                                variables: {
                                    input: {
                                        orderID: props?.id as string,
                                        status: event.target.value,
                                    },
                                },
                            });
                            if (data?.updateMainOrderStatus?.success) {
                                props.refetch();
                            }
                        }}
                    >
                        <option value={props.status}>{props.status}</option>
                        {orderStatuses
                            .filter((status) => status !== props.status)
                            .map((status) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className=" grid md:grid-cols-1 lg:grid-cols-3 gap-4 my-2 py-2 bg-slate-50">
                <div className=" bg-white border border-white rounded p-2">
                    <GlobeAsiaAustraliaIcon className="w-10 h-10 rounded inline-block fill-blue-500 align-middle p-1 bg-[#DBDEEE] mr-4" />
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-blue-500 text-xs">
                            Client Site URL
                        </div>
                        <div className="text-blue-500 text-lg font-semibold">
                            {props.clientSiteURL}
                        </div>
                    </div>
                </div>
                <div className=" bg-white border border-white rounded p-2 grid grid-cols-2 text-center">
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-slate-500">
                            No of Links to Build
                        </div>
                        <div className="text-black text-center">
                            {props.articleCount}
                        </div>
                    </div>
                    <div className="inline-block align-middle ml-2 text-sm">
                        <div className="text-slate-500">DA/DR Required </div>
                        <div className="text-black text-center">
                            {props.requiredDA} / {props.requiredDR}
                        </div>
                    </div>
                </div>
                <div className=" bg-white border border-white rounded p-2 grid grid-cols-2">
                    <div>
                        <CalendarDaysIcon className="w-8 h-8 rounded inline-block bg-[#DBDEEE] fill-[#3BBF68] align-middle p-1" />
                        <div className="inline-block align-middle ml-2 mr-4 text-sm">
                            <div className="text-blue-500">Start Date</div>
                            <div className="text-blue-500">
                                {props?.startDate.substring(0, 10)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <CalendarDaysIcon className="w-8 h-8 rounded inline-block fill-[#FF3A29] align-middle p-1 bg-[#DBDEEE]" />
                        <div className="inline-block align-middle ml-2 text-sm">
                            <div className="text-blue-500">End Date</div>
                            <div className="text-blue-500">
                                {props.dueDate.substring(0, 10)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
