import Card from './Card';
import Notification from './Notification';
import OrderDisplay from './OrderPercent';
import OutreachDisplay from './OutreachPercent';
import ToDoList from './TodoList';
import Welcome from './WelcomeBar';

export default function Dashboard() {
    return (
        <div className="bg-zedex-bg-gray pt-20 flex flex-col">
            <Welcome />
            <div className="flex flex-row py-6 gap-6 w-full">
                <div className='flex flex-col gap-6 w-[25%]' >
                    <OrderDisplay />
                    <OutreachDisplay />
                </div>
                <div className='flex flex-col w-[40%] gap-6' >
                    <Card />
                </div>
                <div className='flex flex-col w-[35%] gap-6' >
                    <ToDoList />
                    <Notification />
                </div>
            </div>
        </div>
    );
}
