import { Link } from "react-router-dom";

export default function FormComponent() {
    return (
        <div className="flex justify-between items-center mb-6">
            <div className="form-group form-check">
                <input className="align-middle h-4 w-4 " type="checkbox" />
                <label
                    className="form-check-label inline-block pl-1 text-trev "
                    htmlFor="exampleCheck2"
                >
                    Remember me
                </label>
            </div>
            <Link to="/forgot-password" className="text-trev hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out">
                Forgot password?
            </Link>
        </div>
    );
}
