import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import SeoViewOrder from '../../../components/ViewOrderSeoEmp/ViewOrder';
import NavBar from '../../../components/main/Navbar/Navbar';
import PathBar from '../../../components/main/PathBar';
import ProfileBar from '../../../components/main/ProfileBar';

export function SeoViewOrderPage() {
    const location = useLocation();
    const { orderID } = location.state;
    return (
        <>
            <NavBar activeElement="order" />
            <ProfileBar title="Client Order" />
            <div className="bg-[#f4f5fa] pt-20">
                <PathBar appendPath={`#${orderID.toString()}`} />
                <div className="ml-[4.3em] mr-1 bg-white py-[1em] px-[0.2em]">
                    <Formik
                        initialValues={{
                            paginationRowCount: 15,
                            pageNum: 0,
                            totalPages: 1,
                            totalRows: 0,
                            seoOrderAssignment: [],
                            edited: false,
                            search: {
                                contains: '',
                            },
                        }}
                        onSubmit={() => {
                            //
                        }}
                    >
                        {() => <SeoViewOrder orderID={orderID} />}
                    </Formik>
                </div>
            </div>
        </>
    );
}
