import { Link } from 'react-router-dom';
import { useGetEmployeeUsingTokenQuery } from '../../api/generated';

export default function ImageBar() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    return (
        <div className="orp-bar rounded-md grid  grid-cols-2  ">
            <div className="grid  grid-cols-2  w-64 h-64 px-10 py-2 ">
                <div>
                    <img
                        className="rounded-full shadow-lg"
                        src="/img/nav/profilePic.jpg"
                        alt="Profile_Picture"
                    />
                </div>
            </div>
            <div>
                <div className="py-2 font-bold">
                    {LoggedInUser?.getEmployeeUsingToken?.firstName?.concat(
                        ` ${LoggedInUser.getEmployeeUsingToken.lastName?.toString()}` as string
                    )}
                </div>
                <div className="py-2 text-blue-500 grid-cols-2">
                    <span>#{LoggedInUser?.getEmployeeUsingToken?.id} |</span>{' '}
                    <span>{LoggedInUser?.getEmployeeUsingToken?.role}</span>{' '}
                </div>
                <div className="py-2 text-blue-500 grid-cols-2">
                    <span>
                        <Link to="/reset-password">Reset Password</Link>
                    </span>{' '}
                </div>
            </div>
        </div>
    );
}
