import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode } from "react";
import Icon from "../../Icons";

export interface ModalProps extends Dialog.DialogProps {
  title?: string;
  description?: string;
  trigger?: ReactNode;
  triggerProps?: Dialog.DialogTriggerProps;
  portalProps?: Dialog.DialogPortalProps;
  overlayProps?: Dialog.DialogOverlayProps;
  contentProps?: Dialog.DialogContentProps;
  titleProps?: Dialog.DialogTitleProps;
  descriptionProps?: Dialog.DialogDescriptionProps;
  closeProps?: Dialog.DialogCloseProps;
}

const Modal = ({
  title,
  description,
  trigger,
  triggerProps,
  portalProps,
  overlayProps,
  contentProps,
  titleProps,
  descriptionProps,
  closeProps,
  children,
  ...props
}: ModalProps) => (
  <Dialog.Root {...props}>
    {trigger && (
      <Dialog.Trigger asChild {...triggerProps}>
        {trigger}
      </Dialog.Trigger>
    )}
    <Dialog.Portal {...portalProps}>
      <Dialog.Overlay
        className="bg-overly data-[state=open]:animate-overlayShow fixed inset-0"
        {...overlayProps}
      />
      <Dialog.Content
        className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
        {...contentProps}
      >
        {title && (
          <Dialog.Title
            className="text-mmad m-0 text-[17px] font-medium py-2"
            {...titleProps}
          >
            {title}
          </Dialog.Title>
        )}
        {description && (
          <Dialog.Description
            className="text-mauve11 mb-5 mt-[10px] text-[15px] leading-normal"
            {...descriptionProps}
          >
            {description}
          </Dialog.Description>
        )}
        {children}
        <Dialog.Close asChild {...closeProps}>
          <button
          type="button"
            className=" absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
            aria-label="Close"
          >
            <Icon icon="cross" className="fill-red" />
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default Modal;
