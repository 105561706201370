import {
    useGetEmployeeUsingTokenQuery,
    useGetNotificationByIdQuery,
} from '../../api/generated';
import '../../styles/ProfileBar.css';
import '../../styles/welcome.css';

export default function Welcome() {
    const { data: LoggedInUser } = useGetEmployeeUsingTokenQuery();
    const { data } = useGetNotificationByIdQuery({
        variables: {
            input: {
                toEmployeeID: LoggedInUser?.getEmployeeUsingToken?.id,
            },
        },
    });
    const count = data?.getNotificationById?.filter(
        (ro) => ro?.status === true
    ).length;
    return (
        <div className="welcome-bar rounded-md ">
            <div className="flex items-center  w-32  bg-gradient-to-r from-trev to-indigo rounded-lg float-left">
                <img src="/img/wel.png" alt="" />
            </div>
            <div>
                <h1 className="headerTitle texts">
                    Welcome {LoggedInUser?.getEmployeeUsingToken?.firstName} !!!
                </h1>
                <p className="textse">
                    {' '}
                    You have{' '}
                    <span className="col"> {count} unread alerts </span>
                </p>
            </div>
        </div>
    );
}
