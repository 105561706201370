import '../../styles/style.css';

import * as Yup from 'yup';

import { EMAILS, REQUIREDS } from '../../components/main/Schema';

import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useForgotPasswordMutation } from '../../api/generated';
import ButtonComp from '../../components/main/Button';
import InputComponent from '../../components/main/Input';

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(EMAILS).required(REQUIREDS),
});

export default function ForgotPassword() {
    const [forgotEmployee] = useForgotPasswordMutation();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: async () => {
            const { data } = await forgotEmployee({
                variables: {
                    input: {
                        email: formik.values.email,
                    },
                },
            });
            if (data?.forgotPassword?.success) {
                notify('Mail Sent');
                window.location.replace('/');
            } else {
                notify('Error, Try Again');
            }
        },
    });
    const notify = (statusMsg: string) => toast(statusMsg);

    return (
        <div
            className="Background bg-cover bg-center"
            style={{
                backgroundImage: "url('/img/background.png')",
            }}
        >
            <div className="  flex min-h-screen justify-center items-center py-8 px-2 sm:px-4 lg:px-6 ">
                <div className=" flex flex-col md:flex-row md:max-w-xl rounded-lg bg-gray shadow-2xl">
                    <div className="flex items-center h-32 md:h-auto md:w-1/2 bg-gradient-to-r from-trev to-indigo rounded-lg">
                        <img src="/img/gg.png" alt="" />
                    </div>
                    <div className="p-6 flex flex-col justify-start">
                        <form onSubmit={formik.handleSubmit}>
                            <img
                                className="mb-3 logo  "
                                src="/img/zedex.png"
                                alt=""
                            />
                            <h1 className="text-2xl font-bold text-trev text-center mb-3">
                                Forgot Password
                            </h1>
                            <h6 className=" font-small text-center mb-3">
                                Enter your email address
                            </h6>
                            <h6 className=" font-small text-center mb-3">
                                and we`ll send you a link to reset your
                                password.
                            </h6>
                            <div className="form-group mb-6 ">
                                <InputComponent
                                    id="email"
                                    inputType="text"
                                    name="email"
                                    inputPlaceholder="Enter Your Email ID"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    touched={formik.touched.email}
                                    error={formik.errors.email}
                                />
                            </div>

                            <ButtonComp buttonHeading="Send Email" />
                            <ToastContainer />
                            <h6 className=" font-small text-center mb-3 text-trev">
                                You will get an email to reset your password
                            </h6>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
